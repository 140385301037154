import React from 'react'
import { styled } from '../../../../theme'

const StyledContainer = styled('div', ({ $theme }) => ({
    paddingBottom: $theme.sizing.scale700
}))

const StyledIcon = styled('i', ({ $theme }) => ({
    fontSize: $theme.sizing.scale1200
}))

const StyledImageContainer = styled('div', {
    width: '100%',
    height: '200px',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    overflow: 'hidden',
    position: 'relative'
})

const StyledImage = styled('img', {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: '100%',
    '-webkit-transform': 'translate(-50%,-50%)',
    '-ms-transform': 'translate(-50%,-50%)',
    transform: 'translate(-50%,-50%)'
})

interface JustIcon {
    icon: string
    img?: never
}
interface JustImage {
    img: string
    icon?: never
}

export type ModalHeaderEnhancerTypes = JustIcon | JustImage

export interface ModalHeaderEnhancerProps {
    data?: ModalHeaderEnhancerTypes
}

export const ModalHeaderEnhancer = (props: ModalHeaderEnhancerProps) => {
    if (!props?.data) return null
    return (
        <StyledContainer>
            {'icon' in props.data ? (
                <StyledIcon className={props.data.icon} />
            ) : (
                <StyledImageContainer>
                    <StyledImage src={props.data.img} alt="header_endhancer_image" />
                </StyledImageContainer>
            )}
        </StyledContainer>
    )
}

export default ModalHeaderEnhancer
