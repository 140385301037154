import React from 'react'
import * as Sentry from '@sentry/react'
import { enableES5 } from 'immer'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'
import ResizeObserver from 'resize-observer-polyfill'

import GlobalErrorBoundary from 'parkdepot-shared/views/errors/GlobalErrorBoundary'
import { TokenProvider } from 'parkdepot-shared/views/token'

import App from './App'
import i18n from './i18n'
import { isMobileSafari } from './utils/isSafari'
import Base from './views/AppFrame/Base'

import 'material-icons/iconfont/material-icons.css'
import 'parkdepot-shared/fonts/pkd/index.css'
import 'parkdepot-shared/fonts/wemolo/index.css'
import './index.css'

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: process.env?.REACT_APP_SENTRY_DSN
    })
}
enableES5()

// Baseweb requires the presence of ResizeObserver
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
}

i18n.loadNamespaces(['common', 'errors'])

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
const vh = window.innerHeight

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)

// We listen to the resize event
window.addEventListener('resize', () => {
    const vh = window.innerHeight
    document.documentElement.style.setProperty('--vh', `${vh}px`)
})

document.addEventListener('focusin', () => {
    if (isMobileSafari && document?.activeElement?.nodeName === 'INPUT') {
        document.body.classList.add('lock-scroll')
    } else {
        document.body.classList.remove('lock-scroll')
    }
})

document.addEventListener('focusout', () => {
    document.body.classList.remove('lock-scroll')
})

document.body.classList.remove('loading')

const searchURL = window.location.search
const token = new URLSearchParams(searchURL).get('access_token') || undefined

ReactDOM.render(
    <Base>
        <GlobalErrorBoundary>
            <TokenProvider
                context={{
                    token
                }}
            >
                <App />
            </TokenProvider>
        </GlobalErrorBoundary>
    </Base>,
    document.getElementById('root')
)
