import React from 'react'
import GlobalErrorView from './views/GlobalErrorView'
import UnsupportedBrowserIE from './views/UnsupportedBrowserIE'

export enum ErrorBoundaryTheme {
    LIGHT = 'light',
    DARK = 'dark'
}

interface BoundaryProps {
    view?: React.ReactNode | React.ReactNode[]
    errorBoundaryTheme?: ErrorBoundaryTheme
}

interface BoundaryState {
    hasError: boolean
}

class GlobalErrorBoundary extends React.Component<BoundaryProps, BoundaryState> {
    state: BoundaryState = { hasError: false }

    public componentDidCatch(error: any, info: any) {
        this.setState({ hasError: true })
    }

    public render() {
        // @ts-ignore
        const isIE = !!document.documentMode

        if (isIE) {
            return <UnsupportedBrowserIE />
        }

        if (this.state.hasError) {
            return this.props.view || <GlobalErrorView errorBoundaryTheme={this.props.errorBoundaryTheme} />
        }

        return this.props.children
    }
}

export default GlobalErrorBoundary
