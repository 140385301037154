import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { ModalBody, ModalHeader } from 'baseui/modal'
import { LabelLarge } from 'baseui/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { TABLET_TYPOGRAHPY_OVERRIDE } from '../MemberEntry/constants'

import MobileModal from 'parkdepot-shared/components/MobileModal'
import { useModal } from 'parkdepot-shared/components/Modal'

const PrivacyPolicyModal = ({ onClose }: { onClose: () => void }) => {
    const [css, theme] = useStyletron()
    const { t } = useTranslation()
    return (
        <MobileModal
            isOpen
            onClose={onClose}
            autoFocus={false}
            overrides={{
                Root: {
                    style: {
                        zIndex: 2
                    }
                },
                Dialog: {
                    style: {
                        minHeight: 'auto',
                        height: '100vh',
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '0 !important',
                        marginTop: '0 !important',
                        [theme.mediaQuery.medium]: {
                            height: 'calc(100vh - 32px)',
                            marginBlock: '32px',
                            width: '60vw'
                        }
                    }
                }
            }}
        >
            <ModalHeader>
                <LabelLarge
                    className={css({
                        textAlign: 'center',
                        ...theme.typography.font550,
                        ...TABLET_TYPOGRAHPY_OVERRIDE.large
                    })}
                >
                    {t('member.memberEntry.privacyPolicy.title')}
                </LabelLarge>
            </ModalHeader>
            <ModalBody
                style={{
                    flex: '1 1 0%',
                    overflowY: 'auto'
                }}
            >
                <div
                    className={css({
                        color: theme.colors.black,
                        marginBottom: theme.sizing.scale600,
                        wordWrap: 'break-word',
                        overflowY: 'auto',
                        ...TABLET_TYPOGRAHPY_OVERRIDE.small
                    })}
                >
                    <p>Stand: August 2023</p>
                    <h2 id="inhaltsverzeichnis">Inhaltsverzeichnis</h2>
                    <ol>
                        <li>Name und Anschrift des Verantwortlichen</li>
                        <li>Kontaktdaten des Datenschutzbeauftragten</li>
                        <li>Erfasste Daten</li>
                        <li>Allgemeines zur Datenverarbeitung</li>
                        <li>Rechte der betroffenen Person</li>
                    </ol>
                    <h2 id="i-name-und-anschrift-des-verantwortlichen">I. Name und Anschrift des Verantwortlichen</h2>
                    <p>
                        Der Verantwortliche im Sinne der Datenschutz-Grundverordnung (DSGVO) und anderer
                        datenschutzrechtlicher Bestimmungen ist:
                    </p>
                    <p>
                        Wemolo GmbH
                        <br />
                        St.-Martin-Straße 72
                        <br />
                        81541 München
                        <br />
                        Deutschland
                    </p>
                    <p>
                        089 356477 60
                        <br />
                        info@wemolo.de
                        <br />
                        <a href="https://www.wemolo.de/" target="_blank" rel="noopener noreferrer">
                            https://www.wemolo.de/
                        </a>
                    </p>
                    <h2 id="ii-kontaktdaten-des-datenschutzbeauftragten">
                        II. Kontaktdaten des Datenschutzbeauftragten
                    </h2>
                    <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
                    <p>
                        LUCID Compliance
                        <br />
                        Christian Schmoll
                        <br />
                        Kaiserplatz 2
                        <br />
                        80803 München
                        <br />
                        Deutschland
                    </p>
                    <p>
                        +49 (0) 89 4622 7322
                        <br />
                        <a href="schmoll@lucid-compliance.com" target="email" rel="noopener noreferrer">
                            schmoll@lucid-compliance.com
                        </a>
                    </p>
                    <h2 id="iii-erfasste-daten">III. Erfasste Daten</h2>
                    <h3 id="1-beschreibung-und-umfang-der-datenverarbeitung">
                        1. Beschreibung und Umfang der Datenverarbeitung
                    </h3>
                    <p>
                        Registriert sich ein Nutzer auf der Wemolo-Webseite, um vom Verlängerungsangebot Gebrauch zu
                        machen, werden bei der Registrierung und Nutzung der Plattform und des angelegten Kontos
                        folgende Daten automatisiert erfasst und verarbeitet:
                    </p>
                    <ul>
                        <li>
                            <p>
                                <strong>Name</strong> Die Erfassung des Namens ist notwendig, um den Nutzer bei der
                                Bereitstellung unserer Dienste zu identifizieren.
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>E-Mail-Adresse</strong> Die Erfassung der E-Mail-Adresse ist notwendig für die
                                Kommunikation mit dem Nutzer, der per E-Mail u.a. Buchungsbestätigungen und anderweitige
                                Wemolo-Services erhält.
                            </p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <strong>Kfz-Kennzeichen</strong> Mithilfe des vom Nutzer angegebenen Kfz-Kennzeichens wird
                            bei der Einfahrt ermittelt, ob eine Eintragung für das jeweilige Fahrzeug vorliegt, um zu
                            prüfen, ob dem Fahrzeug eine Parkzeitverlängerung gestattet wird.
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <strong>Verwaltung der Buchungen des Nutzers</strong> Wemolo-Kundensupport-Mitarbeiter
                            können die oben genannten Daten einsehen, wenn Sie die Eintragung des Nutzers verwalten. Die
                            Verwaltung der Eintragung und diesbezügliche Verarbeitung der Daten ist notwendig, um
                            etwaige Probleme bei der Eintragung oder im Rahmen der Gewährung der Parkzeitverlängerung
                            (ggf.) im Dialog mit dem Nutzer lösen zu können.
                        </li>
                    </ul>
                    <h3 id="3-zweck-der-datenverarbeitung">3. Zweck der Datenverarbeitung</h3>
                    <p>
                        Die Speicherung der Daten erfolgt, um die Funktionsfähigkeit des angebotenen Services
                        (Parkzeitverlängerung) sicherzustellen.
                    </p>
                    <h3 id="4-rechtsgrundlage-f-r-die-datenverarbeitung">
                        4. Rechtsgrundlage für die Datenverarbeitung
                    </h3>
                    <p>
                        Rechtsgrundlage für die vorübergehende Speicherung der Daten ist Art. 6 Abs. 1 S. 1 lit. b und f
                        DSGVO. Die Datenverarbeitung erfolgt zur Bereitstellung der angebotenen und vom Nutzer
                        angefragten Parkzeitverlängerung.
                    </p>
                    <h3 id="5-dauer-der-speicherung">5. Dauer der Speicherung</h3>
                    <p>
                        Die Daten werden grundsätzlich gelöscht, sobald sie für die Erreichung des Zweckes ihrer
                        Erhebung nicht mehr erforderlich sind.{' '}
                    </p>
                    <h3 id="6-widerspruchs-und-beseitigungsm-glichkeit">
                        6. Widerspruchs- und Beseitigungsmöglichkeit
                    </h3>
                    <p>
                        Die Erfassung der Daten zur Bereitstellung der Parkzeitverlängerung und der Plattform ist für
                        den Betrieb der Plattform zwingend erforderlich. Der Nutzer kann die von ihm angegebenen und
                        nicht zwingend erforderlichen Daten jederzeit in seinem Benutzerprofil anpassen oder löschen
                        lassen. Mit Löschung des Profils als solchem werden die angegebenen und erfassten Daten von uns
                        gelöscht, auf Anfrage des Nutzers (via Telefon, Email oder Post) auch bereits vor Ablauf der
                        zwei Monate.
                    </p>
                    <h2 id="iv-allgemeines-zur-datenverarbeitung">IV. Allgemeines zur Datenverarbeitung</h2>
                    <h3 id="1-umfang-der-verarbeitung-personenbezogener-daten">
                        1. Umfang der Verarbeitung personenbezogener Daten
                    </h3>
                    <p>
                        Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur
                        Bereitstellung einer funktionsfähigen Webseite sowie unserer Inhalte und Leistungen erforderlich
                        ist. Die Verarbeitung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach
                        Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige
                        Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung
                        der Daten durch gesetzliche Vorschriften erforderlich ist.
                    </p>
                    <h3 id="2-rechtsgrundlage-f-r-die-verarbeitung-personenbezogener-daten">
                        2. Rechtsgrundlage für die Verarbeitung personenbezogener Daten
                    </h3>
                    <p>
                        Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen
                        Person einholen, dient Art. 6 Abs. 1 S. 1 lit. a EU-Datenschutzgrundverordnung (DSGVO) als
                        Rechtsgrundlage.
                    </p>
                    <p>
                        Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen
                        Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 S. 1 lit. b
                        DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung
                        vorvertraglicher Maßnahmen erforderlich sind.
                    </p>
                    <p>
                        Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung
                        erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 S. 1 lit. c DSGVO als
                        Rechtsgrundlage.
                    </p>
                    <p>
                        Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen
                        natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6
                        Abs. 1 S. 1 lit. d DSGVO als Rechtsgrundlage.
                    </p>
                    <p>
                        Ist die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
                        Dritten erforderlich und überwiegen die Interessen, Grundrechte und Grundfreiheiten des
                        Betroffenen das erstgenannte Interesse nicht, so dient Art. 6 Abs. 1 S. 1 lit. f DSGVO als
                        Rechtsgrundlage für die Verarbeitung.
                    </p>
                    <h3 id="3-datenl-schung-und-speicherdauer">3. Datenlöschung und Speicherdauer</h3>
                    <p>
                        Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der
                        Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch
                        den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder
                        sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung
                        oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene
                        Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der
                        Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
                    </p>
                    <h2 id="v-rechte-der-betroffenen-person">V. Rechte der betroffenen Person</h2>
                    <p>
                        Werden Ihre personenbezogenen Daten verarbeitet, sind Sie Betroffener i.S.d. DSGVO und es stehen
                        Ihnen folgende Rechte gegenüber dem Verantwortlichen zu:
                    </p>
                    <h3 id="1-auskunftsrecht">1. Auskunftsrecht</h3>
                    <p>
                        Sie können von dem Verantwortlichen eine Bestätigung darüber verlangen, ob personenbezogene
                        Daten, die Sie betreffen, von ihm verarbeitet werden.
                    </p>
                    <p>
                        Liegt eine solche Verarbeitung vor, können Sie von dem Verantwortlichen über folgende
                        Informationen Auskunft verlangen:
                    </p>
                    <ul>
                        <li>
                            <p>die Zwecke, zu denen die personenbezogenen Daten verarbeitet werden;</p>
                        </li>
                        <li>
                            <p>die Kategorien von personenbezogenen Daten, welche verarbeitet werden;</p>
                            <ul>
                                <li>
                                    <p>
                                        die Empfänger bzw. die Kategorien von Empfängern, gegenüber denen die Sie
                                        betreffenden personenbezogenen Daten offengelegt wurden oder noch offengelegt
                                        werden;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        die geplante Dauer der Speicherung der Sie betreffenden personenbezogenen Daten
                                        oder, falls konkrete Angaben hierzu nicht möglich sind, Kriterien für die
                                        Festlegung der Speicherdauer;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden
                                        personenbezogenen Daten, eines Rechts auf Einschränkung der Verarbeitung durch
                                        den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
                                    </p>
                                </li>
                                <li>
                                    <p>das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;</p>
                                </li>
                                <li>
                                    <p>
                                        alle verfügbaren Informationen über die Herkunft der Daten, wenn die
                                        personenbezogenen Daten nicht bei der betroffenen Person erhoben werden;
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling
                                        gemäß Art. 22 Abs. 1 und 4 DSGVO und zumindest in diesen Fällen aussagekräftige
                                        Informationen über die involvierte Logik sowie die Tragweite und die
                                        angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene
                                        Person.
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        Ihnen steht das Recht zu, Auskunft darüber zu verlangen, ob die Sie betreffenden
                        personenbezogenen Daten in ein Drittland oder an eine internationale Organisation übermittelt
                        werden. In diesem Zusammenhang können Sie verlangen, über die geeigneten Garantien gem. Art. 46
                        DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.
                    </p>
                    <h3 id="2-recht-auf-berichtigung">2. Recht auf Berichtigung</h3>
                    <p>
                        Sie haben ein Recht auf Berichtigung und/oder Vervollständigung gegenüber dem Verantwortlichen,
                        sofern die verarbeiteten personenbezogenen Daten, die Sie betreffen, unrichtig oder
                        unvollständig sind. Der Verantwortliche hat die Berichtigung unverzüglich vorzunehmen.
                    </p>
                    <h3 id="3-recht-auf-einschr-nkung-der-verarbeitung">3. Recht auf Einschränkung der Verarbeitung</h3>
                    <p>
                        Unter den folgenden Voraussetzungen können Sie die Einschränkung der Verarbeitung der Sie
                        betreffenden personenbezogenen Daten verlangen:
                    </p>
                    <ul>
                        <li>
                            <p>
                                wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen Daten für eine Dauer
                                bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
                                personenbezogenen Daten zu überprüfen;
                            </p>
                        </li>
                        <li>
                            <p>
                                die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten
                                ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
                                verlangen;
                            </p>
                        </li>
                        <li>
                            <p>
                                der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
                                länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen benötigen, oder
                            </p>
                        </li>
                        <li>
                            <p>
                                wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DSGVO eingelegt haben
                                und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber
                                Ihren Gründen überwiegen.
                            </p>
                        </li>
                    </ul>
                    <p>
                        Wurde die Verarbeitung der Sie betreffenden personenbezogenen Daten eingeschränkt, dürfen diese
                        Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
                        Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                        natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses
                        der Union oder eines Mitgliedstaats verarbeitet werden.
                    </p>
                    <p>
                        Wurde die Einschränkung der Verarbeitung nach den o.g. Voraussetzungen eingeschränkt, werden Sie
                        von dem Verantwortlichen unterrichtet bevor die Einschränkung aufgehoben wird.
                    </p>
                    <h3 id="4-recht-auf-l-schung">4. Recht auf Löschung</h3>
                    <h4 id="a-l-schungspflicht">a) Löschungspflicht</h4>
                    <p>
                        Sie können von dem Verantwortlichen verlangen, dass die Sie betreffenden personenbezogenen Daten
                        unverzüglich gelöscht werden, und der Verantwortliche ist verpflichtet, diese Daten unverzüglich
                        zu löschen, sofern einer der folgenden Gründe zutrifft:
                    </p>
                    <ul>
                        <li>
                            <p>
                                Die Sie betreffenden personenbezogenen Daten sind für die Zwecke, für die sie erhoben
                                oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung gem. Art. 6 Abs. 1 S. 1
                                lit. a oder Art. 9 Abs. 2 lit. a DSGVO stützte, und es fehlt an einer anderweitigen
                                Rechtsgrundlage für die Verarbeitung.
                            </p>
                        </li>
                        <li>
                            <p>
                                Sie legen gem. Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen
                                keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gem. Art.
                                21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
                            </p>
                        </li>
                        <li>
                            <p>Die Sie betreffenden personenbezogenen Daten wurden unrechtmäßig verarbeitet.</p>
                        </li>
                        <li>
                            <p>
                                Die Löschung der Sie betreffenden personenbezogenen Daten ist zur Erfüllung einer
                                rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
                                erforderlich, dem der Verantwortliche unterliegt.
                            </p>
                        </li>
                        <li>
                            <p>
                                Die Sie betreffenden personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
                                Informationsgesellschaft gemäß Art. 8 Abs. 1 DSGVO erhoben.
                            </p>
                        </li>
                    </ul>
                    <h4 id="b-informationen-an-dritte">b) Informationen an Dritte</h4>
                    <p>
                        Hat der Verantwortliche die Sie betreffenden personenbezogenen Daten öffentlich gemacht und ist
                        er gem. Art. 17 Abs. 1 DSGVO zu deren Löschung verpflichtet, so trifft er unter Berücksichtigung
                        der verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch
                        technischer Art, um für die Datenverarbeitung Verantwortliche, die die personenbezogenen Daten
                        verarbeiten, darüber zu informieren, dass Sie als betroffene Person von ihnen die Löschung aller
                        Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser
                        personenbezogenen Daten verlangt haben.
                    </p>
                    <h4 id="c-ausnahmen">c) Ausnahmen</h4>
                    <p>Das Recht auf Löschung besteht nicht, soweit die Verarbeitung erforderlich ist</p>
                    <ul>
                        <li>
                            <p>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</p>
                        </li>
                        <li>
                            <p>
                                zur Erfüllung einer rechtlichen Verpflichtung, die die Verarbeitung nach dem Recht der
                                Union oder der Mitgliedstaaten, dem der Verantwortliche unterliegt, erfordert, oder zur
                                Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt oder in Ausübung
                                öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde;
                            </p>
                        </li>
                        <li>
                            <p>
                                aus Gründen des öffentlichen Interesses im Bereich der öffentlichen Gesundheit gemäß
                                Art. 9 Abs. 2 lit. h und i sowie Art. 9 Abs. 3 DSGVO;
                            </p>
                        </li>
                        <li>
                            <p>
                                für im öffentlichen Interesse liegende Archivzwecke, wissenschaftliche oder historische
                                Forschungszwecke oder für statistische Zwecke gem. Art. 89 Abs. 1 DSGVO, soweit das
                                unter Abschnitt a) genannte Recht voraussichtlich die Verwirklichung der Ziele dieser
                                Verarbeitung unmöglich macht oder ernsthaft beeinträchtigt, oder
                            </p>
                        </li>
                        <li>
                            <p>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.</p>
                        </li>
                    </ul>
                    <h4 id="5-recht-auf-unterrichtung">5. Recht auf Unterrichtung</h4>
                    <p>
                        Haben Sie das Recht auf Berichtigung, Löschung oder Einschränkung der Verarbeitung gegenüber dem
                        Verantwortlichen geltend gemacht, ist dieser verpflichtet, allen Empfängern, denen die Sie
                        betreffenden personenbezogenen Daten offengelegt wurden, diese Berichtigung oder Löschung der
                        Daten oder Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
                        unmöglich oder ist mit einem unverhältnismäßigen Aufwand verbunden. Ihnen steht gegenüber dem
                        Verantwortlichen das Recht zu, über diese Empfänger unterrichtet zu werden.
                    </p>
                    <h4 id="6-recht-auf-daten-bertragbarkeit">6. Recht auf Datenübertragbarkeit</h4>
                    <p>
                        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, die Sie dem Verantwortlichen
                        bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
                        erhalten. Außerdem haben Sie das Recht, diese Daten einem anderen Verantwortlichen ohne
                        Behinderung durch den Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden,
                        zu übermitteln, sofern
                    </p>
                    <ul>
                        <li>
                            <p>
                                die Verarbeitung auf einer Einwilligung gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO oder Art. 9
                                Abs. 2 lit. a DSGVO oder auf einem Vertrag gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO beruht
                                und
                            </p>
                        </li>
                        <li>
                            <p>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</p>
                        </li>
                    </ul>
                    <p>
                        In Ausübung dieses Rechts haben Sie ferner das Recht, zu erwirken, dass die Sie betreffenden
                        personenbezogenen Daten direkt von einem Verantwortlichen einem anderen Verantwortlichen
                        übermittelt werden, soweit dies technisch machbar ist. Freiheiten und Rechte anderer Personen
                        dürfen hierdurch nicht beeinträchtigt werden.
                    </p>
                    <p>
                        Das Recht auf Datenübertragbarkeit gilt nicht für eine Verarbeitung personenbezogener Daten, die
                        für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse liegt oder in
                        Ausübung öffentlicher Gewalt erfolgt, die dem Verantwortlichen übertragen wurde.
                    </p>
                    <h4 id="7-widerspruchsrecht">7. Widerspruchsrecht</h4>
                    <p>
                        Sie haben das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit
                        gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6
                        Abs. 1 S. 1 lit. e oder f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf
                        diese Bestimmungen gestütztes Profiling.
                    </p>
                    <p>
                        Der Verantwortliche verarbeitet die Sie betreffenden personenbezogenen Daten nicht mehr, es sei
                        denn, er kann zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
                        Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
                        Ausübung oder Verteidigung von Rechtsansprüchen.
                    </p>
                    <p>
                        Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben,
                        haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden
                        personenbezogenen Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das
                        Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                    </p>
                    <p>
                        Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden die Sie betreffenden
                        personenbezogenen Daten nicht mehr für diese Zwecke verarbeitet.
                    </p>
                    <p>
                        Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung von Diensten der
                        Informationsgesellschaft – ungeachtet der Richtlinie 2002/58/EG – Ihr Widerspruchsrecht mittels
                        automatisierter Verfahren auszuüben, bei denen technische Spezifikationen verwendet werden.
                    </p>
                    <h4 id="8-recht-auf-widerruf-der-datenschutzrechtlichen-einwilligungserkl-rung">
                        8. Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung
                    </h4>
                    <p>
                        Sie haben das Recht, Ihre datenschutzrechtliche Einwilligungserklärung jederzeit zu widerrufen.
                        Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
                        zum Widerruf erfolgten Verarbeitung nicht berührt.
                    </p>
                    <h4 id="9-automatisierte-entscheidung-im-einzelfall-einschlie-lich-profiling">
                        9. Automatisierte Entscheidung im Einzelfall einschließlich Profiling
                    </h4>
                    <p>
                        Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung –
                        einschließlich Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
                        rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt. Dies gilt
                        nicht, wenn die Entscheidung
                    </p>
                    <ul>
                        <li>
                            <p>
                                für den Abschluss oder die Erfüllung eines Vertrags zwischen Ihnen und dem
                                Verantwortlichen erforderlich ist,
                            </p>
                        </li>
                        <li>
                            <p>
                                aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der
                                Verantwortliche unterliegt, zulässig ist und diese Rechtsvorschriften angemessene
                                Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie Ihrer berechtigten Interessen
                                enthalten oder
                            </p>
                        </li>
                        <li>
                            <p>mit Ihrer ausdrücklichen Einwilligung erfolgt.</p>
                        </li>
                    </ul>
                    <p>
                        Allerdings dürfen diese Entscheidungen nicht auf besonderen Kategorien personenbezogener Daten
                        nach Art. 9 Abs. 1 DSGVO beruhen, sofern nicht Art. 9 Abs. 2 lit. a oder b DSGVO gilt und
                        angemessene Maßnahmen zum Schutz der Rechte und Freiheiten sowie Ihrer berechtigten Interessen
                        getroffen wurden.
                    </p>
                    <p>
                        Hinsichtlich der im ersten und dritten Spiegelstrich genannten Fälle trifft der Verantwortliche
                        angemessene Maßnahmen, um die Rechte und Freiheiten sowie Ihre berechtigten Interessen zu
                        wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens des
                        Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
                        gehört.
                    </p>
                    <h4 id="10-recht-auf-beschwerde-bei-einer-aufsichtsbeh-rde">
                        10. Recht auf Beschwerde bei einer Aufsichtsbehörde
                    </h4>
                    <p>
                        Unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs steht
                        Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat
                        Ihres Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, zu, wenn
                        Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen
                        die DSGVO verstößt.
                    </p>
                    <p>
                        Die Aufsichtsbehörde, bei der die Beschwerde eingereicht wurde, unterrichtet den
                        Beschwerdeführer über den Stand und die Ergebnisse der Beschwerde einschließlich der Möglichkeit
                        eines gerichtlichen Rechtsbehelfs nach Art. 78 DSGVO.
                    </p>
                </div>
                <Button
                    onClick={onClose}
                    overrides={{
                        Root: {
                            style: {
                                width: '100%',
                                ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                            }
                        }
                    }}
                >
                    {t('common.button.close')}
                </Button>
            </ModalBody>
        </MobileModal>
    )
}

const usePrivacyPolicyModal = (onCompleted?: () => void) => {
    const [showModal, hideModal] = useModal('privacyPolicy', (hideModal) => (
        <PrivacyPolicyModal
            onClose={() => {
                if (onCompleted) {
                    onCompleted()
                }

                hideModal()
            }}
        />
    ))

    return [showModal, hideModal] as const
}

export default usePrivacyPolicyModal
