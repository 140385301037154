import React from 'react'

import { useModal } from 'parkdepot-shared/components/Modal'
import SuccessModal from './SuccessModal'

export const useSuccessModal = () => {
    const [showModal, hideModal] = useModal('successModal', (hideModal) => <SuccessModal hideModal={hideModal} />)

    return [showModal, hideModal] as [typeof showModal, typeof hideModal]
}

export default useSuccessModal
