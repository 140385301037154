import countries from 'parkdepot-shared/assets/countries.json'

export const getCountry = (language?: string, country?: string): string => {
    // While newer JWTs embed the country via the language variable,
    // older JWTs have the language information in the "country" variable.
    return language?.split('_').pop() || country?.split('_').pop() || countries.germany
}

export const getLanguage = (language?: string, country?: string): string => {
    // While newer JWTs embed a language variable,
    // older JWTs still embed the language in the "country".
    return language?.toUpperCase() || country?.toUpperCase() || countries.germany
}
