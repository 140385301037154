import axios from 'axios'

/**
 * Fetches the commit hash of the app from the process.env.PUBLIC_URL/version.json file
 * @returns Promise<void | AxiosResponse<{ commit: string }>>
 * @example
 * const version = await fetchVersion()
 * console.log(version?.data?.commit)
 * // => 'a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6q7r8s9t0'
 */
export const fetchVersion = () => {
    return axios
        .get<{
            commit: string
        }>(`${process.env.PUBLIC_URL}/version.json`, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

export default fetchVersion
