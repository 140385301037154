import countriesFull from 'parkdepot-shared/assets/countriesFull'
import { ICaseData } from 'parkdepot-shared/types'
import React from 'react'
import { PreflightContext } from '../../PreFlight/PreflightContext'
import { IEvEntryIntermediate } from '../EvEntryContext'

const useGenerateSalesforceCase = () => {
    const { preflightData } = React.useContext(PreflightContext)

    const isProduction = process.env.NODE_ENV !== 'development'

    const generateSalesforceCase = (data: IEvEntryIntermediate) => {
        const caseData: ICaseData = {
            Status: 'New',
            Case_Reason__c: 'Charging Proof',
            Subject: `${!isProduction ? 'TESTING (Supervision) - ' : ''}Guest_EV_CHARGING_PROOF ${data.plate}`,
            Description: `${!isProduction ? 'TESTING (Supervision) - ' : ''}Ev Charging Proof for ${data.plate}`,
            Country__c: data.country !== 'INT' ? countriesFull[data.country] : '',
            Parking_Lot_Id__c: preflightData.lot_ids[0],
            Licence_Plate__c: data.plate,
            File_number__c: '_',
            Origin: 'Web',
            Case_Web_Source__c: 'Guest',
            Type: 'CC - Electric Vehicles',
            SuppliedName: '',
            SuppliedEmail: data.email,
            SuppliedPhone: ''
        }

        const attachments = data.attachments.map((attachment) => ({
            Name: attachment.name,
            Body: attachment.body
        }))

        return {
            plate: data.plate,
            plate_country: data.country,
            attachmentData: attachments,
            caseData
        }
    }

    return [generateSalesforceCase]
}

export default useGenerateSalesforceCase
