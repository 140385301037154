import React from 'react'

import { useStyletron } from 'baseui'

import { ConfigurationOverrideObject } from 'baseui/helpers/overrides'
import { PLACEMENT } from 'baseui/popover'
import { useTranslation } from 'react-i18next'
import countries from '../../assets/countries.json'
import CountryDropdown from './CountryDropdown'
import CountryFlag from './CountryFlag'
import AustrianLPInput from './CountryInputs/AustrianLPInput'
import DanishLPInput from './CountryInputs/DanishLPInput'
import GermanLPInput from './CountryInputs/GermanLPInput'
import IntLPInput from './CountryInputs/IntLPInput'
import ItalianLPInput from './CountryInputs/ItalianLPInput'
import PolishLPInput from './CountryInputs/PolishLPInput'
import SwissLPInput from './CountryInputs/SwissLPInput'
import LicensePlateInputProvider from './LicensePlateInputContext'

export interface IFormPlateInputOverrides {
    DropdownButton?: ConfigurationOverrideObject
    LPContainer?: ConfigurationOverrideObject
    LPInnerContainer?: ConfigurationOverrideObject
    Input?: ConfigurationOverrideObject
    CircularBadge?: ConfigurationOverrideObject
    PlateBadge?: ConfigurationOverrideObject
    PlateCountryPart?: ConfigurationOverrideObject
}

export interface IFormPlateInput {
    label?: string
    country?: string
    countrySelection?: string[]
    value?: string
    readOnly?: boolean
    error?: string
    disabled?: boolean
    onChange?: (value: string) => void
    onCountryChange?: (value: string) => void
    overrides?: IFormPlateInputOverrides
    withLabel?: boolean
    withKeyboard?: boolean
    width?: string
    dropdowPlacement?: keyof typeof PLACEMENT
}

const FormPlateInput = ({
    value = '',
    country,
    countrySelection,
    error,
    disabled,
    readOnly = false,
    onChange = () => {},
    onCountryChange = () => {},
    overrides,
    withLabel = false,
    withKeyboard = false,
    dropdowPlacement,
    width
}: IFormPlateInput) => {
    const { t } = useTranslation()
    const [css, theme] = useStyletron()
    const [firstPartEmptyError, setFirstPartEmptyError] = React.useState(false)

    if (!Object.values(countries).includes(country || '')) {
        country = countries.foreign
    }

    let countriesInOrder = countrySelection || []

    React.useMemo(() => {
        Object.values(countries).map((c: string) => {
            if (!!!countriesInOrder.includes(c)) {
                return countriesInOrder.push(c)
            }
        })
    }, [countriesInOrder])

    React.useEffect(() => {
        if (firstPartEmptyError) {
            setFirstPartEmptyError(false)
        }
    }, [country])

    const context = {
        value,
        onChange,
        disabled,
        error: !!error,
        overrides,
        readOnly,
        withKeyboard,
        firstPartEmptyError,
        setFirstPartEmptyError
    }

    return (
        <>
            <div
                className={css({
                    display: 'flex',
                    width,
                    flexDirection: 'column',
                    gap: disabled ? '0px' : theme.sizing.scale500,
                    [theme.mediaQuery.medium]: {
                        flexDirection: 'row'
                    }
                })}
            >
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column'
                    })}
                >
                    {withLabel && (
                        <div
                            className={css({
                                marginBottom: theme.sizing.scale200,
                                ...theme.typography.LabelMedium,
                                [theme.mediaQuery.medium]: {
                                    ...theme.typography.LabelLarge
                                }
                            })}
                        >
                            {t('lpInput.labels.country')}
                        </div>
                    )}
                    {!disabled && !readOnly ? (
                        <div
                            className={css({
                                display: 'flex',
                                height: '100%',
                                justifyContent: 'center'
                            })}
                        >
                            <CountryDropdown
                                country={country}
                                countries={countriesInOrder}
                                onCountryChange={onCountryChange}
                                dropdowPlacement={dropdowPlacement}
                                overrides={overrides?.DropdownButton}
                            >
                                <CountryFlag
                                    src={require(`../../assets/flags/${country?.toLowerCase()}.svg`)}
                                    alt={`${country?.toLowerCase()}-svg`}
                                    label={country}
                                    overrides={overrides?.DropdownButton}
                                />
                            </CountryDropdown>
                        </div>
                    ) : null}
                </div>
                <div
                    className={css({
                        display: 'flex',
                        flexGrow: '1',
                        flexDirection: 'column'
                    })}
                >
                    {withLabel && (
                        <div
                            className={css({
                                marginBottom: theme.sizing.scale200,
                                ...theme.typography.LabelMedium,
                                [theme.mediaQuery.medium]: {
                                    ...theme.typography.LabelLarge
                                }
                            })}
                        >
                            {t('lpInput.labels.plate')}
                        </div>
                    )}
                    <div
                        className={css({
                            display: 'flex',
                            flexGrow: '1',
                            height: '100%',
                            alignItems: 'center',
                            flexDirection: 'column'
                        })}
                    >
                        <LicensePlateInputProvider context={context}>
                            {country === countries.foreign && <IntLPInput />}
                            {country === countries.germany && <GermanLPInput />}
                            {country === countries.austria && <AustrianLPInput />}
                            {country === countries.switzerland && <SwissLPInput />}
                            {country === countries.italy && <ItalianLPInput />}
                            {country === countries.denmark && <DanishLPInput />}
                            {country === countries.poland && <PolishLPInput />}
                        </LicensePlateInputProvider>
                    </div>
                </div>
            </div>
            {firstPartEmptyError && (
                <div
                    className={css({
                        color: '#D92D20',
                        fontSize: '18px',
                        marginTop: theme.sizing.scale400
                    })}
                >
                    {t('lpInput.error.firstPartMissing')}
                </div>
            )}
        </>
    )
}

export default FormPlateInput
