import React from 'react'
import { ModalContext } from '../ModalProvider'

const useModal = <T extends any[]>(
    id: string,
    modalGenerator: (hideModal: () => void, ...args: T) => React.ReactNode
) => {
    const context = React.useContext(ModalContext)

    return React.useMemo(() => {
        const showModal = (...args: T) => {
            context.addModal(
                id,
                modalGenerator(() => context.removeModal(id), ...args)
            )
        }

        const hideModal = () => {
            context.removeModal(id)
        }

        return [showModal, hideModal]
    }, [context])
}

export default useModal
