import { useStyletron } from 'baseui'
import { Modal } from 'baseui/modal'
import { Spinner } from 'baseui/spinner'
import React from 'react'

const MemberEntryLoading = () => {
    const [css] = useStyletron()
    return (
        <Modal isOpen={true} closeable={false} overrides={{ Dialog: { style: { backgroundColor: 'transparent' } } }}>
            <div className={css({ width: '100%', display: 'flex', justifyContent: 'center' })}>
                <Spinner $size="100" $borderWidth="8" />
            </div>
        </Modal>
    )
}

export default MemberEntryLoading
