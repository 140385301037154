import { useStyletron } from 'baseui'
import { BigNumber } from 'bignumber.js'
import React from 'react'

import { Cell, Grid } from 'baseui/layout-grid'
import { EvEntryContext } from '../../EvEntryContext'

const formatFileSize = (value: number) => {
    if (value > 1000000) return `${BigNumber(value).dividedBy(1000000).toFixed(2)}Mb`
    if (value > 1000) return `${BigNumber(value).dividedBy(1000000).toFixed(2)}Kb`
    return `${value}b`
}

const FilesList = () => {
    const [css, theme] = useStyletron()

    const { data, edit } = React.useContext(EvEntryContext)

    const isImage = (type: string) => type.split('/')[0] === 'image'

    if (data.attachments.length === 0) return null

    return (
        <div
            className={css({
                marginTop: data.attachments.length ? theme.sizing.scale900 : theme.sizing.scale100,
                marginBottom: data.attachments.length ? theme.sizing.scale1000 : theme.sizing.scale550,
                [theme.mediaQuery.medium]: {
                    marginTop: data.attachments.length ? theme.sizing.scale700 : '72px',
                    marginBottom: data.attachments.length ? theme.sizing.scale1400 : theme.sizing.scale1200
                },
                border: data.attachments.length ? '1px solid #E5E5E5' : 'none',
                borderRadius: '25px'
            })}
        >
            {data.attachments.map((attachment, index) => (
                <Grid
                    gridColumns={6}
                    gridMargins={19}
                    key={attachment.id}
                    overrides={{
                        Grid: {
                            style: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingBottom: theme.sizing.scale600,
                                paddingTop: index === 0 ? theme.sizing.scale600 : 0
                            }
                        }
                    }}
                >
                    <Cell span={4}>
                        <div className={css({ display: 'flex', alignItems: 'center' })}>
                            <div
                                className={css({
                                    marginRight: theme.sizing.scale400
                                })}
                            >
                                {isImage(attachment.type) ? (
                                    <i className="wemolo-image-1" />
                                ) : (
                                    <i className="wemolo-document-check" />
                                )}
                            </div>
                            <div
                                className={css({
                                    fontSize: theme.sizing.scale500,
                                    lineHeight: '23px',
                                    color: theme.colors.contentAccent,
                                    minHeight: '23px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    width: '200px'
                                })}
                            >
                                {attachment.name}
                            </div>
                            <div
                                className={css({
                                    ...theme.typography.ParagraphXSmall,
                                    marginRight: theme.sizing.scale200,
                                    marginLeft: theme.sizing.scale600
                                })}
                            >
                                {formatFileSize(attachment.size)}
                            </div>
                        </div>
                    </Cell>
                    <Cell>
                        <div className={css({ display: 'flex', justifyContent: 'end' })}>
                            <div
                                role="button"
                                onClick={() => {
                                    edit((draft) => {
                                        draft.attachments = [
                                            ...draft.attachments.filter(
                                                (existingAttachment) => existingAttachment.id !== attachment.id
                                            )
                                        ]
                                    })
                                }}
                                className={css({
                                    marginLeft: 'auto',
                                    paddingLeft: theme.sizing.scale400,
                                    display: 'flex'
                                })}
                            >
                                <i
                                    className={`${css({
                                        color: theme.colors.primary400,
                                        cursor: 'pointer',
                                        ':hover': {
                                            color: theme.colors.primary300
                                        }
                                    })} wemolo-cross`}
                                />
                            </div>
                        </div>
                    </Cell>
                </Grid>
            ))}
        </div>
    )
}

export default FilesList
