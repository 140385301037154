import { styled, useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { HeadingLevel } from 'baseui/heading'
import { HeadingXSmall, LabelLarge, LabelMedium } from 'baseui/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ContactsSection from '../components/ContactsSection'

const ErrorBoundarySection = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '99vh',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    color: `${$theme.colors.white} !important`
}))

const TitleLabel = styled(HeadingXSmall, ({ $theme }) => ({
    color: `${$theme.colors.primary} !important`,
    fontSize: '1rem',
    margin: 0
}))

const DescriptionLabel = styled(LabelLarge, ({ $theme }) => ({
    color: $theme.colors.primary,
    marginBottom: $theme.sizing.scale600,
    marginTop: $theme.sizing.scale600
}))

const BrowsersSection = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '240px',
    marginTop: $theme.sizing.scale300
}))

const BrowserElement = styled('div', ({ $theme }) => ({
    width: '100%',
    paddingLeft: $theme.sizing.scale300,
    paddingRight: $theme.sizing.scale300,
    marginBottom: $theme.sizing.scale400,
    paddingBottom: $theme.sizing.scale100,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid'
}))

const BrowserLabel = styled(LabelMedium, ({ $theme }) => ({
    color: `${$theme.colors.white} !important`,
    paddingRight: $theme.sizing.scale300
}))

const BrowserArrowButton = styled(Button, ({ $theme }) => ({
    padding: `${$theme.sizing.scale100} !important`,
    backgroundColor: $theme.colors.primary,
    ':hover': {
        backgroundColor: $theme.colors.primary500
    }
}))

const UnsupportedBrowserIE = () => {
    const { t } = useTranslation()
    const [css] = useStyletron()

    const browsers: {
        title: string
        link: string
    }[] = [
        {
            title: 'Chrome',
            link: 'https://www.google.com/chrome/'
        },
        {
            title: 'Firefox',
            link: 'https://www.mozilla.org/firefox/new/'
        },
        {
            title: 'Microsoft Edge',
            link: 'https://www.microsoft.com/de-de/edge/'
        }
    ]

    const renderBrowsersList = () => {
        return browsers.map((browser) => {
            return (
                <BrowserElement>
                    <BrowserLabel>{browser.title}</BrowserLabel>
                    <a href={browser.link} className={css({ textDecoration: 'none' })}>
                        <BrowserArrowButton kind="primary" shape="round" size="compact">
                            <i className={`material-icons ${css({ fontSize: '22px' })}`}>arrow_forward</i>
                        </BrowserArrowButton>
                    </a>
                </BrowserElement>
            )
        })
    }

    return (
        <React.Fragment>
            <HeadingLevel>
                <ErrorBoundarySection>
                    <TitleLabel>{t('unsupportedBrowserPage.title')}</TitleLabel>
                    <DescriptionLabel>{t('unsupportedBrowserPage.switchTo')}</DescriptionLabel>
                    <BrowsersSection>{renderBrowsersList()}</BrowsersSection>
                    <ContactsSection />
                </ErrorBoundarySection>
            </HeadingLevel>
        </React.Fragment>
    )
}

export default UnsupportedBrowserIE
