import React from 'react'

/**
 * Creates a component containing a number of supplied React providers.
 * Avoids having to create deep React provider trees.
 *
 * @param providers Array of React providers and associated props
 * @returns a component containing all provided props, with the outermost provider being the first
 * in the supplied array and the innermost the last in the supplied array.
 */
const concatProviders =
    (providers: Array<{ Component: React.ComponentType<any>; props?: Record<string, any> }>): React.FunctionComponent =>
    ({ children }) => {
        let OutputComponent = <>{children}</>

        const reversedProviders = [...providers].reverse()

        for (const provider of reversedProviders) {
            const { Component, props = {} } = provider
            OutputComponent = <Component {...props}>{OutputComponent}</Component>
        }

        return OutputComponent
    }

export default concatProviders
