import { generateContext } from 'parkdepot-shared/utils/contextGenerator'

export interface IMemberEntryIntermediate {
    plate: string
    created_at: string
    country: string
    language: string
    readPolicy: boolean
    anonymous: boolean
    whitelist_duration: string
    purpose: string
    end_date?: string
    email?: string
    name?: string
}

export interface IMemberEntry {
    plate: string
    created_at: string
    country: string
    purpose: string
    whitelist_duration: string
    start_date?: string
    end_date?: string
    email?: string
    name?: string
}

export interface IMemberEntryContext {
    data: IMemberEntry & { email?: string; readPolicy: boolean; anonymous: boolean }
    edit: (produce: (draft: IMemberEntryIntermediate) => void) => void
    resetErrors: () => void
    create: () => void
    cancel: () => void
    validationErrors: { [key: string]: string }
    loading: boolean
    entryCreated: boolean
    jwt_id?: number | string
    sendEmail: () => Promise<{ message?: string; error?: boolean }>
}

const [MemberEntryContext, MemberEntryProvider] = generateContext<IMemberEntryContext>()

export { MemberEntryContext }
export default MemberEntryProvider
