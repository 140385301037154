import React from 'react'
import { styled } from 'baseui'
import { useTranslation } from 'react-i18next'

import LanguageSelect from './LanguageSelect/LanguageSelect'
import { LabelLarge, LabelSmall, MonoDisplayMedium } from 'baseui/typography'

const StyledMainSection = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '99vh',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    color: `${$theme.colors.white} !important`,
    margin: '0px auto'
}))

const StyledMonoDisplayMedium = styled(MonoDisplayMedium, ({ $theme }) => ({
    color: `${$theme.colors.white} !important`,
    margin: '0px'
}))

const StyledTitle = styled(LabelLarge, ({ $theme }) => ({
    color: `${$theme.colors.white} !important`,
    fontSize: '0.9rem !important',
    margin: '0px'
}))

const StyledDescription = styled(LabelSmall, ({ $theme }) => ({
    maxWidth: '700px',
    color: $theme.colors.white,
    marginBottom: $theme.sizing.scale600,
    marginTop: $theme.sizing.scale600,
    paddingLeft: $theme.sizing.scale400,
    paddingRight: $theme.sizing.scale400
}))

const StyledBottomBar = styled('div', ({ $theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '70px',
    paddingTop: $theme.sizing.scale800,
    paddingBottom: $theme.sizing.scale800,
    paddingLeft: $theme.sizing.scale800,
    paddingRight: $theme.sizing.scale800,
    position: 'sticky',
    alignSelf: 'center',
    justifyContent: 'center',
    bottom: 0,
    zIndex: 1,
    [$theme.mediaQuery.large]: {
        borderBottomLeftRadius: $theme.borders.radius400,
        borderBottomRightRadius: $theme.borders.radius400
    }
}))

const PageNotFound = () => {
    const { t } = useTranslation(['base'])

    return (
        <StyledMainSection>
            <StyledMonoDisplayMedium>
                <code>404</code>
            </StyledMonoDisplayMedium>
            <StyledTitle>{t('base.pageNotFound.title')}</StyledTitle>
            <StyledDescription>{t('base.pageNotFound.description')}</StyledDescription>
            <StyledBottomBar>
                <LanguageSelect />
            </StyledBottomBar>
        </StyledMainSection>
    )
}

export default PageNotFound
