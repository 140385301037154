import primitives from './primitives'

export const borders = () => ({
    /** True */
    useRoundedCorners: true,
    /** 100px */
    buttonBorderRadius: 100 + 'px',
    /** 100px */
    inputBorderRadius: 100 + 'px',
    /** 24px */
    popoverBorderRadius: 8 + 'px',
    /** 24px */
    surfaceBorderRadius: 8 + 'px',
    /** 24px */
    tagBorderRadius: 24 + 'px',
    /** 24px */
    modalBorderRadius: 24 + 'px',
    /** 2px solid #7882FF */
    buttonPrimaryHoverBorder: `2px solid ${primitives.primary300}`,
    /** 2px solid #5663FF */
    buttonSecondaryBorder: `2px solid ${primitives.primary}`,
    /** 2px solid #5663FF */
    buttonSecondaryDarkBorder: `2px solid ${primitives.white}`,
    /** 2px solid #5663FF */
    buttonIconSecondaryBorder: `2px solid ${primitives.primary}`,
    /** 2px solid #5663FF */
    buttonIconSecondaryDarkBorder: `2px solid ${primitives.primary}`,
    /** 2px solid #EAECF0 */
    buttonSecondaryBorderDisabled: `2px solid ${primitives.mono200}`,
    /** 2px solid #EAECF0 */
    buttonSecondaryBorderDarkDisabled: `2px solid ${primitives.mono200}`,
    /** 2px solid #B9C0CB */
    buttonIconSecondaryBorderDisabled: `2px solid ${primitives.mono300}`,
    /** 2px solid #B9C0CB */
    buttonIconSecondaryDarkBorderDisabled: `2px solid ${primitives.mono300}`,
    /** 2px solid #7882FF */
    buttonIconSecondaryHoverBorder: `2px solid ${primitives.primary300}`,
    /** 2px solid #7882FF */
    buttonIconSecondaryDarkHoverBorder: `2px solid ${primitives.primary300}`
})
