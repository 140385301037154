/** @typedef {primary} #5663FF */

export const primitives = {
    /**  'Albert Sans', sans-serif */
    primaryFontFamily: "'Albert Sans', sans-serif",
    /** #000000 */
    primaryA: '#000000',
    /** #FFFFFF */
    primaryB: '#FFFFFF',
    /** #5663FF */
    primary: '#5663FF',
    /** #F7F7FF */
    primary50: '#F7F7FF',
    /** #DDE0FF */
    primary100: '#DDE0FF',
    /** #9AA1FF */
    primary200: '#9AA1FF',
    /** #7882FF */
    primary300: '#7882FF',
    /** #5663FF */
    primary400: '#5663FF',
    /** #5663FF */
    primary500: '#5663FF',
    /** #5663FF */
    primary600: '#5663FF',
    /** #5663FF */
    primary700: '#5663FF',

    /** #000000 */
    accent: '#000000',
    /** #cacaca */
    accent50: '#cacaca',
    /** #a6a6a6 */
    accent100: '#a6a6a6',
    /** #949494 */
    accent200: '#949494',
    /** #717171 */
    accent300: '#717171',
    /** #454545 */
    accent400: '#454545',
    /** #363636 */
    accent500: '#363636',
    /** #272727 */
    accent600: '#272727',
    /** #171717 */
    accent700: '#171717',

    /** #FFFFFF */
    white: '#FFFFFF',
    /** #FFFFFF */
    black: '#000000',

    /** #FAFAFA */
    mono100: '#FAFAFA',
    /** #EAECF0 */
    mono200: '#EAECF0',
    /** #B9C0CB */
    mono300: '#B9C0CB',
    /** #667085 */
    mono400: '#667085',
    /** #000000 */
    mono500: '#000000',
    /** #000000 */
    mono600: '#000000',
    /** #000000 */
    mono700: '#000000',
    /** #000000 */
    mono800: '#000000',
    /** #000000 */
    mono900: '#000000',
    /** #000000 */
    mono1000: '#000000',

    /** #D42434 */
    negative: '#D42434',
    /** #FFF2F5 */
    negative50: '#FFF2F5',
    /** #FFF2F5 */
    negative100: '#FFF2F5',
    /** #FFF2F5 */
    negative200: '#D42434',
    /** #FFF2F5 */
    negative300: '#D42434',
    /** #FFF2F5 */
    negative400: '#D42434',
    /** #FFF2F5 */
    negative500: '#D42434',
    /** #FFF2F5 */
    negative600: '#D42434',
    /** #D42434 */
    negative700: '#D42434',

    /** #B54708 */
    warning: '#B54708',
    /** #FFFAEB */
    warning50: '#FFFAEB',
    /** #FFFAEB */
    warning100: '#FFFAEB',
    /** #FFC043 */
    warning200: '#FFC043',
    /** #FFC043 */
    warning300: '#FFC043',
    /** #FFC043 */
    warning400: '#FFC043',
    /** #FFC043 */
    warning500: '#FFC043',
    /** #FFC043 */
    warning600: '#FFC043',
    /** #B54708 */
    warning700: '#B54708',

    /** #027A48 */
    positive: '#027A48',
    /** #E6FFE7 */
    positive50: '#E6FFE7',
    /** #E6FFE7 */
    positive100: '#E6FFE7',
    /** #039855 */
    positive200: '#039855',
    /** #039855 */
    positive300: '#039855',
    /** #039855 */
    positive400: '#039855',
    /** #039855 */
    positive500: '#039855',
    /** #039855 */
    positive600: '#039855',
    /** #027A48 */
    positive700: '#027A48',

    /** #FFF5F5 */
    red50: '#FFF5F5',
    /** #FFD5D8 */
    red200: '#FFD5D8',
    /** #FF808B */
    red600: '#FF808B',
    /** #FE2C3E */
    red700: '#FE2C3E'
}

export default primitives
