import { captureException } from '@sentry/react'
import axios, { AxiosError, AxiosResponse } from 'axios'

const axiosWithSentry = axios.create()

/* 

Sentry checks in the callOnHub method called by captureException (and all other method),
If Sentry has been initialised and if instance is running
Therefore we don't have to worry about Sentry being enabled or not

*/
axiosWithSentry.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.status !== 200) captureException(response.data)
        return response
    },
    (error: AxiosError) => {
        captureException(error)
        return Promise.reject(error)
    }
)

export default axiosWithSentry
