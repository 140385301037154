import {
    ANALYTICS_TYPES,
    CANCELLATIONS_REASONS,
    EMPLOYEE_DESCRIPTION,
    FEATURES,
    WHITELIST_REASONS
} from 'parkdepot-shared/models/user'
import React from 'react'

export interface IWhitelistMetadata {
    anonymous: boolean
    whitelistLimit: number
    // "name", "7_digit_number", "8_digit_number"
    employeeDescription: EMPLOYEE_DESCRIPTION
    reason: WHITELIST_REASONS[]
    // PT24H
    duration_limit: string
}

export interface ICancellationsData {
    freeCancellations: number
    reason: CANCELLATIONS_REASONS[]
}

export interface IResidentMetadata {
    whitelistLimit: number
    duration_limit: string
}

export type IAnalyticsMetadata = {
    [key in ANALYTICS_TYPES]: boolean
}

export interface IUserMetadata {
    [FEATURES.ANALYTICS]: IAnalyticsMetadata
    [FEATURES.CUSTOMER_WHITELIST]: IWhitelistMetadata
    [FEATURES.CANCELLATIONS]: ICancellationsData
    [FEATURES.RESIDENT]: IResidentMetadata
    [FEATURES.USER_MANAGEMENT]: any
    [FEATURES.BLACKLIST]: any
}

export interface IUserConfigurationMetadata {
    render_nps_modal: boolean
}

export interface IDecodedToken {
    company_name: string
    user_metadata: {
        [FEATURES.ANALYTICS]: IAnalyticsMetadata
        [FEATURES.CUSTOMER_WHITELIST]: {
            anonymous: boolean
            whitelist_limit: number
            employee_description: EMPLOYEE_DESCRIPTION
            reason: string[]
            duration_limit: string
        }
        [FEATURES.CANCELLATIONS]: {
            free_cancellations_per_month: number
            reason: string[]
        }
        [FEATURES.RESIDENT]: {
            whitelist_limit: number
            duration_limit: string
        }
        [FEATURES.USER_MANAGEMENT]: any
        [FEATURES.BLACKLIST]: any
    }
    lot_countries: Record<string, number>
    visible_company_ids: string[]
    visible_lot_ids: string[]
    general: {
        language: string
        logrocketConsent: boolean
        video_version: number
    }
    user_configuration: IUserConfigurationMetadata
}

export interface IActivatedFeatures {
    whitelist: boolean
    blacklist: boolean
    analytics: boolean
    cancellation: boolean
    userManagement: boolean
    resident: boolean
}

export interface ILogin {
    auth0_id?: string
    user_id: string
    activatedFeatures: IActivatedFeatures
    company_id: string
    company_name: string
    sortedLotCountries?: string[]
    companyIds: string[]
    lotIds: string[]
    whitelist?: IWhitelistMetadata
    cancellations?: ICancellationsData
    resident?: IResidentMetadata
    analytics?: IAnalyticsMetadata
    user_configuration?: IUserConfigurationMetadata
}

export type IWhitelistLotPermissions = {
    days_limitation?: number
}

export interface ILoginContextData {
    data: ILogin
    logout: () => void
}

export const LoginContext = React.createContext<ILoginContextData>({ loading: false } as any)

interface ILoginProvider {
    context: ILoginContextData
}

const LoginProvider: React.FunctionComponent<ILoginProvider> = ({ context, children }) => (
    <LoginContext.Provider value={context}>{children}</LoginContext.Provider>
)

export default LoginProvider
