import Joi from '@hapi/joi'

export const whitelistValidation = Joi.object({
    // Disallow spaces and asterisk
    plate: Joi.string().pattern(/^[^\s*]*$/),
    readPolicy: Joi.boolean(),
    name: Joi.when('anonymous', {
        is: false,
        then: Joi.string()
    })
}).unknown()

export const emailValidation = Joi.string()
    .email({ tlds: { allow: false } })
    .required()
