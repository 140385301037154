import { BaseProvider } from 'baseui'
import { SnackbarProvider } from 'baseui/snackbar'
import React from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'

import theme from './theme'

const engine = new Styletron()

const Base: React.FunctionComponent = ({ children }) => {
    return (
        <StyletronProvider value={engine}>
            <BaseProvider theme={theme}>
                <SnackbarProvider>{children}</SnackbarProvider>
            </BaseProvider>
        </StyletronProvider>
    )
}

export default Base
