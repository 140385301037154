import theme, { CUSTOM_MEDIA_QUERIES } from '../AppFrame/theme'

export const TABLET_TYPOGRAHPY_OVERRIDE = {
    small: {
        [CUSTOM_MEDIA_QUERIES.tablet]: {
            fontSize: theme.sizing.scale600,
            lineHeight: theme.sizing.scale700
        }
    },
    medium: {
        [CUSTOM_MEDIA_QUERIES.tablet]: {
            fontSize: theme.sizing.scale700,
            lineHeight: theme.sizing.scale800
        }
    },
    large: {
        [CUSTOM_MEDIA_QUERIES.tablet]: {
            fontSize: theme.sizing.scale800,
            lineHeight: theme.sizing.scale900
        }
    }
}

export const GUEST_UI_STYLES = {
    HEADER: {
        fontWeight: 600,
        marginTop: theme.sizing.scale600,
        marginBottom: theme.sizing.scale600,
        [CUSTOM_MEDIA_QUERIES.phone]: {
            fontSize: theme.sizing.scale800,
            lineHeight: theme.sizing.scale900,
            paddingLeft: '16px',
            paddingRight: '16px'
        },
        [CUSTOM_MEDIA_QUERIES.tablet]: {
            fontSize: theme.sizing.scale1400
        },
        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
            flexDirection: 'row',
            alignItems: 'center'
        },
        [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
            fontSize: theme.sizing.scale1200
        },
        [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
            fontSize: theme.sizing.scale1600
        },
        [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
            fontSize: theme.sizing.scale2400
        }
    },
    LICENSE_PLATE: {
        HEIGHT: {
            [CUSTOM_MEDIA_QUERIES.phone]: {
                height: '80px'
            },
            [CUSTOM_MEDIA_QUERIES.tablet]: {
                height: theme.sizing.scale2400
            },
            [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
                height: theme.sizing.scale2400
            },
            [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
                height: theme.sizing.scale3200
            },
            [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
                fontSize: theme.sizing.scale4800
            }
        },
        INPUT_CONTAINER: {
            display: 'flex',
            flexDirection: 'column',
            [CUSTOM_MEDIA_QUERIES.largerThanSmallTablet]: {
                flexDirection: 'row'
            }
        },
        INPUT: {
            [CUSTOM_MEDIA_QUERIES.phone]: {
                ...theme.typography.font350
            },
            [CUSTOM_MEDIA_QUERIES.tablet]: {
                ...theme.typography.font1050
            },
            [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
                ...theme.typography.font1350
            },
            [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
                ...theme.typography.font1350
            },
            [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
                ...theme.typography.font1450
            }
        },
        BUTTON: {
            borderBottomLeftRadius: '2px',
            borderBottomRightRadius: '2px',
            borderTopLeftRadius: '2px',
            borderTopRightRadius: '2px',
            [CUSTOM_MEDIA_QUERIES.phone]: {
                ...theme.typography.font300
            },
            [CUSTOM_MEDIA_QUERIES.tablet]: {
                ...theme.typography.font850
            },
            [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
                ...theme.typography.font850
            },
            [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
                ...theme.typography.font850
            },
            [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
                ...theme.typography.font1250
            }
        },
        BADGES: {
            CIRCULAR: {
                [CUSTOM_MEDIA_QUERIES.phone]: {
                    height: theme.sizing.scale400,
                    width: theme.sizing.scale400
                },
                [CUSTOM_MEDIA_QUERIES.tablet]: {
                    height: theme.sizing.scale600,
                    width: theme.sizing.scale600
                },
                [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
                    height: theme.sizing.scale600,
                    width: theme.sizing.scale600
                },
                [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
                    height: theme.sizing.scale800,
                    width: theme.sizing.scale800
                },
                [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
                    height: theme.sizing.scale800,
                    width: theme.sizing.scale800
                }
            },
            PLATE: {
                [CUSTOM_MEDIA_QUERIES.phone]: {
                    height: theme.sizing.scale400,
                    width: theme.sizing.scale100
                },
                [CUSTOM_MEDIA_QUERIES.tablet]: {
                    width: theme.sizing.scale600,
                    height: theme.sizing.scale200
                },
                [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
                    width: theme.sizing.scale600,
                    height: theme.sizing.scale400
                },
                [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
                    width: theme.sizing.scale800,
                    height: theme.sizing.scale400
                },
                [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
                    width: theme.sizing.scale800,
                    height: theme.sizing.scale500
                }
            }
        }
    },
    NAME_INPUT: {
        padding: '12px 24px',
        [CUSTOM_MEDIA_QUERIES.phone]: {
            fontSize: theme.typography.font350
        },
        [CUSTOM_MEDIA_QUERIES.tablet]: {
            fontSize: theme.typography.font1050
        },
        [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
            fontSize: theme.typography.font1150
        },
        [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
            fontSize: theme.typography.font1250
        },
        [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
            fontSize: theme.typography.font1350
        }
    },
    CONFIRM_BUTTON: {
        [CUSTOM_MEDIA_QUERIES.phone]: {
            ...theme.typography.font550,
            padding: '30px',
            fontWeight: 600
        },
        [CUSTOM_MEDIA_QUERIES.tablet]: {
            ...theme.typography.font550,
            fontWeight: 600
        },
        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
            padding: '40px',
            fontWeight: 600,
            minWidth: '230px'
        },
        [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
            ...theme.typography.font550,
            fontWeight: 600
        },
        [CUSTOM_MEDIA_QUERIES.tabletLarge]: {
            ...theme.typography.font950,
            fontWeight: 600
        },
        [CUSTOM_MEDIA_QUERIES.tabletXLarge]: {
            ...theme.typography.font1250,
            fontWeight: 600
        }
    },
    SUCCESS_HEADER: {
        fontWeight: 600,
        [CUSTOM_MEDIA_QUERIES.phone]: {
            fontSize: '22px',
            marginTop: theme.sizing.scale600,
            marginBottom: theme.sizing.scale600
        },
        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
            fontSize: '44px',
            marginTop: theme.sizing.scale700,
            marginBottom: theme.sizing.scale700
        }
    },
    INPUT_EMAIL_SUCCESS: {
        [CUSTOM_MEDIA_QUERIES.phone]: {
            fontSize: '18px',
            height: '60px'
        },
        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
            height: '80px',
            fontSize: '24px'
        }
    },
    CONFIRM_SUCCESS_BUTTON: {
        transition: 'box-shadow 0.1s ease-in-out',
        boxShadow: '0px 4px 0px 0px #ACACAC',
        ':focus:not([disabled])': {
            boxShadow: 'none'
        },
        ':hover:not([disabled])': {
            boxShadow: '0px 2px 0px 0px #ACACAC'
        },
        [CUSTOM_MEDIA_QUERIES.phone]: {
            ...theme.typography.font450,
            fontWeight: 600,
            height: '60px'
        },
        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
            height: '80px'
        },
        [CUSTOM_MEDIA_QUERIES.tablet]: {
            ...theme.typography.font650,
            fontWeight: 600
        },
        [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
            ...theme.typography.font850,
            fontWeight: 600,
            height: '100px'
        }
    },
    SUCCESS_LP_INPUT: {
        [CUSTOM_MEDIA_QUERIES.phone]: {
            ...theme.typography.font350
        },
        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
            ...theme.typography.font1050
        }
    }
}

export const COMMON_GHOST_BUTTON_OVERRIDE = {
    ':focus': {
        color: '#ACACAC',
        backgroundColor: 'transparent'
    },
    ':hover': {
        color: '#ACACAC',
        backgroundColor: 'transparent'
    }
}

interface IGetSuccessModalKeyboardTransition {
    screenHeight: number
    offset?: number
    keyboardSize: number
    shouldGoUp?: boolean
}

// eslint-disable-next-line
export const GetSuccessModalKeyboardTransition = ({
    offset,
    keyboardSize,
    shouldGoUp
}: IGetSuccessModalKeyboardTransition) => {
    return {
        marginTop: offset && shouldGoUp ? `-${offset - keyboardSize + 60}px` : theme.sizing.scale1200
    }
}
