import { Duration } from 'luxon'
import i18n from '../i18n'

export const getDurationStringFromIS0 = (duration: string) => {
    const { years, months, weeks, days, hours, minutes } = Duration.fromISO(duration)
        .shiftTo('years', 'months', 'weeks', 'days', 'hours', 'minutes')
        .toObject()

    const durationStringParts = []

    if (years === 1) {
        durationStringParts.push(i18n.t(`base.date.year`, { year: years }))
    } else if (years && years > 1) {
        durationStringParts.push(i18n.t(`base.date.years`, { years: years }))
    }

    if (months === 1) {
        durationStringParts.push(i18n.t(`base.date.month`, { month: months }))
    } else if (months && months > 1) {
        durationStringParts.push(i18n.t(`base.date.months`, { months: months }))
    }

    if (weeks === 1) {
        durationStringParts.push(i18n.t(`base.date.week`, { week: weeks }))
    } else if (weeks && weeks > 1) {
        durationStringParts.push(i18n.t(`base.date.weeks`, { weeks: weeks }))
    }

    if (days === 1) {
        durationStringParts.push(i18n.t(`base.date.day`, { day: days }))
    } else if (days && days > 1) {
        durationStringParts.push(i18n.t(`base.date.days`, { days: days }))
    } else if (days && hours && minutes === 0) {
        durationStringParts.push(i18n.t(`base.date.hours`, { hours: days * 24 + hours }))
    }

    if (durationStringParts.length === 0 && hours && hours < 5 && minutes) {
        return i18n.t(`base.date.minutes`, { minutes: hours * 60 + minutes })
    } else if (hours === 1) {
        durationStringParts.push(i18n.t(`base.date.hour`, { hour: hours }))
    } else if (hours && hours > 1) {
        durationStringParts.push(i18n.t(`base.date.hours`, { hours: hours }))
    }

    if (minutes === 1) {
        durationStringParts.push(i18n.t(`base.date.minute`, { minute: minutes }))
    } else if (minutes && minutes > 1) {
        durationStringParts.push(i18n.t(`base.date.minutes`, { minutes: minutes }))
    }

    return durationStringParts.length ? durationStringParts.join(' ') : ''
}
