import { styled } from 'baseui'

const StyledTopBar = styled<'div', { $paddingSmall?: boolean; $sticky?: boolean; $shadow?: boolean }>(
    'div',
    ({ $paddingSmall, $sticky, $theme, $shadow = false }) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '70px',
        padding: $paddingSmall ? $theme.sizing.scale300 : $theme.sizing.scale600,
        position: $sticky ? 'sticky' : 'static',
        background: $sticky ? $theme.colors.backgroundAlwaysLight : undefined,
        top: 0,
        zIndex: 1,
        boxShadow: $shadow ? $theme.lighting.shadow400 : undefined,
        [$theme.mediaQuery.large]: {
            borderTopLeftRadius: $theme.borders.radius400,
            borderTopRightRadius: $theme.borders.radius400
        }
    })
)

export default StyledTopBar
