import { styled } from '../../../../theme'

export const ModalFooter = styled('div', ({ $theme }) => ({
    textAlign: 'right',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '24px',
    paddingTop: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '0px',
    ...$theme.typography.ParagraphSmall,
    lineHeight: $theme.typography.ParagraphSmall.fontSize
}))
