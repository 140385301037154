import React from 'react'
import { styled } from 'baseui'
import { useTranslation } from 'react-i18next'

import { HeadingLevel } from 'baseui/heading'
import { LabelLarge, LabelSmall } from 'baseui/typography'

import LanguageSelect from './LanguageSelect/LanguageSelect'
import { useParams } from 'react-router-dom'

const ErrorBoundarySection = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '99vh',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    color: `${$theme.colors.white} !important`,
    margin: '0px auto'
}))

const StyledTitle = styled(LabelLarge, ({ $theme }) => ({
    color: `${$theme.colors.white} !important`,
    fontSize: '1.8rem !important',
    margin: '1.2rem'
}))

const StyledDescription = styled(LabelSmall, ({ $theme }) => ({
    maxWidth: '700px',
    color: $theme.colors.white,
    marginBottom: $theme.sizing.scale600,
    marginTop: $theme.sizing.scale600,
    paddingLeft: $theme.sizing.scale400,
    paddingRight: $theme.sizing.scale400
}))

const StyledBottomBar = styled('div', ({ $theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '70px',
    paddingTop: $theme.sizing.scale800,
    paddingBottom: $theme.sizing.scale800,
    paddingLeft: $theme.sizing.scale800,
    paddingRight: $theme.sizing.scale800,
    position: 'sticky',
    alignSelf: 'center',
    justifyContent: 'center',
    bottom: 0,
    zIndex: 1,
    [$theme.mediaQuery.large]: {
        borderBottomLeftRadius: $theme.borders.radius400,
        borderBottomRightRadius: $theme.borders.radius400
    }
}))

const routeToTranslationKeyMapping: Record<string, string> = {
    'insufficient-permissions': 'insufficientPermission',
    'outside-opening-hours': 'outsideOpeningHours',
    'expired-token': 'expiredToken'
}

const GuestAccessError = () => {
    const { t } = useTranslation(['base'])
    const { errorType } = useParams() as { errorType: string }
    const translationKey = routeToTranslationKeyMapping[errorType]

    return (
        <HeadingLevel>
            <ErrorBoundarySection>
                <StyledTitle>{t(`base.${translationKey}.title`)}</StyledTitle>
                <StyledDescription>{t(`base.${translationKey}.description`)}</StyledDescription>
                <StyledBottomBar>
                    <LanguageSelect />
                </StyledBottomBar>
            </ErrorBoundarySection>
        </HeadingLevel>
    )
}

export default GuestAccessError
