import { Modal, ModalProps } from 'baseui/modal'
import { useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { useHistory } from 'react-router-dom'

/** Modal that goes fullscreen on mobile devices */
const MobileModal = ({
    children,
    atLeastFullHeight = true,
    ...props
}: ModalProps & { atLeastFullHeight?: boolean }) => {
    const [css, theme] = useStyletron()
    const navigator = useHistory()

    React.useEffect(() => {
        if (props.isOpen) {
            const shouldGoBackRef = { goBack: true }

            const modalId = (window.history.state?.state?.id || 0) + 1

            navigator.push({ pathname: window.location.pathname, search: navigator.location.search }, { id: modalId })

            const listener = (event: PopStateEvent) => {
                if (!event.state.state || event.state.state.id < modalId) {
                    // The back event already relates to the modal, no need to go back again
                    shouldGoBackRef.goBack = false
                    if (props.onClose) {
                        props.onClose({ closeSource: undefined })
                    } else {
                        navigator.push(
                            { pathname: window.location.pathname, search: navigator.location.search },
                            { id: modalId }
                        )
                    }
                }
            }

            window.addEventListener('popstate', listener)

            return () => {
                window.removeEventListener('popstate', listener)

                if (shouldGoBackRef.goBack) {
                    navigator.goBack()
                }
            }
        }
    }, [props.isOpen])

    return (
        <Modal
            {...props}
            animate={false}
            overrides={{
                ...props.overrides,
                Close: {
                    component: ({ onClick }) => {
                        return (
                            <div
                                onClick={onClick}
                                className={css({
                                    color: theme.colors.primary,
                                    padding: theme.sizing.scale200,
                                    position: 'fixed',
                                    top: theme.sizing.scale600,
                                    right: theme.sizing.scale600,
                                    ':hover': { cursor: 'pointer' }
                                })}
                            >
                                <i className="wemolo-cross" />
                            </div>
                        )
                    }
                },
                Dialog: {
                    style: {
                        width: '100vw',
                        ...(atLeastFullHeight && { minHeight: 'var(--vh, 100vh)' }),
                        marginTop: 0,
                        marginBottom: 0,
                        marginLeft: 0,
                        marginRight: 0,
                        [theme.mediaQuery.medium]: {
                            width: '500px',
                            marginTop: theme.sizing.scale600,
                            marginBottom: theme.sizing.scale600
                        },
                        ...(props.overrides?.Dialog?.style || {})
                    }
                }
            }}
        >
            {children}
        </Modal>
    )
}

export default MobileModal
