import React from 'react'

export const useChangeUsercentricsLanguage = (lang: string) => {
    const UC_UI = (window as unknown as { UC_UI: { updateLanguage: (lang: string) => void } })?.UC_UI

    React.useEffect(() => {
        if (lang) {
            //In case the UC_UI is not fully loaded, we wait for it to be loaded and then update the language
            if (!UC_UI) {
                let interval = setInterval(() => {
                    if ((window as unknown as { UC_UI: { updateLanguage: (lang: string) => void } })?.UC_UI)
                        (
                            window as unknown as { UC_UI: { updateLanguage: (lang: string) => void } }
                        )?.UC_UI?.updateLanguage(lang)
                    clearInterval(interval)
                }, 500)
            } else {
                UC_UI?.updateLanguage(lang)
            }
        }
    }, [lang, UC_UI])

    return
}

export default useChangeUsercentricsLanguage
