import theme, { styled } from '../../../../theme'

export const ModalHeader = styled<'div', { $centered?: boolean }>('div', ({ $centered = false }) => ({
    paddingTop: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingBottom: '8px',
    marginLeft: '0px',
    marginRight: '0px',
    marginTop: '0px',
    marginBottom: '0px',
    textAlign: $centered ? 'center' : 'start',
    ...theme.typography.LabelLarge,
    lineHeight: theme.typography.LabelLarge.fontSize
}))
