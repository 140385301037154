import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { ModalBody } from 'baseui/modal'
import { LabelLarge, LabelSmall, ParagraphSmall } from 'baseui/typography'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import MobileModal from 'parkdepot-shared/components/MobileModal'
import { useModal } from 'parkdepot-shared/components/Modal'
import { TABLET_TYPOGRAHPY_OVERRIDE } from '../MemberEntry/constants'

// eslint-disable-next-line
const HelpModal = ({
    onClose
}: {
    onClose: () => void
    isEmailFieldProvided: boolean
    isNameFieldProvided: boolean
}) => {
    const [css, theme] = useStyletron()
    const { t } = useTranslation()

    // TODO: Check modal -> unstable_ModalBackdropScroll is not anymore available on baseui
    return (
        <MobileModal
            autoFocus={false}
            isOpen
            onClose={onClose}
            atLeastFullHeight={false}
            overrides={{
                Root: {
                    style: {
                        zIndex: 2
                    }
                }
            }}
        >
            <ModalBody className={css({ marginTop: theme.sizing.scale900, marginBottom: theme.sizing.scale900 })}>
                <div className={css({ marginBottom: theme.sizing.scale600 })}>
                    <LabelLarge
                        className={css({
                            textAlign: 'center',
                            ...theme.typography.font550,
                            ...TABLET_TYPOGRAHPY_OVERRIDE.large
                        })}
                    >
                        {t('member.memberEntry.help.title')}
                    </LabelLarge>
                    <hr />
                </div>
                <LabelSmall className={css(TABLET_TYPOGRAHPY_OVERRIDE.medium)}>
                    {t('member.memberEntry.help.question1')}
                </LabelSmall>
                <ParagraphSmall
                    className={css({
                        marginBottom: theme.sizing.scale600,
                        ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                    })}
                >
                    {t('member.memberEntry.help.answer1')}
                </ParagraphSmall>
                <LabelSmall className={css(TABLET_TYPOGRAHPY_OVERRIDE.medium)}>
                    {t('member.memberEntry.help.question2')}
                </LabelSmall>
                <ParagraphSmall
                    className={css({
                        marginBottom: theme.sizing.scale600,
                        ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                    })}
                >
                    {t('member.memberEntry.help.answer2')}
                </ParagraphSmall>
                <LabelSmall className={css(TABLET_TYPOGRAHPY_OVERRIDE.medium)}>
                    {t('member.memberEntry.help.question3')}
                </LabelSmall>
                <ParagraphSmall
                    className={css({
                        marginBottom: theme.sizing.scale600,
                        ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                    })}
                >
                    {t('member.memberEntry.help.answer3')}
                </ParagraphSmall>
                <LabelSmall className={css(TABLET_TYPOGRAHPY_OVERRIDE.medium)}>
                    {t('member.memberEntry.help.question4')}
                </LabelSmall>
                <ParagraphSmall
                    className={css({
                        marginBottom: theme.sizing.scale600,
                        ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                    })}
                >
                    <Trans
                        i18nKey="member.memberEntry.help.answer4"
                        components={{
                            br: (
                                <>
                                    <span />
                                    <br />
                                </>
                            )
                        }}
                    />
                </ParagraphSmall>
                <Button
                    onClick={onClose}
                    overrides={{
                        Root: {
                            style: {
                                width: '100%',
                                ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                            }
                        }
                    }}
                >
                    {t('common.button.close')}
                </Button>
            </ModalBody>
        </MobileModal>
    )
}

const useHelpModal = () => {
    const [showModal, hideModal] = useModal('privacyPolicy', (hideModal, isEmailFieldProvided, isNameFieldProvided) => (
        <HelpModal
            onClose={hideModal}
            isEmailFieldProvided={isEmailFieldProvided}
            isNameFieldProvided={isNameFieldProvided}
        />
    ))

    return [showModal, hideModal] as const
}

export default useHelpModal
