import { generateContext } from 'parkdepot-shared/utils/contextGenerator'

export interface IAttachment {
    name: string
    body: File
    type: string
    size: number
    id: string
}

export interface IEvEntryIntermediate {
    plate: string
    created_at: string
    country: string
    language: string
    email: string
    attachments: IAttachment[]
}

export interface IEvEntry {
    plate: string
    created_at: string
    country: string
    email: string
    attachments: IAttachment[]
}

export interface IEvEntryContext {
    data: IEvEntry
    edit: (produce: (draft: IEvEntryIntermediate) => void) => void
    create: () => void
    validateEntry: () => void
    validationErrors: { [key: string]: string }
    loading: boolean
}

const [EvEntryContext, EvEntryProvider] = generateContext<IEvEntryContext>()

export { EvEntryContext }
export default EvEntryProvider
