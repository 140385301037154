import React from 'react'
import { useImmer } from 'use-immer'

import { generateConsentContext } from 'parkdepot-shared/utils/consentContextGenerator'
import { MixpanelContainer } from 'parkdepot-shared/views/tracking'

const INITIAL_PERMISSIONS = {
    allowedMixpanel: false,
    allowedSalesforce: true
}

interface IPermissions {
    allowedSalesforce: boolean
    allowedMixpanel: boolean
}

interface IConsentDetails {
    Mixpanel?: boolean
    Salesforce?: boolean
}

const [ConsentContext, getConsentContainer] = generateConsentContext<IPermissions, IConsentDetails>()

export const GuestConsentContainer: React.FC = ({ children }) => {
    const [permissions, setPermission] = useImmer(INITIAL_PERMISSIONS)

    const handleConsentChange = React.useCallback(
        (e: CustomEvent<IConsentDetails>) => {
            if (e.type === 'consentChanged' || e.type === 'UC_SDK_EVENT') {
                setPermission((draft) => {
                    draft.allowedSalesforce = !!e.detail?.Salesforce
                    draft.allowedMixpanel = !!e.detail?.Mixpanel
                })
            }
        },
        [setPermission]
    )

    const ConsentContainer = React.useMemo(() => getConsentContainer(handleConsentChange), [handleConsentChange])

    return (
        <ConsentContainer context={permissions}>
            <MixpanelContainer mixpanel_active={permissions.allowedMixpanel}>{children}</MixpanelContainer>
        </ConsentContainer>
    )
}

export { ConsentContext }
export default GuestConsentContainer
