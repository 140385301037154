import { IUserMetadata } from './models/user'

export type Option = {
    value: string
    label: string
}

export type Definition = {
    kind: string
    operation?: string
}

export type Tag = {
    text: string
    id: string
}

export type ReasonInfo = {
    [key: string]: string
}

export interface IWhitelistEntry {
    id?: string
    name: string
    plate: string
    country: string
    lot?: ILot
    company?: ICompany
    user_id?: string
    purpose?: string
    end_date?: string
    created_at?: string
    start_date?: string
    plateCount?: number
    reason?: string
    reasonInfo?: ReasonInfo
    rruleString?: string
    rruleDuration?: string
    company_name?: string
    lot_name?: string
    lot_address?: string
}

export interface ILot {
    id: string
    name: string
    address: string
    state: string
    size: string
    country?: string
    currency?: string
    lot_type?: string
    lotSections: string[]
    ownCompanies: string[]
    kba_registered: boolean
    go_live_date: string | null
}

export interface IRejectedLot extends ILot {
    replaced: boolean
}

export type ICancellationEntryBase = {
    id: number
    case_id: string
    cancellation_date: string
    cancellation_issuer: string
    cancellation_reason: string
    plate: string
    plate_country: string
    note?: string
    parking_lot?: string
    company_id?: string
}

export type ChainId = string

export interface ICompany {
    id: string
    chainId: ChainId
    name: string
    address: string
}

export interface IUser {
    id: string
    name: string
    nickname: string
    email: string
    own_company: string
}

export interface IBlacklistEntry {
    id: number
    comment: string
    plate: string
    lot?: ILot
    company_id: string
    company?: ICompany
    user_id?: string
    country: string
    start_date?: string
    end_date?: string
}

export interface IBlacklistUser {
    name?: string
    email?: string
}

export interface IUserPreference {
    general: {
        language?: string
        video_version?: number
        logrocketConsent?: boolean
        whitelistColumnOrder?: any
    }
    notification: {
        useEmail?: boolean
        email?: string
    }
    blacklist: {
        users?: IBlacklistUser[]
        ignoreMissingSubscribersWarning?: boolean
    }
    company_id?: string
    user_name?: string
    user_id?: string
    user_metadata?: IUserMetadata
}

export enum JWT_PURPOSE {
    CUSTOMER_WHITELIST = 'customer_whitelist',
    TIME_DISCOUNT = 'time_discount',
    EV_CHARGING_PROOF = 'ev_charging_proof'
}

export interface ICaseData {
    Case_Reason__c?: string
    Salutation__c?: string
    Description: string
    Status: string
    Subject: string
    Origin: string
    Country__c: string
    Parking_Lot_Id__c?: string
    Licence_Plate__c?: string
    File_number__c?: string
    Case_Web_Source__c: string
    Type: string
    SuppliedName: string
    SuppliedEmail: string
    SuppliedPhone: string
}

export interface ISalesforceData {
    caseData: ICaseData
    attachmentData: IAttachmentData[]
    plate?: string
    plate_country?: string
}

export interface IAttachmentData {
    Name: string
    Body: File
}

export const GUEST_ACCESS_LANGUAGES = [
    { value: 'DE', label: 'de' },
    { value: 'DE_AT', label: 'de-AT' },
    { value: 'DE_CH', label: 'de-CH' },
    { value: 'FR_CH', label: 'fr-CH' },
    { value: 'IT_CH', label: 'it-CH' },
    { value: 'IT', label: 'it' },
    { value: 'DK', label: 'da' },
    { value: 'PL', label: 'pl' }
]
