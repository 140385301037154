import { useStyletron } from 'baseui'
import { ConfigurationOverrideObject } from 'baseui/helpers/overrides'
import React from 'react'

interface ICountryFlag {
    onClick?: (event: React.MouseEvent) => void
    src: string
    alt: string
    label?: string
    marginOverride?: string
    overrides?: ConfigurationOverrideObject
}

const CountryFlag = ({ onClick, src, alt, label, marginOverride = '8px', overrides }: ICountryFlag) => {
    const [css, theme] = useStyletron()

    return (
        <div
            className={css({
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
                ...overrides
            })}
            onClick={onClick}
        >
            <img
                onClick={onClick}
                className={css({
                    width: '24px',
                    height: '24px',
                    borderRadius: '14px',
                    objectFit: 'cover'
                })}
                src={src}
                alt={alt}
            />
            <div
                className={css({
                    ...theme.typography.font300,
                    textAlign: 'start',
                    width: '100%',
                    margin: `0px ${marginOverride}`,
                    color: theme.colors.mono1000,
                    ...overrides
                })}
            >
                {label}
            </div>
        </div>
    )
}

export default CountryFlag
