import React from 'react'
import { useImmer } from 'use-immer'

interface IModalContext {
    addModal: (id: string, node: React.ReactNode) => void
    removeModal: (id: string) => void
}

export const ModalContext = React.createContext<IModalContext>({
    addModal: () => {},
    removeModal: () => {}
})

interface IModalProvider {
    children: React.ReactNode | React.ReactNodeArray
}

const ModalProvider = ({ children }: IModalProvider) => {
    const [activeModals, setActiveModals] = useImmer<{ [id: string]: React.ReactNode }>({})

    // Memoize context object to avoid expensive rerenders of the entire
    // app tree.
    const context = React.useMemo(() => {
        const addModal = (id: string, node: React.ReactNode) =>
            setActiveModals((draft) => {
                draft[id] = node
            })

        const removeModal = (id: string) =>
            setActiveModals((draft) => {
                delete draft[id]
            })

        return {
            addModal,
            removeModal
        }
    }, [])

    return (
        <ModalContext.Provider value={context}>
            {Object.entries(activeModals).map(([id, modal]) => (
                <React.Fragment key={id}>{modal}</React.Fragment>
            ))}
            {children}
        </ModalContext.Provider>
    )
}

export default ModalProvider
