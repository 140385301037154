import React from 'react'

import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { ConfigurationOverrideObject } from 'baseui/helpers/overrides'
import { ChevronDown, ChevronUp } from 'baseui/icon'
import { StatefulMenu } from 'baseui/menu'
import { PLACEMENT, Popover } from 'baseui/popover'
import { useTranslation } from 'react-i18next'
import useBreakpoint from '../../utils/hooks/useBreakpoint'
import CountryFlag from './CountryFlag'

interface ICountryDropdown {
    countries: string[]
    children: React.ReactNode
    country?: string
    onCountryChange: (value: string) => void
    overrides?: ConfigurationOverrideObject
    dropdowPlacement?: keyof typeof PLACEMENT
}

const CountryDropdown = ({
    countries,
    children,
    country,
    onCountryChange,
    overrides,
    dropdowPlacement
}: ICountryDropdown) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [, theme] = useStyletron()
    const { t } = useTranslation()

    const breakpoints = useBreakpoint()

    const items = countries.map((countryCode: string) => {
        const contryCodeLower = countryCode.toLowerCase()
        return {
            label: t(`lpInput.countries.country${countryCode}`),
            content: () => t(`lpInput.countries.country${countryCode}`),
            kind: country === countryCode ? 'secondary' : 'tertiary',
            onClick: () => onCountryChange(countryCode),
            src: (() => require(`../../assets/flags/${contryCodeLower}.svg`))(),
            alt: `${contryCodeLower}-svg`,
            placement: 'left'
        }
    })

    return (
        <Popover
            returnFocus
            autoFocus
            placement={
                breakpoints === 1
                    ? PLACEMENT.bottomRight
                    : (dropdowPlacement && PLACEMENT[dropdowPlacement]) || PLACEMENT.bottom
            }
            isOpen={isOpen}
            overrides={{
                Body: {
                    style: ({ $theme }) => ({
                        width: '100%',
                        backgroundColor: $theme.colors.transparent,
                        boxShadow: 'none',
                        padding: 0,
                        [theme.mediaQuery.medium]: {
                            width: 'auto'
                        }
                    })
                }
            }}
            onClick={() => setIsOpen((current) => !current)}
            onClickOutside={() => setIsOpen(false)}
            content={
                <StatefulMenu
                    items={items}
                    overrides={{
                        List: {
                            style: {
                                display: 'flex',
                                border: 'none',
                                width: '100%',
                                flexDirection: 'column',
                                paddingTop: 0,
                                paddingBottom: 0,
                                [theme.mediaQuery.medium]: {
                                    width: 'auto'
                                }
                            }
                        },
                        ListItem: {
                            style: {
                                padding: '0px'
                            }
                        },
                        Option: {
                            props: {
                                overrides: {
                                    ListItem: {
                                        component: React.forwardRef((props: any, _ref) => (
                                            <Button
                                                kind={props.item.kind}
                                                onClick={() => {
                                                    props.item.onClick()
                                                    setIsOpen(false)
                                                }}
                                                overrides={{
                                                    BaseButton: {
                                                        style: ({ $theme }) => ({
                                                            borderBottomLeftRadius: '0px !important',
                                                            borderBottomRightRadius: '0px !important',
                                                            borderTopLeftRadius: '0px !important',
                                                            borderTopRightRadius: '0px !important',
                                                            paddingTop: $theme.sizing.scale600,
                                                            paddingBottom: $theme.sizing.scale600,
                                                            backgroundColor:
                                                                props.item.kind === 'tertiary'
                                                                    ? $theme.colors.white
                                                                    : $theme.colors.primary100,
                                                            ':hover': {
                                                                backgroundColor:
                                                                    props.item.kind === 'tertiary'
                                                                        ? $theme.colors.primary50
                                                                        : theme.colors.primary200
                                                            },
                                                            ...overrides
                                                        })
                                                    }
                                                }}
                                            >
                                                <CountryFlag
                                                    onClick={() => {}}
                                                    marginOverride={'16px'}
                                                    src={props.item.src}
                                                    alt={props.item.alt}
                                                    label={props.item.label}
                                                />
                                            </Button>
                                        ))
                                    }
                                }
                            }
                        }
                    }}
                />
            }
        >
            <Button
                kind={'tertiary'}
                size="default"
                endEnhancer={() =>
                    isOpen ? <ChevronUp size={24} color="mono1000" /> : <ChevronDown size={24} color="mono1000" />
                }
                overrides={{
                    BaseButton: {
                        style: {
                            height: '100%',
                            padding: `8px ${theme.sizing.scale300}`,
                            border: `solid 1px ${theme.colors.mono300}`,
                            backgroundColor: theme.colors.white,
                            width: '100%',
                            [theme.mediaQuery.medium]: {
                                wdith: 'auto'
                            },
                            [':hover']: {
                                backgroundColor: theme.colors.primary50
                            },
                            ...overrides
                        }
                    },
                    EndEnhancer: {
                        style: ({ $theme }) => ({
                            marginLeft: `-${$theme.sizing.scale200}`
                        })
                    }
                }}
            >
                {children}
            </Button>
        </Popover>
    )
}

export default CountryDropdown
