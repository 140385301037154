import Joi from '@hapi/joi'

export const evChargingEntryValidation = Joi.object({
    // Disallow spaces and asterisk
    plate: Joi.string().pattern(/^[^\s*]*$/),
    email: Joi.string()
        .required()
        .email({ tlds: { allow: false }, allowUnicode: false }),
    country: Joi.string().required(),
    attachments: Joi.array().items(Joi.object()).min(1)
}).unknown()
