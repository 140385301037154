import { useStyletron } from 'baseui'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'baseui/button'
import { SnackbarElement } from 'baseui/snackbar'
import { LabelSmall } from 'baseui/typography'

export interface IErrorObj {
    translationKey: string
    files: File[]
    active: boolean
}

export interface IUploadErrorNotificationComponent {
    files: File[]
    translationKey: string
    dequeue: () => void
}

const UploadErrorNotificationComponent = ({ files, translationKey, dequeue }: IUploadErrorNotificationComponent) => {
    const { t } = useTranslation()
    const [css, theme] = useStyletron()

    const [filesListExpanded, setFilesListExpanded] = React.useState(false)

    return (
        <div
            className={css({
                backgroundColor: '#fff !important',
                background: '#fff !important'
            })}
        >
            <SnackbarElement
                startEnhancer={() => (
                    <i
                        className={` wemolo-warning-circle ${css({
                            filter: 'brightness(0) saturate(100%) invert(24%) sepia(77%) saturate(3634%) hue-rotate(351deg) brightness(88%) contrast(92%)'
                        })}`}
                    />
                )}
                message={t(translationKey)}
                // add a space to have action button
                actionMessage=" "
                actionOnClick={() => {
                    setFilesListExpanded(!filesListExpanded)
                }}
                focus={false}
                overrides={{
                    Root: {
                        style: {
                            backgroundColor: '#fff !important',
                            background: '#fff !important',
                            color: theme.colors.contentAccent,
                            boxShadow: 'none'
                        }
                    },
                    WrapActionButtonContainer: {
                        style: {
                            backgroundColor: theme.colors.black,
                            borderRadius: '0 !important',
                            boxShadow: 'none !important'
                        }
                    },
                    Content: {
                        style: {
                            alignItems: 'flex-start',
                            marginTop: theme.sizing.scale600,
                            marginBottom: theme.sizing.scale600,
                            paddingLeft: theme.sizing.scale550,
                            paddingRight: theme.sizing.scale700
                        }
                    },
                    Message: {
                        component: () => {
                            return (
                                <div
                                    className={css({
                                        marginLeft: theme.sizing.scale550,
                                        marginRight: theme.sizing.scale650
                                    })}
                                >
                                    <LabelSmall
                                        className={css({
                                            fontSize: theme.sizing.scale550,
                                            color: theme.colors.contentAccent,
                                            fontWeight: '600 !important',
                                            lineHeight: theme.sizing.scale700,
                                            marginBottom: theme.sizing.scale0
                                        })}
                                    >
                                        {t('ev.evEntry.attachments.errors.title')}
                                    </LabelSmall>
                                    <LabelSmall
                                        className={css({
                                            fontSize: theme.sizing.scale500,
                                            color: theme.colors.contentAccent,
                                            fontWeight: 400,
                                            lineHeight: theme.sizing.scale650
                                        })}
                                    >
                                        {t(translationKey)}
                                    </LabelSmall>
                                </div>
                            )
                        }
                    },
                    StartEnhancerContainer: {
                        style: {
                            padding: 0
                        }
                    },
                    ActionButtonContainer: {
                        style: {
                            backgroundColor: theme.colors.black,
                            borderRadius: '0 !important',
                            boxShadow: 'none !important',
                            flex: '0',
                            minWidth: '120px',
                            padding: 0
                        },
                        component: () => {
                            return (
                                <>
                                    <Button
                                        startEnhancer={<i className="wemolo-cross" />}
                                        size="compact"
                                        overrides={{
                                            StartEnhancer: {
                                                style: {
                                                    marginRight: '0 !important',
                                                    filter: 'brightness(0) saturate(100%) invert(14%) sepia(100%) saturate(4058%) hue-rotate(221deg) brightness(96%) contrast(96%)',
                                                    ':hover': {
                                                        filter: 'brightness(0) saturate(100%) invert(67%) sepia(69%) saturate(217%) hue-rotate(184deg) brightness(95%) contrast(105%)'
                                                    }
                                                }
                                            },
                                            Root: {
                                                style: {
                                                    padding: 0,
                                                    marginTop: theme.sizing.scale100,
                                                    ':hover': {
                                                        backgroundColor: 'transparent'
                                                    },
                                                    ':focus': {
                                                        backgroundColor: 'transparent'
                                                    }
                                                }
                                            }
                                        }}
                                        onClick={() => {
                                            dequeue()
                                        }}
                                    />
                                </>
                            )
                        }
                    }
                }}
            />
            <div
                className={css({
                    borderTop: '1px solid #000'
                })}
            >
                {files.map((file: File, index: number) => (
                    <div
                        key={file.name}
                        className={css({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            paddingLeft: theme.sizing.scale550,
                            paddingRight: theme.sizing.scale550,
                            paddingTop: theme.sizing.scale600,
                            paddingBottom: index === files.length - 1 ? theme.sizing.scale600 : 0,
                            maxWidth: '95vw'
                        })}
                    >
                        <div
                            className={css({
                                marginRight: theme.sizing.scale400
                            })}
                        >
                            <i className="wemolo-document-cross" />
                        </div>
                        <div
                            key={file.name}
                            className={css({
                                fontSize: theme.sizing.scale500,
                                lineHeight: theme.sizing.scale600,
                                color: theme.colors.contentAccent,
                                minHeight: '19px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap'
                            })}
                        >
                            {file.name}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default UploadErrorNotificationComponent
