export default {
    acceptedMimeTypes: {
        image: 'image/*',
        pdf: 'application/pdf'
    },
    maxUploadSize: 20000000,
    overallMaxSize: 100000000,
    maxPdfSize: 3300000,
    // sometimes it would add some extra bytes
    // left 0.2 for those extra bytes
    maxImageCompressionSizeMb: 3.1,
    maxImageWidthOrHeight: 1024
}
