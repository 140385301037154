import React, { useEffect, useState } from 'react'
import { useStyletron } from 'styletron-react'
import { BurgerIcon, WemoloIcon } from '../../assets/icons'
import { MobileHeaderDrawer } from './MobileHeaderDrawer'

export interface PublicHeaderProps {
    links?: Array<{ label: string; clicked: (e: React.MouseEvent) => void }>
    actions?: (isDark: boolean, isMobile?: boolean) => Array<React.ReactElement<{ onClick: () => void }>>
    onLogoClick?: () => void
    $dark?: boolean
    hideMobileDrawer?: boolean
}
const headerLength = 80
let lastScrollTop = 0
export const PublicHeader = ({ links, actions, onLogoClick, $dark = true, hideMobileDrawer }: PublicHeaderProps) => {
    const hasNoActionsNorLinks = (!links?.length || links.length === 0) && !actions
    const [css] = useStyletron()
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const mobileViewBreakpoint = 768
    const [isMobile, setIsMobile] = useState(hasNoActionsNorLinks ? false : window.innerWidth < mobileViewBreakpoint)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < mobileViewBreakpoint)
        }
        if (!hasNoActionsNorLinks) {
            window.addEventListener('resize', handleResize)
        }
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    let isDark = $dark

    const handleScroll = () => {
        const navbar = document.getElementById('header-public')
        if (!navbar) return
        let currentScroll = window.pageYOffset || document.documentElement.scrollTop
        if (currentScroll < headerLength) {
            isDark = $dark
            ;(navbar.style as CSSStyleDeclaration & { backdropFilter: string }).backdropFilter = 'blur(0px)'
            navbar.style.backgroundColor = 'transparent'
        }
        if (currentScroll > lastScrollTop) {
            // Downscroll
            navbar.style.top = `-${headerLength}px` // Adjust this value based on your navbar's height
        } else {
            // Upscroll
            if (currentScroll > headerLength) {
                //not scrolled top,  blured background
                if ($dark === false) {
                    isDark = true
                }
                ;(navbar.style as CSSStyleDeclaration & { backdropFilter: string }).backdropFilter = 'blur(16px)'
                navbar.style.backgroundColor = 'rgb(255 255 255 / 0.8)'
            }

            navbar.style.top = '0'
        }
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll // For Mobile or negative scrolling
    }

    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll, false)

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)
    const mobileView = () =>
        isMobile && (
            <>
                <MobileHeaderDrawer
                    isOpen={isDrawerOpen}
                    links={links}
                    isDark={isDark}
                    actions={actions}
                    close={() => {
                        toggleDrawer()
                    }}
                />
                <div
                    onClick={() => {
                        toggleDrawer()
                    }}
                >
                    <BurgerIcon $dark={isDark} />
                </div>
            </>
        )

    return (
        <div
            id="header-public"
            className={css({
                minHeight: '80px',
                backgroundColor: 'transparent',
                position: 'fixed',
                top: '0',
                width: '100%',
                transition: 'top 0.3s',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '10px 15px',
                zIndex: '1'
            })}
        >
            <div
                className={css({
                    display: 'flex',
                    justifyContent: isMobile ? 'space-between' : 'center',
                    width: '100%'
                })}
            >
                <div
                    onClick={onLogoClick}
                    className={css({
                        display: 'flex',
                        justifyContent: 'flex-start',
                        minWidth: '123px',
                        width: isMobile ? '123px' : '10%',
                        alignItems: 'center',
                        cursor: onLogoClick ? 'pointer' : 'cursor'
                    })}
                >
                    <WemoloIcon $dark={isDark} />
                </div>
                {!isMobile && (
                    <div
                        className={css({
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: '40%',
                            alignItems: 'center',
                            marginLeft: '40px'
                        })}
                    >
                        {links?.map((link) => (
                            <div
                                key={link.label}
                                onClick={link.clicked}
                                className={css({
                                    marginTop: '5px',
                                    fontSize: '18px',
                                    fontFamily: 'Albert Sans',
                                    lineHeight: '24px',
                                    marginRight: '30px',
                                    fontWeight: 700,
                                    color: isDark ? '#000' : 'white',
                                    position: 'relative',
                                    padding: '2px',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    '::after': {
                                        content: '""',
                                        position: 'absolute',
                                        left: '0',
                                        bottom: '0',
                                        height: '2px',
                                        backgroundColor: isDark ? '#000' : 'white',
                                        width: '0%' // Initialize width to 0
                                    },
                                    ':hover::after': {
                                        // Apply animation on hover
                                        animationName: {
                                            from: { width: '0%' },
                                            to: { width: '100%' }
                                        },

                                        animationDuration: '0.5s',
                                        animationFillMode: 'forwards'
                                    }
                                })}
                            >
                                {link.label}
                            </div>
                        ))}
                    </div>
                )}
                {!isMobile && (
                    <div
                        className={css({
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '40%',
                            alignItems: 'center'
                        })}
                    >
                        {actions?.(isDark)?.map((action, index) => (
                            <div key={index} className={css({ marginLeft: '20px' })}>
                                {action}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {!hideMobileDrawer && mobileView()}
        </div>
    )
}
