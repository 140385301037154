import { Delete } from 'baseui/icon'
import { useSnackbar } from 'baseui/snackbar'
import { DateTime } from 'luxon'
import countries from 'parkdepot-shared/assets/countries.json'
import { JWT_PURPOSE } from 'parkdepot-shared/types'
import useLicensePlateValidation from 'parkdepot-shared/utils/hooks/useLicensePlateValidation'
import useSalesforceAPI from 'parkdepot-shared/utils/hooks/useSalesforceAPI'
import { MixpanelContext } from 'parkdepot-shared/views/tracking'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useImmer } from 'use-immer'
import { getCountry, getLanguage } from '../../utils/helper'
import generateValidator from '../../utils/validateForms'
import { PreflightContext } from '../PreFlight/PreflightContext'
import EvChargingEntryProvider, { IEvEntryContext, IEvEntryIntermediate } from './EvEntryContext'
import useSuccessModal from './components/SuccessModal'
import useGenerateSalesforceCase from './hooks/useGenerateSalesforceCase'
import { evChargingEntryValidation as evChargingEntrySchema } from './validation/evEntryValidaiton'

const EvChargingContainer: React.FunctionComponent = ({ children }) => {
    const { t } = useTranslation()
    const { enqueue } = useSnackbar()
    const [errors, setErrors] = React.useState({})
    const [createLoading, setCreateLoading] = React.useState(false)

    const { track } = React.useContext(MixpanelContext)
    const { preflightData, preflightLoading } = React.useContext(PreflightContext)

    const [intermediateData, edit] = useImmer<IEvEntryIntermediate>({
        created_at: DateTime.utc().toJSDate().toISOString(),
        language: getLanguage(preflightData.language, preflightData.country) || countries.germany,
        country: getCountry(preflightData.language, preflightData.country) || countries.germany,
        email: '',
        plate: '',
        attachments: []
    })

    const data = {
        ...intermediateData,
        start_date: DateTime.local().toISO()
    }

    const validateLicensePlate = useLicensePlateValidation()

    const evChargingEntryValidation = generateValidator(evChargingEntrySchema, {
        'date.min': () => t('modals.createWhitelistEntry.errors.date.min'),
        'date.max': () => t('modals.createWhitelistEntry.errors.date.max'),
        'any.required': () => t('modals.createWhitelistEntry.errors.date.base'),
        'any.ref': () => t('modals.createWhitelistEntry.errors.date.base'),
        'any.only': () => t('errors.string.empty'),
        'string.pattern.base': () => t('modals.createWhitelistEntry.errors.plateNoWhitespace'),
        'string.empty': () => t('errors.string.empty'),
        'array.min': () => t('ev.evEntry.attachments.errors.missingAttachments')
    })

    const [showModal] = useSuccessModal()
    const [generateSalesForceCase] = useGenerateSalesforceCase()
    const { submitWebToCase } = useSalesforceAPI({ accessToken: preflightData.accessToken })

    const validateEntry = () => {
        const result = evChargingEntryValidation(data)
        const plateResult = validateLicensePlate(data.plate, data.country)

        return result.valid && plateResult.valid
    }

    const context: IEvEntryContext = {
        data,
        edit,
        loading: createLoading || preflightLoading,
        validationErrors: errors,
        validateEntry,
        create: async () => {
            const result = evChargingEntryValidation(data)
            const plateResult = validateLicensePlate(data.plate, data.country)

            if (validateEntry()) {
                setCreateLoading(true)

                try {
                    const generatedSalesforceCase = generateSalesForceCase(data)
                    const submitWebToCaseResult = await submitWebToCase(generatedSalesforceCase)

                    if (submitWebToCaseResult?.error) {
                        enqueue({
                            message: submitWebToCaseResult?.error.toString(),
                            startEnhancer: ({ size }) => <Delete size={size} />
                        })
                        setErrors({})
                        setCreateLoading(false)
                        return
                    }

                    track('whitelisted', {
                        plate: data.plate,
                        purpose: JWT_PURPOSE.EV_CHARGING_PROOF
                    })

                    showModal()
                    setErrors({})
                    setCreateLoading(false)
                } catch (e) {
                    setCreateLoading(false)
                    enqueue({
                        message: e as string,
                        startEnhancer: ({ size }) => <Delete size={size} />
                    })
                }
                return
            } else {
                setErrors({ ...result.errors, ...plateResult.errors })
            }
        }
    }

    return <EvChargingEntryProvider context={context}>{children}</EvChargingEntryProvider>
}

export default EvChargingContainer
