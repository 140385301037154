import { useStyletron } from 'baseui'
import { Drawer, SIZE } from 'baseui/drawer'
import React, { cloneElement } from 'react'
import { CloseIcon } from '../../assets/icons'

interface MobileHeaderDrawerProps {
    isOpen: boolean
    close: () => void
    links?: Array<{ label: string; clicked: (e: React.MouseEvent) => void }>
    actions?: (isDark: boolean, isMobile?: boolean) => Array<React.ReactElement<{ onClick: () => void }>>
    isDark: boolean
}

export const MobileHeaderDrawer = ({ isOpen, close, links, actions, isDark }: MobileHeaderDrawerProps) => {
    const [css] = useStyletron()

    const handleClick = (buttonOnClickFn?: () => void) => {
        close()

        if (buttonOnClickFn) buttonOnClickFn()
    }

    return (
        <Drawer
            animate={false}
            isOpen={isOpen}
            onClose={close}
            autoFocus={false}
            size={SIZE.full}
            overrides={{
                Root: {
                    style: {
                        zIndex: 2
                    }
                },
                DrawerContainer: {
                    style: {
                        width: '100%',
                        padding: '0px',
                        borderRadius: '0px'
                    }
                },
                DrawerBody: {
                    style: {
                        width: '100vw',
                        margin: '15px',
                        marginTop: '0px !important;'
                    }
                },

                Close: {
                    component: () => null
                }
            }}
        >
            <div className={css({ display: 'flex', flexDirection: 'column', height: '100%' })}>
                <div
                    onClick={() => close()}
                    className={css({
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        height: '60px'
                    })}
                >
                    <div
                        className={css({
                            paddingTop: '30px',
                            height: '25px'
                        })}
                    >
                        <CloseIcon />
                    </div>
                </div>
                <div
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '100%',
                        height: 'calc(100vh - 60px)'
                    })}
                >
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginTop: '0px',
                            width: '100%'
                        })}
                    >
                        {links?.map((link) => (
                            <div
                                onClick={link.clicked}
                                className={css({
                                    fontFamily: 'Albert Sans',
                                    letterSpacing: '-0.005em',
                                    fontSize: '40px',
                                    lineHeight: '48px',
                                    fontWeight: 700,
                                    marginTop: '2rem'
                                })}
                            >
                                {link.label}
                            </div>
                        ))}
                    </div>
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            marginTop: '60px',

                            width: '100%'
                        })}
                    >
                        {actions?.(true, true)?.map((action) => (
                            <div className={css({ marginTop: '15px', width: '100%' })}>
                                {cloneElement(action, { onClick: () => handleClick(action.props.onClick) })}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Drawer>
    )
}
