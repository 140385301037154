import React from 'react'
import { useTranslation } from 'react-i18next'

import { useStyletron } from 'baseui'
import { Button } from 'baseui/button'
import { HeadingLevel } from 'baseui/heading'
import { Modal, ROLE } from 'baseui/modal'
import { LabelMedium } from 'baseui/typography'

import confettiSVG from '../../../../assets/confetti.svg'

export interface ISuccessModal {
    hideModal(): void
}

const SuccessModal = ({ hideModal }: ISuccessModal) => {
    const [css, theme] = useStyletron()

    const { t } = useTranslation()

    const modalWrapperClass = css({
        width: '80vw',
        maxWidth: '1100px',
        paddingLeft: theme.sizing.scale850,
        paddingRight: theme.sizing.scale850,
        [theme.mediaQuery.small]: {
            width: '90vw'
        },
        [theme.mediaQuery.medium]: {
            paddingRight: '30px',
            paddingLeft: '30px',
            maxWidth: '620px'
        },
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.sizing.scale1200
    })

    const [isOpen, setIsOpen] = React.useState(true)

    return (
        <HeadingLevel>
            <Modal
                size="auto"
                onClose={() => {
                    setIsOpen(false)
                    hideModal()
                }}
                isOpen={isOpen}
                animate
                role={ROLE.dialog}
                overrides={{
                    Close: () => (
                        <Button
                            kind="primary"
                            startEnhancer={<i className="wemolo-cross" />}
                            size="compact"
                            autoFocus={false}
                            $isFocusVisible={false}
                            overrides={{
                                StartEnhancer: {
                                    style: {
                                        marginRight: '0 !important',
                                        filter: 'brightness(0) saturate(100%) invert(14%) sepia(100%) saturate(4058%) hue-rotate(221deg) brightness(96%) contrast(96%)',
                                        ':hover': {
                                            filter: 'brightness(0) saturate(100%) invert(67%) sepia(69%) saturate(217%) hue-rotate(184deg) brightness(95%) contrast(105%)'
                                        }
                                    }
                                },
                                Root: {
                                    style: {
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        paddingTop: theme.sizing.scale800,
                                        paddingRight: theme.sizing.scale800,
                                        backgroundColor: 'transparent',
                                        ':hover': {
                                            backgroundColor: 'transparent'
                                        },
                                        ':focus': {
                                            backgroundColor: 'transparent'
                                        }
                                    }
                                }
                            }}
                            onClick={() => {
                                setIsOpen(false)
                                hideModal()
                            }}
                        />
                    ),
                    Dialog: {
                        style: {
                            borderTopLeftRadius: theme.sizing.scale800,
                            borderTopRightRadius: theme.sizing.scale800,
                            borderBottomLeftRadius: theme.sizing.scale800,
                            borderBottomRightRadius: theme.sizing.scale800
                        }
                    },
                    Root: {
                        style: {
                            marginRight: '0 !important',
                            paddingRight: '0 !important'
                        }
                    }
                }}
            >
                <div className={modalWrapperClass}>
                    <div className={css({ overflow: 'hidden' })}>
                        <div
                            className={css({
                                marginTop: theme.sizing.scale1400,
                                textAlign: 'center'
                            })}
                        >
                            <img
                                className={css({
                                    filter: 'brightness(0) saturate(100%) invert(32%) sepia(82%) saturate(1357%) hue-rotate(128deg) brightness(98%) contrast(98%)',
                                    width: '40px',
                                    height: '40px',
                                    marginBottom: theme.sizing.scale300
                                })}
                                src={confettiSVG}
                                alt="success"
                            />
                            <LabelMedium
                                overrides={{
                                    Block: {
                                        style: {
                                            fontSize: theme.sizing.scale800,
                                            lineHeight: theme.sizing.scale900,
                                            marginBottom: theme.sizing.scale300,
                                            fontWeight: 600,
                                            color: theme.colors.black,
                                            textAlign: 'center'
                                        }
                                    }
                                }}
                            >
                                {t('ev.evEntry.successModal.title')}
                            </LabelMedium>
                            <LabelMedium
                                overrides={{
                                    Block: {
                                        style: {
                                            fontSize: theme.sizing.scale600,
                                            lineHeight: theme.sizing.scale800,
                                            fontWeight: 300,
                                            color: theme.colors.black,
                                            textAlign: 'center'
                                        }
                                    }
                                }}
                            >
                                {t('ev.evEntry.successModal.description')}
                            </LabelMedium>
                        </div>
                    </div>
                </div>
            </Modal>
        </HeadingLevel>
    )
}

export default SuccessModal
