import { styled } from 'baseui'
import { LabelMedium } from 'baseui/typography'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ContactsSectionProps {
    hideTitle?: boolean
}

const ContactsSection = ({ hideTitle = false }: ContactsSectionProps) => {
    const { t } = useTranslation(['common'])

    const ContactsSection = styled('div', ({ $theme }) => ({
        display: 'block',
        width: '100%',
        maxWidth: '740px',
        justifyContent: 'space-between',
        [$theme.mediaQuery.large]: {
            display: 'flex'
        }
    }))

    const ContactsColumn = styled('div', ({ $theme }) => ({
        flex: 1,
        marginTop: $theme.sizing.scale800
    }))

    const ContactsPrimaryLabel = styled('a', ({ $theme }) => ({
        display: 'block',
        color: $theme.colors.primary,
        margin: `${$theme.sizing.scale500} 0 0 0`,
        ...$theme.typography.font550,
        textDecoration: 'none'
    }))

    const ContactsSecondaryLabel = styled(LabelMedium, ({ $theme }) => ({
        color: $theme.colors.primary,
        margin: 0
    }))

    const ContactSupportLabel = styled(LabelMedium, ({ $theme }) => ({
        color: $theme.colors.primary,
        marginBottom: $theme.sizing.scale600,
        marginTop: $theme.sizing.scale900,
        paddingLeft: $theme.sizing.scale600,
        paddingRight: $theme.sizing.scale600
    }))

    return (
        <React.Fragment>
            {!hideTitle && <ContactSupportLabel>{t('common.supportContacts.title')}</ContactSupportLabel>}
            <ContactsSection>
                <ContactsColumn>
                    <ContactsSecondaryLabel>{t('common.supportContacts.emailLabel')}</ContactsSecondaryLabel>
                    <ContactsPrimaryLabel href={`mailto:${t('common.supportContacts.email')}`}>
                        {t('common.supportContacts.email')}
                    </ContactsPrimaryLabel>
                </ContactsColumn>
                <ContactsColumn>
                    <ContactsSecondaryLabel>{t('common.supportContacts.numberLabel')}</ContactsSecondaryLabel>
                    <ContactsPrimaryLabel href={`tel:${t('common.supportContacts.number')}`}>
                        {t('common.supportContacts.number')}
                    </ContactsPrimaryLabel>
                </ContactsColumn>
            </ContactsSection>
        </React.Fragment>
    )
}

export default ContactsSection
