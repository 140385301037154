import React from 'react'

export interface ITokenContextData {
    token: string | undefined
}

export const TokenContext = React.createContext<ITokenContextData>({ loading: false } as any)

interface ITokenProvider {
    context: ITokenContextData
}

const TokenProvider: React.FunctionComponent<ITokenProvider> = ({ context, children }) => (
    <TokenContext.Provider value={context}>{children}</TokenContext.Provider>
)

export default TokenProvider
