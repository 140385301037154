import React, { FunctionComponent, MutableRefObject } from 'react'
import Keyboard from 'react-simple-keyboard'
import 'react-simple-keyboard/build/css/index.css'
import { layouts, LAYOUT_KEYS } from './layouts'

export interface TouchKeyboardProps {
    onChange: (input: string) => void
    inputName?: string
    keyboardRef: MutableRefObject<any>
    layoutName?: string
    layout?: Record<string, string[]>
    display?: Record<string, string>
    withFormatting?: boolean
}

const DEFAULT_DISPLAY = {
    '{backspace}': '<i class="pkd-delete"/>',
    '{space}': '<i class="pkd-space/>'
}

const TouchKeyboard: FunctionComponent<TouchKeyboardProps> = ({
    onChange,
    keyboardRef,
    inputName,
    layoutName = 'default',
    layout = layouts[LAYOUT_KEYS.DEFAULT],
    display = DEFAULT_DISPLAY,
    withFormatting = true
}) => {
    return (
        <Keyboard
            // This is any since the lib does not have it typed (we don't know what it is)
            // eslint-disable-next-line no-return-assign, no-param-reassign
            keyboardRef={(r: any) => {
                if (keyboardRef) {
                    keyboardRef.current = r
                }
            }}
            preventMouseDownDefault={true}
            layoutName={layoutName}
            inputName={inputName}
            onChange={(value: string) => {
                const newValue = withFormatting ? value.trim() : value
                console.log('On change triggered')
                if (newValue.length <= 12 || !withFormatting) {
                    console.log('User typed: ', newValue)
                    onChange(newValue)
                } else {
                    console.log('User typed: ', newValue, 'but it exceeded character limit')
                    keyboardRef?.current.setInput(newValue.slice(0, -1))
                    onChange(newValue.slice(0, -1))
                }
            }}
            theme="hg-theme-default hg-layout-default myTheme"
            layout={layout}
            buttonTheme={[
                {
                    class: 'hg-button all-buttons',
                    buttons:
                        '1 2 3 4 5 6 7 8 9 0 Q W E R T Y U I O P Ü A S D F G H J K L Ö Ä Z X C V B N M {space} {backspace} . _ - @'
                },
                {
                    class: 'hg-special',
                    buttons: '. _ - @'
                },
                {
                    class: 'letters',
                    buttons: 'Q W E R T Y U I O P Ü A S D F G H J K L Ö Ä Z X C V B N M'
                },
                {
                    class: 'numbers',
                    buttons: '1 2 3 4 5 6 7 8 9 0'
                },
                {
                    class: 'hg-first',
                    buttons: '1 2 3 4 5 6 7 8 9 0'
                },
                {
                    class: 'hg-last',
                    buttons: 'Y X C V B N M {backspace}'
                },
                {
                    class: 'backspace',
                    buttons: '{backspace}'
                }
            ]}
            display={display}
        />
    )
}

export default TouchKeyboard
