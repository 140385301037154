import { useStyletron } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { Cell, Grid } from 'baseui/layout-grid'
import { LabelMedium } from 'baseui/typography'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND } from 'parkdepot-shared/components/Buttons/types'
import { Footer } from 'parkdepot-shared/components/Footer'
import LPInput from 'parkdepot-shared/components/LPInput'
import { PublicHeader } from 'parkdepot-shared/components/PublicHeader'
import StyledTopBar from 'parkdepot-shared/components/TopBar'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LANGUAGE_FALLBACKS, REVERSE_FALLBACK_MAP } from '../../i18n'
import { EvEntryContext } from './EvEntryContext'
import FileUploader from './components/FileUploader'
import FilesList from './components/FilesList'
import PrivacyPolicy from './components/PrivacyPolicy'

const EvEntryView = () => {
    const { t, i18n } = useTranslation()
    const [css, theme] = useStyletron()

    const { data, edit, validationErrors, create, validateEntry, loading } = React.useContext(EvEntryContext)

    React.useEffect(() => {
        document.body.classList.add('scrolling')

        return () => {
            document.body.classList.remove('scrolling')
        }
    })

    const bulletListTranslationKeys = ['receipt', 'proof']
    const isSubmitButtonEnabled = !!data.plate && !!data.country && data.attachments.length && !!data.email

    return (
        <div
            className={css({
                width: '100%',
                minHeight: 'var(--vh, 100vh)',
                margin: '0px auto',
                background: 'white',
                display: 'flex',
                flexDirection: 'column'
            })}
        >
            <StyledTopBar
                $shadow={false}
                className={css({
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                    marginBottom: theme.sizing.scale1200,
                    [theme.mediaQuery.medium]: {
                        paddingTop: theme.sizing.scale750,
                        paddingBottom: theme.sizing.scale750,
                        marginBottom: theme.sizing.scale1400
                    }
                })}
            >
                <PublicHeader />
            </StyledTopBar>
            <div
                className={css({
                    paddingLeft: theme.sizing.scale600,
                    paddingRight: theme.sizing.scale600,
                    marginBottom: theme.sizing.scale600
                })}
            >
                <div
                    className={css({
                        [theme.mediaQuery.medium]: {
                            paddingRight: '15vw',
                            paddingLeft: '15vw'
                        },
                        [theme.mediaQuery.large]: {
                            paddingRight: `calc(${theme.sizing.scale4800} + ${theme.sizing.scale1600})`,
                            paddingLeft: `calc(${theme.sizing.scale4800} + ${theme.sizing.scale1600})`
                        }
                    })}
                >
                    <h6
                        className={css({
                            ...theme.typography.HeadingLarge,
                            marginTop: 0,
                            marginBottom: theme.sizing.scale600,
                            color: theme.colors.black,
                            [theme.mediaQuery.medium]: {
                                lineHeight: theme.sizing.scale1200
                            }
                        })}
                    >
                        {t('ev.evEntry.title')}
                    </h6>
                    <ul
                        className={css({
                            paddingLeft: '27px'
                        })}
                    >
                        {bulletListTranslationKeys.map((key) => (
                            <li
                                key={key}
                                className={css({
                                    fontSize: theme.sizing.scale600,
                                    lineHeight: theme.sizing.scale800,
                                    color: theme.colors.contentAccent,
                                    fontFamily: "'Albert Sans', sans-serif",
                                    '::marker': {
                                        fontSize: theme.sizing.scale550,
                                        lineHeight: theme.sizing.scale950
                                    }
                                })}
                                // dangerouslySetInnerHTML used for interpolation
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: t(`ev.evEntry.bulletList.${key}`, {
                                        escapeInterpolation: false,
                                        interpolation: { escapeValue: false }
                                    })
                                }}
                            />
                        ))}
                    </ul>
                    <LabelMedium
                        className={css({
                            marginTop: theme.sizing.scale800,
                            lineHeight: theme.sizing.scale800,
                            color: `${theme.colors.contentAccent} !important`,
                            fontSize: theme.sizing.scale600
                        })}
                    >
                        {t('ev.evEntry.pleaseFillFields')}
                    </LabelMedium>
                    <Grid
                        gridColumns={[1, 1, 2]}
                        gridMargins={0}
                        gridGaps={1}
                        gridGutters={4}
                        overrides={{
                            Grid: {
                                style: {
                                    marginRight: 0,
                                    marginLeft: 0
                                }
                            },
                            GridWrapper: {
                                style: {
                                    marginRight: 0,
                                    marginLeft: 0
                                }
                            }
                        }}
                    >
                        <Cell span={2}>
                            <FormControl
                                label={t('ev.evEntry.fields.labels.licensePlate')}
                                overrides={{
                                    ControlContainer: {
                                        style: {
                                            [theme.mediaQuery.medium]: {
                                                maxWidth: '440px',
                                                marginBottom: theme.sizing.scale1200
                                            }
                                        }
                                    },
                                    Caption: {
                                        style: {
                                            fontSize: theme.sizing.scale400,
                                            lineHeight: theme.sizing.scale500,
                                            marginTop: theme.sizing.scale100
                                        }
                                    },
                                    LabelContainer: {
                                        style: {
                                            marginBottom: 0
                                        }
                                    },
                                    Label: {
                                        style: {
                                            lineHeight: theme.sizing.scale700,
                                            marginTop: theme.sizing.scale750,
                                            marginBottom: theme.sizing.scale100,
                                            color: theme.colors.contentAccent,
                                            [theme.mediaQuery.medium]: {
                                                fontSize: theme.sizing.scale550,
                                                lineHeight: theme.sizing.scale700
                                            }
                                        }
                                    }
                                }}
                                error={t(validationErrors.plate)}
                            >
                                <LPInput
                                    value={data.plate}
                                    country={data.country}
                                    countrySelection={[data.country]}
                                    onChange={(plate) => {
                                        edit((draft) => {
                                            draft.plate = plate
                                        })
                                    }}
                                    onCountryChange={(country) => {
                                        edit((draft) => {
                                            draft.country = country
                                        })
                                    }}
                                    error={validationErrors.plate}
                                />
                            </FormControl>
                        </Cell>
                        <Cell span={2}>
                            <FormControl
                                label={t('ev.evEntry.fields.labels.email')}
                                overrides={{
                                    ControlContainer: {
                                        style: {
                                            marginTop: 0,
                                            marginBottom: 0
                                        }
                                    },
                                    LabelContainer: {
                                        style: {
                                            marginTop: 0,
                                            marginBottom: 0
                                        }
                                    },
                                    Caption: {
                                        style: {
                                            fontSize: theme.sizing.scale400,
                                            lineHeight: theme.sizing.scale500,
                                            marginTop: theme.sizing.scale100
                                        }
                                    },
                                    Label: {
                                        style: {
                                            lineHeight: theme.sizing.scale700,
                                            marginBottom: theme.sizing.scale100,
                                            color: theme.colors.contentAccent,
                                            [theme.mediaQuery.medium]: {
                                                fontSize: theme.sizing.scale550
                                            }
                                        }
                                    }
                                }}
                                error={t(validationErrors.email)}
                            >
                                <Input
                                    placeholder={t('ev.evEntry.fields.placeholder.email')}
                                    value={data.email}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        const value = e.target.value.replace(/\|/, '')
                                        edit((draft) => {
                                            draft.email = value
                                        })
                                        validateEntry()
                                    }}
                                    overrides={{
                                        Input: {
                                            style: {
                                                backgroundColor: theme.colors.white,
                                                color: theme.colors.black,
                                                lineHeight: theme.sizing.scale800,
                                                paddingLeft: theme.sizing.scale800,
                                                paddingTop: theme.sizing.scale500,
                                                paddingBottom: theme.sizing.scale500,
                                                '::placeholder': {
                                                    color: '#ADADAD',
                                                    fontSize: theme.sizing.scale600,
                                                    lineHeight: theme.sizing.scale800
                                                }
                                            }
                                        },
                                        Root: {
                                            style: {
                                                backgroundColor: theme.colors.white,
                                                borderTopColor: '#CCCCCC',
                                                borderBottomColor: '#CCCCCC',
                                                borderLeftColor: '#CCCCCC',
                                                borderRightColor: '#CCCCCC',
                                                borderTopWidth: '1px',
                                                borderBottomWidth: '1px',
                                                borderLeftWidth: '1px',
                                                borderRightWidth: '1px',
                                                borderRightStyle: 'solid',
                                                borderLeftStyle: 'solid',
                                                borderBottomStyle: 'solid',
                                                borderTopStyle: 'solid',
                                                ':hover': {
                                                    borderTopColor: '#CCCCCC',
                                                    borderBottomColor: '#CCCCCC',
                                                    borderLeftColor: '#CCCCCC',
                                                    borderRightColor: '#CCCCCC'
                                                },
                                                ':focus': {
                                                    borderTopColor: '#CCCCCC',
                                                    borderBottomColor: '#CCCCCC',
                                                    borderLeftColor: '#CCCCCC',
                                                    borderRightColor: '#CCCCCC'
                                                },
                                                [theme.mediaQuery.medium]: {
                                                    maxWidth: '440px'
                                                }
                                            }
                                        }
                                    }}
                                    error={!!validationErrors.email}
                                />
                            </FormControl>
                        </Cell>
                    </Grid>
                    <FileUploader />
                    <FilesList />
                    <PrivacyPolicy />
                    <Button
                        $kind={BUTTON_KIND.primary}
                        isLoading={loading}
                        disabled={!isSubmitButtonEnabled}
                        onClick={create}
                        overrides={{
                            BaseButton: {
                                style: {
                                    marginBlock: theme.sizing.scale1200
                                }
                            }
                        }}
                    >
                        {t('ev.evEntry.button.sendLoadingReceipt')}
                    </Button>
                </div>
            </div>
            <div
                className={css({
                    backgroundColor: theme.colors.black,
                    width: '100%',
                    [theme.mediaQuery.medium]: {
                        paddingLeft: theme.sizing.scale600,
                        paddingRight: theme.sizing.scale600
                    },
                    [theme.mediaQuery.large]: {
                        paddingLeft: theme.sizing.scale1200,
                        paddingRight: theme.sizing.scale1200
                    }
                })}
            >
                <Footer
                    links={[
                        {
                            label: t('ev.evEntry.footer.imprint'),
                            clicked: () => {
                                window.open('https://www.wemolo.com/impressum', '_blank')
                            }
                        },
                        {
                            label: t('ev.evEntry.footer.dataProtection'),
                            clicked: () => {
                                window.open('https://www.wemolo.com/datenschutz', '_blank')
                            }
                        }
                    ]}
                    onLanguageChange={(newValue) => {
                        i18n.changeLanguage(LANGUAGE_FALLBACKS[newValue])
                    }}
                    selectedLanguage={REVERSE_FALLBACK_MAP.get(i18n.language)}
                />
            </div>
        </div>
    )
}

export default EvEntryView
