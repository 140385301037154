import { styled } from 'baseui'
import { Button, KIND, SHAPE } from 'baseui/button'
import { HeadingLevel } from 'baseui/heading'
import { HeadingXSmall, LabelLarge } from 'baseui/typography'
import { LanguageSelectOptions, Select } from 'parkdepot-shared/components/Select'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorBoundaryTheme } from '../GlobalErrorBoundary'
import ContactsSection from '../components/ContactsSection'

const ErrorBoundarySection = styled('div', ({ $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: $theme.colors.white,
    color: `${$theme.colors.white} !important`
}))

const TitleLabel = styled(HeadingXSmall, ({ $theme }) => ({
    color: `${$theme.colors.primary} !important`,
    fontSize: '1rem',
    margin: 0
}))

const RefreshPageLabel = styled(LabelLarge, ({ $theme }) => ({
    color: $theme.colors.primary,
    marginBottom: $theme.sizing.scale600,
    marginTop: $theme.sizing.scale600
}))

const LanguageSelectWrapper = styled('div', ({ $theme }) => ({
    marginTop: $theme.sizing.scale600
}))

const GlobalErrorView = ({ errorBoundaryTheme }: { errorBoundaryTheme?: ErrorBoundaryTheme }) => {
    const { t, i18n } = useTranslation()

    const refresh = () => {
        // using window instead of router
        // because component is outside of router
        window.location.reload()
    }

    let selectedOptionIndex = i18n.language
        ? LanguageSelectOptions.findIndex((e) => e.id === i18n.language.split('-')[0])
        : 0
    if (selectedOptionIndex === -1) selectedOptionIndex = 0

    return (
        <React.Fragment>
            <HeadingLevel>
                <ErrorBoundarySection>
                    <TitleLabel>{t('globalErrorPage.pageTitle')}</TitleLabel>
                    <RefreshPageLabel>{t('globalErrorPage.refreshPageLabel')}</RefreshPageLabel>
                    <Button
                        kind={KIND.primary}
                        shape={SHAPE.circle}
                        onClick={refresh}
                        overrides={{
                            BaseButton: {
                                style: ({ $theme }) => ({
                                    backgroundColor: $theme.colors.primary,
                                    ':hover': {
                                        backgroundColor: $theme.colors.primary200
                                    }
                                })
                            }
                        }}
                    >
                        <i className="material-icons">refresh</i>
                    </Button>
                    <ContactsSection />
                    <LanguageSelectWrapper>
                        <Select
                            options={LanguageSelectOptions}
                            value={{
                                value: LanguageSelectOptions[selectedOptionIndex].label,
                                id: LanguageSelectOptions[selectedOptionIndex].id
                            }}
                            onChange={(language) => i18n.changeLanguage(language[0].id as string)}
                        />
                    </LanguageSelectWrapper>
                </ErrorBoundarySection>
            </HeadingLevel>
        </React.Fragment>
    )
}

export default GlobalErrorView
