import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { LoginContext } from '../login'
import MixpanelProvider, { IMixpanelContext } from './MixpanelContext'

export const MixpanelContainer: React.FunctionComponent<{ mixpanel_active?: boolean }> = ({
    children,
    mixpanel_active = true
}) => {
    const mixpanel = require('mixpanel-browser')
    const loginContext = React.useContext(LoginContext)
    const loginID = loginContext?.data?.user_id
    const storedUUID = window.localStorage.getItem('mixpanel_uuid')
    const userId = loginID || storedUUID || uuidv4()
    const [mixpanelLoaded, setMixpanelLoaded] = React.useState(!!mixpanel.__loaded)

    React.useEffect(() => {
        if (!loginID && !storedUUID) {
            window.localStorage.setItem('mixpanel_uuid', userId)
        }
        if (mixpanel_active && !mixpanelLoaded) {
            mixpanel.init(process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN)
            mixpanel.identify(userId)
            mixpanel.people.set({ Email: userId })
            mixpanel.register_once({ Email: userId })
            setMixpanelLoaded(mixpanel.__loaded)
        }
    }, [mixpanel, userId, mixpanel_active])

    let MIXPANEL_ACTIVE = mixpanel_active && mixpanelLoaded

    const context: IMixpanelContext = {
        alias: (id: string) => {
            if (MIXPANEL_ACTIVE) mixpanel.alias(id)
        },
        track: (name: string, props: any) => {
            if (MIXPANEL_ACTIVE) {
                mixpanel.track(name, props)
            }
        },
        time_event: (name: string) => {
            if (MIXPANEL_ACTIVE) {
                mixpanel.time_event(name)
            }
        },
        people: {
            set: (props: any) => {
                if (MIXPANEL_ACTIVE) mixpanel.people.set(props)
            }
        }
    }
    return <MixpanelProvider context={context}>{children}</MixpanelProvider>
}
