import { styled } from '../../../../theme'

export const ModalBody = styled<'div', { $centered?: boolean }>('div', ({ $centered = false, $theme }) => ({
    paddingLeft: '24px',
    paddingRight: '24px',
    marginLeft: '0px',
    marginRight: '0px',
    marginBottom: '0px',
    marginTop: '0px',
    paddingBottom: $theme.sizing.scale900,
    textAlign: $centered ? 'center' : 'start',
    ...$theme.typography.ParagraphSmall
}))
