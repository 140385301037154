import React from 'react'

interface IUseKeyPress {
    /** Set to true if you want the shortcut to trigger when the corresponding character is pressed on the keyboard, regardless of its position. */
    useKey?: boolean
    modifiers?: { ctrl?: boolean; alt?: boolean; shift?: boolean; meta?: boolean }
    callbacks?: { onKeyDown?: (e: KeyboardEvent) => void; onKeyUp?: (e: KeyboardEvent) => void }
    active?: boolean
    disableIfInputActive?: boolean
}

export const useKeyPress = (
    targetKey: string | number,
    { modifiers = {}, callbacks = {}, active = true, disableIfInputActive = false, useKey = false }: IUseKeyPress = {}
) => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = React.useState(false)

    // If pressed key is our target key then set to true
    function downHandler(e: React.KeyboardEvent) {
        const { key, code, ctrlKey, altKey, shiftKey, metaKey } = e.nativeEvent

        const inputActive = document.activeElement instanceof HTMLInputElement
        if (disableIfInputActive && inputActive) {
            return
        }

        const pressedValue = useKey ? key : code

        if (
            active &&
            pressedValue === targetKey &&
            (modifiers.ctrl === undefined || modifiers.ctrl === ctrlKey) &&
            (modifiers.alt === undefined || modifiers.alt === altKey) &&
            (modifiers.shift === undefined || modifiers.shift === shiftKey) &&
            (modifiers.meta === undefined || modifiers.meta === metaKey)
        ) {
            e.preventDefault()
            e.stopPropagation()

            setKeyPressed(true)

            if (callbacks.onKeyDown) {
                callbacks.onKeyDown(e.nativeEvent)
            }
        }
    }

    // If released key is our target key then set to false
    const upHandler = (e: React.KeyboardEvent) => {
        const { key, code } = e.nativeEvent

        const pressedValue = useKey ? key : code

        if (active && pressedValue === targetKey) {
            e.preventDefault()
            e.stopPropagation()

            setKeyPressed(false)

            if (callbacks.onKeyUp) {
                callbacks.onKeyUp(e.nativeEvent)
            }
        }
    }

    return [downHandler, upHandler, { keyPressed }] as [typeof downHandler, typeof upHandler, { keyPressed: boolean }]
}

export const useGlobalKeyPress = (
    targetKey: string | number,
    { modifiers = {}, callbacks = {}, active = true, disableIfInputActive = false, useKey = false }: IUseKeyPress = {}
) => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = React.useState(false)

    // If pressed key is our target key then set to true
    function downHandler(e: KeyboardEvent) {
        const { key, code, ctrlKey, altKey, shiftKey, metaKey } = e

        const inputActive = document.activeElement instanceof HTMLInputElement
        if (disableIfInputActive && inputActive) {
            return
        }

        const pressedValue = useKey ? key : code

        if (
            pressedValue === targetKey &&
            (modifiers.ctrl === undefined || modifiers.ctrl === ctrlKey) &&
            (modifiers.alt === undefined || modifiers.alt === altKey) &&
            (modifiers.shift === undefined || modifiers.shift === shiftKey) &&
            (modifiers.meta === undefined || modifiers.meta === metaKey)
        ) {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()

            setKeyPressed(true)

            if (callbacks.onKeyDown) {
                callbacks.onKeyDown(e)
            }
        }
    }

    // If released key is our target key then set to false
    const upHandler = (e: KeyboardEvent) => {
        const { key, code } = e

        const pressedValue = useKey ? key : code

        if (pressedValue === targetKey) {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()

            setKeyPressed(false)

            if (callbacks.onKeyUp) {
                callbacks.onKeyUp(e)
            }
        }
    }

    // Add event listeners
    React.useEffect(() => {
        if (active) {
            window.addEventListener('keydown', downHandler)
            window.addEventListener('keyup', upHandler)
            // Remove event listeners on cleanup
            return () => {
                window.removeEventListener('keydown', downHandler)
                window.removeEventListener('keyup', upHandler)
            }
        }
    })

    return keyPressed
}
