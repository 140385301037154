import { AxiosError } from 'axios'
import { Delete } from 'baseui/icon'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { IMixpanelContext, MixpanelContext } from 'parkdepot-shared/views/tracking/MixpanelContext'
import React from 'react'
import { useTranslation } from 'react-i18next'

const useErrorMessageHandler = () => {
    const { t } = useTranslation()
    const { enqueue, dequeue } = useSnackbar()
    const { track } = React.useContext<IMixpanelContext>(MixpanelContext)

    const handleAPIError = (e: AxiosError) => {
        enqueue(
            {
                message: t('errors.genericBackendError'),
                startEnhancer: ({ size }) => <Delete size={size} onClick={dequeue} />,
                overrides: {
                    Root: {
                        style: ({ $theme }) => ({
                            backgroundColor: $theme.colors.negative100,
                            color: $theme.colors.negative,
                            borderColor: $theme.colors.negative
                        })
                    }
                }
            },
            DURATION.medium
        )
        track('errorGuestAPI', { error: e })
    }

    return {
        handleAPIError,
        hideErrorSnackbar: dequeue
    }
}

export default useErrorMessageHandler
