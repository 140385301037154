import { useStyletron } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { LabelLarge, LabelSmall } from 'baseui/typography'
import { DateTime } from 'luxon'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND } from 'parkdepot-shared/components/Buttons/types'
import LPInput from 'parkdepot-shared/components/LPInput'
import {
    DefaultTouchKeyboardProps,
    getLayoutPerLanguage,
    layouts,
    TouchKeyboardContext
} from 'parkdepot-shared/components/TouchKeyboard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useTimer from '../../hooks/useTimer'
import { getDurationStringFromIS0 } from '../../utils/datetime'
import { isMobileSafari } from '../../utils/isSafari'
import { CUSTOM_MEDIA_QUERIES } from '../AppFrame/theme'
import { GUEST_UI_STYLES, TABLET_TYPOGRAHPY_OVERRIDE } from './constants'
import { MemberEntryContext } from './MemberEntryContext'
import MemberEntryLoading from './MemberEntryLoading'
import MemberEntryTopbar from './MemberEntryTopbar'
import SuccessEntryModal from './SuccessEntryModal'

const MemberEntryView = () => {
    const { t, i18n } = useTranslation()
    const [css, theme] = useStyletron()

    const { showKeyboard, hideKeyboard, hasKeyboard, keyboardRef } = React.useContext(TouchKeyboardContext)

    const keyboardLanguage = getLayoutPerLanguage(i18n.language)

    const { data, edit, create, validationErrors, loading, resetErrors, entryCreated } =
        React.useContext(MemberEntryContext)

    const time = useTimer()

    React.useEffect(() => {
        document.body.classList.add('scrolling')
        return () => {
            document.body.classList.remove('scrolling')
        }
    })

    const onChangeInput = (plate: string): void => {
        resetErrors()
        edit((draft) => {
            draft.plate = plate
        })
    }

    const onCountryChange = (country: string) => {
        resetErrors()
        edit((draft) => {
            draft.country = country
        })
    }

    React.useEffect(() => {
        const container = document.getElementById('member_entry_lp_container')
        const htmlTag = document.documentElement

        if (container && !entryCreated) {
            if (hasKeyboard) {
                if (isMobileSafari && htmlTag) htmlTag.setAttribute('class', 'unscrollable')
                container.scrollIntoView({ behavior: 'smooth' })
            }
            if (!hasKeyboard) {
                if (isMobileSafari && htmlTag) htmlTag.removeAttribute('class')
                window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            }
        }
    }, [hasKeyboard, entryCreated])

    return (
        <div
            className={css({
                width: '100%',
                overflow: 'auto',
                minHeight: 'var(--vh, 100vh)',
                margin: '0px auto',
                background: theme.colors.white
            })}
        >
            {loading && <MemberEntryLoading />}
            <SuccessEntryModal open={entryCreated} />
            <MemberEntryTopbar />
            <div
                className={css({
                    paddingTop: theme.sizing.scale800,
                    paddingBottom: theme.sizing.scale800,
                    height: '100vh',
                    ...(!entryCreated && {
                        [CUSTOM_MEDIA_QUERIES.smallHeightDevice]: {
                            height: 'calc(100vh + 500px)'
                        }
                    }),
                    [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                        paddingLeft: `max(${theme.sizing.scale600}, 3%)`,
                        paddingRight: `max(${theme.sizing.scale600}, 3%)`
                    },
                    [CUSTOM_MEDIA_QUERIES.largerThanSmallTablet]: {
                        paddingLeft: '75px',
                        paddingRight: '75px'
                    }
                })}
            >
                <>
                    <div
                        className={css({
                            display: 'flex',
                            paddingLeft: '16px',
                            paddingRight: '16px',
                            [CUSTOM_MEDIA_QUERIES.largerThanPhone]: { display: 'none' }
                        })}
                    >
                        {time}
                    </div>
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            ...GUEST_UI_STYLES.HEADER
                        })}
                    >
                        <div className={css({ display: 'flex', flexWrap: 'wrap', whiteSpace: 'pre-wrap' })}>
                            {`${t('member.memberEntry.title')}`}
                            {data.whitelist_duration && (
                                <>
                                    {' - '}
                                    <div
                                        className={css({
                                            color: '#039855',
                                            [CUSTOM_MEDIA_QUERIES.tabletMedium]: {
                                                marginLeft: '.25em'
                                            }
                                        })}
                                    >
                                        {getDurationStringFromIS0(data.whitelist_duration)}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    {data.purpose === 'customer_whitelist' && (
                        <div
                            className={css({
                                paddingLeft: '16px',
                                paddingRight: '16px'
                            })}
                        >
                            <LabelSmall
                                className={css({
                                    ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                                })}
                            >
                                {t('member.memberEntry.form.label.endDate')}
                            </LabelSmall>
                            <LabelLarge
                                className={css({
                                    marginTop: theme.sizing.scale300,
                                    marginBottom: theme.sizing.scale300,
                                    ...TABLET_TYPOGRAHPY_OVERRIDE.large
                                })}
                            >
                                {data.end_date
                                    ? DateTime.fromISO(data.end_date).toFormat('dd.MM.yyyy  HH:mm')
                                    : t('common.values.unlimited')}
                            </LabelLarge>
                        </div>
                    )}
                </>
                <div
                    id="member_entry_lp_container"
                    className={css({
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        paddingLeft: theme.sizing.scale600,
                        paddingRight: theme.sizing.scale600,
                        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                            paddingLeft: '0px',
                            paddingRight: '0px'
                        },
                        [CUSTOM_MEDIA_QUERIES.largerThanSmallTablet]: {
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '34px'
                        }
                    })}
                >
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            backgroundColor: '#fff',
                            borderRadius: '8px',
                            padding: '15px 0px',
                            width: '100%'
                        })}
                    >
                        <FormControl
                            overrides={{
                                Caption: { style: { fontSize: theme.typography.font450.fontSize } }
                            }}
                            error={t(validationErrors.plate)}
                        >
                            <LPInput
                                withLabel
                                withKeyboard
                                value={data.plate}
                                country={data.country}
                                countrySelection={[data.country]}
                                onChange={onChangeInput}
                                onCountryChange={onCountryChange}
                                error={validationErrors.plate}
                                width="100%"
                                overrides={{
                                    CircularBadge: {
                                        ...GUEST_UI_STYLES.LICENSE_PLATE.BADGES.CIRCULAR
                                    },
                                    PlateBadge: {
                                        ...GUEST_UI_STYLES.LICENSE_PLATE.BADGES.PLATE
                                    },
                                    LPContainer: {
                                        ...GUEST_UI_STYLES.LICENSE_PLATE.HEIGHT
                                    },
                                    LPInnerContainer: {
                                        ...GUEST_UI_STYLES.LICENSE_PLATE.HEIGHT
                                    },
                                    Input: {
                                        ...GUEST_UI_STYLES.LICENSE_PLATE.INPUT
                                    },
                                    DropdownButton: {
                                        ...GUEST_UI_STYLES.LICENSE_PLATE.BUTTON
                                    }
                                }}
                            />
                        </FormControl>
                        {!data.anonymous && (
                            <FormControl
                                label={t('member.memberEntry.form.label.name')}
                                error={t(validationErrors.name)}
                                overrides={{
                                    Label: {
                                        style: {
                                            ...theme.typography.LabelMedium,
                                            [theme.mediaQuery.medium]: { ...theme.typography.LabelLarge }
                                        }
                                    },
                                    Caption: {
                                        style: {
                                            fontSize: theme.typography.font450.fontSize,
                                            color: theme.colors.negative
                                        }
                                    }
                                }}
                            >
                                <Input
                                    placeholder={t('member.memberEntry.form.placeholder.name')}
                                    inputMode="none"
                                    value={data.name}
                                    overrides={{
                                        Root: {
                                            style: {
                                                borderWidth: '1px'
                                            }
                                        },
                                        Input: {
                                            style: {
                                                ...GUEST_UI_STYLES.NAME_INPUT
                                            }
                                        },
                                        InputContainer: {
                                            style: {
                                                backgroundColor: '#fff'
                                            }
                                        }
                                    }}
                                    onBlur={() => hideKeyboard()}
                                    onFocus={() => {
                                        showKeyboard('name_input', {
                                            handler: (input: string) => {
                                                edit((draft) => {
                                                    draft.name = input
                                                })
                                                keyboardRef.current?.setCaretPosition(input.length)
                                            },
                                            props: {
                                                ...DefaultTouchKeyboardProps,
                                                layoutName: 'name',
                                                layout: layouts[keyboardLanguage]
                                            },
                                            withTrim: false,
                                            defaultValue: data.name
                                        })
                                    }}
                                    id="entry_name"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                        const value = e.target.value
                                        resetErrors()
                                        edit((draft) => {
                                            draft.name = value
                                        })
                                    }}
                                    error={!!validationErrors.name}
                                />
                            </FormControl>
                        )}
                    </div>
                    <div
                        className={css({
                            [CUSTOM_MEDIA_QUERIES.phone]: {
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                marginTop: '16px'
                            },
                            [CUSTOM_MEDIA_QUERIES.tablet]: {
                                marginTop: '39px'
                            }
                        })}
                    >
                        <Button
                            onClick={create}
                            isLoading={loading}
                            $kind={BUTTON_KIND.primary}
                            disabled={data.plate === '' || data.plate === '_' || loading}
                            overrides={{
                                BaseButton: {
                                    style: {
                                        width: '100%',
                                        boxShadow: '0px 4px 0px 0px #00532E',
                                        backgroundColor: '#039855',
                                        border: '2px solid transparent',
                                        transition: `color ${theme.animation.easeInOutCurve} .2s, background ${theme.animation.easeInOutCurve} .2s, border ${theme.animation.easeInOutCurve} .2s, box-shadow ${theme.animation.easeInOutCurve} 0.1s`,
                                        ':disabled': {
                                            backgroundColor: '#E2E2E2',
                                            border: '2px solid transparent',
                                            boxShadow: '0px 4px 0px 0px #ACACAC'
                                        },
                                        ':disabled:hover': {
                                            backgroundColor: '#E2E2E2'
                                        },
                                        ':disabled:focus': {
                                            backgroundColor: '#E2E2E2',
                                            border: '2px solid transparent',
                                            boxShadow: 'none'
                                        },
                                        ':focus:not([disabled])': {
                                            backgroundColor: '#039855CC',
                                            border: '2px solid transparent',
                                            boxShadow: 'none'
                                        },
                                        ':hover:not([disabled])': {
                                            backgroundColor: '#039855CC',
                                            border: '2px solid transparent',
                                            boxShadow: '0px 2px 0px 0px #ACC6BB'
                                        },
                                        ...GUEST_UI_STYLES.CONFIRM_BUTTON
                                    }
                                }
                            }}
                        >
                            {t('member.memberEntry.confirmButton')}
                        </Button>
                    </div>
                </div>
                <div
                    className={css({
                        marginTop: theme.sizing.scale800,
                        [CUSTOM_MEDIA_QUERIES.phone]: {
                            fontSize: theme.typography.font350.fontSize,
                            paddingLeft: '16px',
                            paddingRight: '16px'
                        },
                        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                            fontSize: theme.typography.font450.fontSize
                        }
                    })}
                >
                    {t('member.memberEntry.form.warning')}
                </div>
            </div>
        </div>
    )
}

export default MemberEntryView
