import { useTranslation } from 'react-i18next'
import {
    AUSTRIAN_PLATE_MATCH,
    CONSULAT_GERMAN_PLATE_MATCH,
    COUNTRIES,
    DENMARK_ARMY_PLATE_MATCH,
    DENMARK_PLATE_MATCH,
    DIPLOMATIC_GERMAN_PLATE_MATCH,
    EXPORT_GERMAN_PLATE_MATCH,
    GERMAN_PLATE_MATCH,
    ITALY_PLATE_MATCH,
    LONG_GERMAN_PLATE_MATCH,
    POLAND_PLATE_EXTRACT,
    SWISS_CANTON,
    SWISS_PLATE_EXTRACT,
    SWISS_PLATE_MATCH
} from '../licensePlate'

export const useLicensePlateValidation = () => {
    const { t } = useTranslation()

    const validateLicensePlate = (plate: string, country: string, isWildCard?: boolean) => {
        if (isWildCard && (plate.endsWith('*') || plate.startsWith('*')) && (plate.match(/[*]/g) || []).length === 1) {
            return { valid: true, errors: {} }
        }

        if (plate && country.toUpperCase() === COUNTRIES.germany) {
            if (
                plate.match(GERMAN_PLATE_MATCH) === null &&
                plate.match(LONG_GERMAN_PLATE_MATCH) === null &&
                plate.match(DIPLOMATIC_GERMAN_PLATE_MATCH) === null &&
                plate.match(CONSULAT_GERMAN_PLATE_MATCH) === null &&
                plate.match(EXPORT_GERMAN_PLATE_MATCH) === null
            ) {
                return { valid: false, errors: { plate: t('errors.licensePlate.formatInvalid') } }
            }
        }
        if (plate && country.toUpperCase() === COUNTRIES.poland) {
            if (plate.match(POLAND_PLATE_EXTRACT) === null) {
                return { valid: false, errors: { plate: t('errors.licensePlate.formatInvalid') } }
            }
        }
        if (plate && country.toUpperCase() === COUNTRIES.austria) {
            if (plate.match(AUSTRIAN_PLATE_MATCH) === null) {
                return { valid: false, errors: { plate: t('errors.licensePlate.formatInvalid') } }
            }
        }
        if (plate && country.toUpperCase() === COUNTRIES.italy) {
            if (plate.match(ITALY_PLATE_MATCH) === null) {
                return { valid: false, errors: { plate: t('errors.licensePlate.formatInvalid') } }
            }
        }
        if (plate && country.toUpperCase() === COUNTRIES.switzerland) {
            if (plate.match(SWISS_PLATE_MATCH) === null) {
                return { valid: false, errors: { plate: t('errors.licensePlate.formatInvalid') } }
            }

            const { canton } = SWISS_PLATE_EXTRACT.exec(plate)?.groups as {
                canton: string
                plate: string
            }

            if (!SWISS_CANTON[canton]) {
                return { valid: false, errors: { plate: t('errors.licensePlate.ch.cantonInvalid') } }
            }
        }
        if (plate && country.toUpperCase() === COUNTRIES.denmark) {
            if (plate.match(DENMARK_PLATE_MATCH) === null && plate.match(DENMARK_ARMY_PLATE_MATCH) === null) {
                return { valid: false, errors: { plate: t('errors.licensePlate.formatInvalid') } }
            }
        }

        return { valid: true, errors: {} }
    }

    return validateLicensePlate
}

export default useLicensePlateValidation
