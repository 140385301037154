import { IErrorHandlerDictionary } from './types'
import i18n from '../../i18n'

export default {
    'any.allow': () => i18n.t('errors.any.allow'),
    'array.min': () => i18n.t('errors.array.min'),
    'string.empty': () => i18n.t('errors.string.empty'),
    'string.max': ({ context: { limit } }) => i18n.t('errors.string.max', { count: limit }),
    'string.min': ({ context: { limit } }) => i18n.t('errors.string.min', { count: limit }),
    'string.email': () => i18n.t('errors.string.email'),
    'number.min': ({ context: { limit } }) => i18n.t('errors.number.min', { count: limit }),
    'number.max': ({ context: { limit } }) => i18n.t('errors.number.max', { count: limit }),
    'number.base': () => i18n.t('errors.number.base')
} as IErrorHandlerDictionary
