import { DateTime } from 'luxon'
import React from 'react'

const useTimer = () => {
    const [time, setTime] = React.useState(DateTime.now().toFormat('dd.MM.yyyy - hh:mm'))

    React.useEffect(() => {
        const interval = setInterval(() => {
            const newTime = DateTime.now().toFormat('dd.MM.yyyy - HH:mm')
            if (time !== newTime) setTime(newTime)
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return time
}

export default useTimer
