import { AxiosRequestConfig } from 'axios'
import { DateTime } from 'luxon'
import { ISalesforceData } from 'parkdepot-shared/types'
import axios from '../axiosWithSentryInterceptor'

const salesforceSubmissionEndpoint = process.env.REACT_APP_OBJECT_SUBMISSION || ''
const uploadURLsEndpoint = process.env.REACT_APP_GET_UPLOAD_URLS || ''

export const extensionToMimeType = {
    png: 'image/png',
    jpeg: 'image/jpeg',
    pdf: 'application/pdf'
}

type ITicketRequestParameters = {
    params: any
    post?: boolean
    endpointURL?: string
    config?: AxiosRequestConfig
}

const postToURL = async ({
    params = null,
    post = true,
    endpointURL = salesforceSubmissionEndpoint,
    config
}: ITicketRequestParameters) => {
    const result = post ? await axios.post(endpointURL, params, config) : await axios.get(endpointURL, params)
    return result.data
}

export interface ISalesforceTicketRequestInput {
    case_id?: string
    accessToken?: string
}

const useSalesforceAPI = ({ case_id, accessToken }: ISalesforceTicketRequestInput) => {
    const requestInfo = { case_id, timestamp: DateTime.local().toISO() }

    // set access token if provided. This is necessary for the Guest UI where
    // we always validate this access token adding a Salesforce Ticket
    const authHeader = accessToken
        ? {
              headers: {
                  Authorization: accessToken
              }
          }
        : undefined

    const createNewCase = async (salesforceCaseData: ISalesforceData) => {
        const createCaseResult = await postToURL({
            params: JSON.stringify({
                ...requestInfo,
                plate: salesforceCaseData.plate,
                case: salesforceCaseData.caseData,
                plate_country: salesforceCaseData.plate_country
            }),
            ...(authHeader ? { config: authHeader } : undefined)
        })
        if (!createCaseResult.success) {
            throw new Error(`Case could not be create: ${createCaseResult?.error}`)
        }

        return createCaseResult.id as string
    }

    const linkAttachment = async (infos: ISalesforceData, timestamp: number, parentId: string) => {
        if (infos.attachmentData.length !== 0) {
            try {
                await postToURL({
                    params: JSON.stringify({
                        ...requestInfo,
                        plate: infos.plate,
                        attachment: {
                            reason: infos.caseData.Case_Reason__c,
                            time_stamp: `${timestamp}`,
                            parentId
                        }
                    }),
                    ...(authHeader ? { config: authHeader } : undefined)
                })
            } catch (error) {
                console.error(error)
            }
        }
    }

    const getUploadUrls = async ({
        plate,
        reason,
        timestamp,
        files
    }: {
        plate: string
        reason: string
        timestamp: number
        files: Record<number, string>
    }) => {
        const result = await postToURL({
            params: JSON.stringify({
                case_id,
                plate,
                reason,
                time_stamp: timestamp,
                files
            }),
            endpointURL: uploadURLsEndpoint
        })
        return result.presigned_urls as Record<number, string>
    }

    const createUploadAttachmentsRequest = (urls: Record<number, string>, salesforceCaseData: ISalesforceData) => {
        if (salesforceCaseData.attachmentData.length !== 0) {
            return salesforceCaseData.attachmentData.map(
                async (attachment, index) =>
                    await new Promise<number>(async (resolve, reject) => {
                        try {
                            const { status } = await axios.put(urls[index], attachment.Body, {
                                headers: {
                                    'Content-Type': attachment.Body.type
                                }
                            })
                            if (status !== 200) {
                                reject(status)
                            }
                            resolve(status)
                        } catch (e) {
                            reject(e as Error)
                        }
                    })
            )
        }
    }

    const submitWebToCase = async (infos: ISalesforceData, _toDoOnClose?: () => void) => {
        const needAttachmentUpload = infos.attachmentData.length !== 0
        try {
            const timestamp = DateTime.utc().toUnixInteger()
            if (needAttachmentUpload) {
                const urls = await getUploadUrls({
                    plate: infos.plate || '',
                    timestamp,
                    reason: infos.caseData.Case_Reason__c || '',
                    files: infos.attachmentData.reduce(
                        (acc, { Name }, index) => {
                            acc[index] = Name
                            return acc
                        },
                        {} as Record<number, string>
                    )
                })
                if (!urls) return
                const uploadPromises = createUploadAttachmentsRequest(urls, infos)
                if (uploadPromises) await Promise.all(uploadPromises)
            }
            const salesforceCaseID = await createNewCase(infos)
            if (needAttachmentUpload) await linkAttachment(infos, timestamp, salesforceCaseID)
            return
        } catch (error) {
            return { error: error as Error }
        }
    }

    return { submitWebToCase }
}

export default useSalesforceAPI
