import { styled } from 'baseui'
import { ConfigurationOverrideObject } from 'baseui/helpers/overrides'

export const StyleLPWrapper = styled<
    'div',
    { $error?: boolean; $disabled?: boolean; $overrides?: ConfigurationOverrideObject }
>('div', ({ $theme, $error, $overrides }) => ({
    display: 'flex',
    flexFlow: '1',
    width: '100%',
    backgroundColor: $theme.colors.white,
    borderRadius: $theme.borders.radius100,
    borderWidth: '1px',
    borderStyle: 'solid',
    gap: $theme.sizing.scale100,
    borderColor: $error ? $theme.colors.negative300 : $theme.colors.mono1000,
    ...$overrides
}))

export const StyledLPInnerWraper = styled<'div', { $overrides?: ConfigurationOverrideObject }>(
    'div',
    ({ $theme, $overrides }) => ({
        display: 'flex',
        flexGrow: '1',
        gap: $theme.sizing.scale100,
        paddingRight: $theme.sizing.scale100,
        margin: '4px auto',
        color: `${$theme.colors.contentPrimary} !important`,
        $overrides
    })
)

export interface IStyledCountrySection {
    $color?: string
    $overrides?: ConfigurationOverrideObject
    $backgroundColor?: string
}

export const StyledCountrySection = styled<'div', IStyledCountrySection>(
    'div',
    ({ $theme, $color, $backgroundColor, $overrides }) => ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '30px',
        backgroundColor: $backgroundColor ? $backgroundColor : $theme.colors.primary400,
        flexShrink: 0,
        color: $color ? $color : $theme.colors.white,
        fontSize: $theme.typography.font200.fontSize,
        fontWeight: 700,
        ...$overrides
    })
)

export const StyledCircularBadge = styled<'div', { $overrides?: ConfigurationOverrideObject }>(
    'div',
    ({ $theme, $overrides }) => ({
        width: '14px',
        height: '14px',
        backgroundColor: '#E2E2E2',
        borderRadius: '50%',
        marginTop: $theme.sizing.scale100,
        marginBottom: $theme.sizing.scale100,
        ...$overrides
    })
)

export const StyledShieldBadge = styled<'div', { $overrides?: ConfigurationOverrideObject }>(
    'div',
    ({ $theme, $overrides }) => ({
        width: '14px',
        height: '20px',
        backgroundColor: '#E2E2E2',
        borderBottomLeftRadius: '50%',
        borderBottomRightRadius: '50%',
        marginTop: $theme.sizing.scale100,
        marginBottom: '4px',
        ...$overrides
    })
)

export const StyledPlateBadgeText = styled<'div', { $overrides?: ConfigurationOverrideObject }>(
    'div',
    ({ $theme, $overrides }) => ({
        width: '16px',
        height: '4px',
        backgroundColor: '#E2E2E2',
        marginBottom: $theme.sizing.scale100,
        ...$overrides
    })
)

export default { StyleLPWrapper, StyledLPInnerWraper, StyledCountrySection, StyledCircularBadge, StyledShieldBadge }
