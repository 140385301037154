import React from 'react'

export interface IAuthData {
    id?: number
    company_id: string
    country?: string
    language?: string
    exp: number
    lot_id: string
    user_id: string
    whitelist_duration: string
    anonymous: boolean
    purpose?: string
}

export interface IPreflightData {
    jwt_id?: number | string
    accessToken?: string
    company_id: string
    country?: string
    language?: string
    lot_ids: string[]
    whitelist_duration: string
    anonymous: boolean
    expires_at: number
    token_expiration_in_days: number
    purpose?: string
}

export interface IPreflightContextData {
    preflightData: IPreflightData
    preflightStatusCode?: number
    preflightLoading: boolean
}

export const PreflightContext = React.createContext<IPreflightContextData>({
    loading: false
} as unknown as IPreflightContextData)

interface IPreflightProvider {
    context: IPreflightContextData
}

const PreflightProvider: React.FunctionComponent<IPreflightProvider> = ({ context, children }) => (
    <PreflightContext.Provider value={context}>{children}</PreflightContext.Provider>
)

export default PreflightProvider
