import { createLightTheme } from 'baseui'
import baseTheme from 'parkdepot-shared/theme'

const primitives = {
    primaryFontFamily: "'Albert Sans', sans-serif",
    primary: '#3867d6',
    primary50: '#d7e1f7',
    primary100: '#afc2ef',
    primary200: '#88a4e6',
    primary300: '#6085de',
    primary400: '#3867d6',
    primary500: '#2d52ab',
    primary600: '#223e80',
    primary700: '#162956',
    accent: '#000000',
    accent50: '#cacaca',
    accent100: '#a6a6a6',
    accent200: '#949494',
    accent300: '#717171',
    accent400: '#454545',
    accent500: '#363636',
    accent600: '#272727',
    accent700: '#171717',
    contentAccent: '#222323'
}

// Exporting custom media queries for use in styled components
export const CUSTOM_MEDIA_QUERIES = {
    // Custom media query for tablets only
    smallHeightDevice: '@media only screen and (max-height: 600px)',
    phone: '@media only screen and (max-width: 480px)',
    largerThanPhone: '@media only screen and (min-width: 481px)',
    tablet: '@media only screen and (min-width: 481px) and (max-width: 1149px)',
    largerThanSmallTablet: '@media only screen and (min-width: 1150px)',
    tabletMedium: '@media only screen and (min-width: 1150px) and (max-width: 1919px)',
    tabletLarge: '@media only screen and (min-width: 1920px) and (max-width: 2731px)',
    tabletXLarge: '@media only screen and (min-width: 2731px)'
}

const overrides = () => ({
    ...baseTheme,
    colors: {
        ...baseTheme.colors,
        inputFill: 'transparent',
        inputFillActive: 'transparent'
    },
    mediaQuery: {
        small: '@media screen and (min-width: 320px)',
        medium: '@media screen and (min-width: 600px)',
        tablet: CUSTOM_MEDIA_QUERIES.tablet,
        large: '@media screen and (min-width: 1136px)'
    }
})

// Increase app scale for the member entry modal
const theme = createLightTheme(primitives, overrides())

export default theme
