import { LanguageSelectOptions, Select } from 'parkdepot-shared/components/Select'
import { COUNTRIES } from 'parkdepot-shared/utils/licensePlate'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LANGUAGE_FALLBACKS, REVERSE_FALLBACK_MAP } from '../../../i18n'

const LanguageMenuDropdown = () => {
    const { i18n } = useTranslation()

    const language = REVERSE_FALLBACK_MAP.get(i18n.language) || COUNTRIES.germany.toLowerCase()

    const selectedOptionIndex = language ? LanguageSelectOptions.findIndex((e) => e.id === language) : 0

    return (
        <Select
            $dark={true}
            options={LanguageSelectOptions}
            value={{
                value: LanguageSelectOptions[selectedOptionIndex].label,
                id: LanguageSelectOptions[selectedOptionIndex].id
            }}
            onChange={(newValue) => i18n.changeLanguage(LANGUAGE_FALLBACKS[newValue[0].id as string])}
        />
    )
}

export default LanguageMenuDropdown
