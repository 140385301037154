import { useStyletron } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { DateTime } from 'luxon'
import Button from 'parkdepot-shared/components/Buttons/Button'
import LPInput from 'parkdepot-shared/components/LPInput'
import { DefaultTouchKeyboardProps, TouchKeyboardContext } from 'parkdepot-shared/components/TouchKeyboard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CUSTOM_MEDIA_QUERIES } from '../AppFrame/theme'
import { MemberEntryContext } from './MemberEntryContext'
import MemberEntryLoading from './MemberEntryLoading'
import { GUEST_UI_STYLES, GetSuccessModalKeyboardTransition, TABLET_TYPOGRAHPY_OVERRIDE } from './constants'

const SuccessEntryModal = ({ open }: { open: boolean }) => {
    const { t } = useTranslation()
    const emailRef = React.useRef<HTMLInputElement>(null)
    const [sendingEmail, setSendingEmail] = React.useState(false)
    const [css, theme] = useStyletron()

    const { showKeyboard, hideKeyboard, hasKeyboard, keyboardRef, forceSelectionOfKeyboard } =
        React.useContext(TouchKeyboardContext)
    const { data, jwt_id, edit, validationErrors, sendEmail, resetErrors, cancel } =
        React.useContext(MemberEntryContext)

    // only show notification email field if the jwt id could be successfully parsed from the JWT
    const showEmailField = !!jwt_id
    const timeOfCreation = React.useMemo(
        () => DateTime.now().toFormat('dd.MM.yyyy - HH:mm'),
        //eslint-disable-next-line
        [open]
    )

    const emailInput = document.getElementById('success_email_container')

    const screenHeight = window.screen.height
    const keyboardSize = window.screen.width > 425 ? 5 * 75 : 5 * 50

    const emailInputOffset = React.useMemo(() => emailInput?.offsetTop, [emailInput])

    const shouldGoUp = React.useMemo(
        () => (emailInputOffset ? screenHeight - emailInputOffset - 125 < keyboardSize : false),
        //eslint-disable-next-line
        [emailInputOffset]
    )

    React.useEffect(() => {
        const container = document.getElementById('success-modal-container')
        if (hasKeyboard && container && container.scrollTop !== 0) {
            container.scrollTo(0, 0)
        }
    }, [hasKeyboard])

    if (!open) return null

    if (sendingEmail) {
        return (
            <div
                className={css({
                    left: 0,
                    bottom: 0,
                    top: 0,
                    right: 0,
                    position: 'fixed',
                    zIndex: 1
                })}
            >
                <div
                    className={css({
                        display: 'flex',
                        width: '100%',
                        minHeight: '100vh',
                        background: '#FFFFFF'
                    })}
                >
                    <MemberEntryLoading />
                </div>
            </div>
        )
    }

    return (
        <div
            id="success-modal-container"
            className={css({
                left: 0,
                bottom: 0,
                top: 0,
                right: 0,
                position: 'fixed',
                overflow: 'auto',
                zIndex: 2
            })}
        >
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    minHeight: '100vh',
                    background: '#FFFFFF',
                    ...(shouldGoUp &&
                        emailInputOffset && {
                            minHeight: 'calc(100vh + 500px)',
                            overflow: 'auto'
                        })
                })}
            >
                <div
                    className={css({
                        backgroundColor: '#03582F',
                        borderRadius: '25px',
                        ...GetSuccessModalKeyboardTransition({
                            screenHeight,
                            offset: emailInputOffset,
                            keyboardSize,
                            shouldGoUp: hasKeyboard && shouldGoUp
                        }),
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: 'auto',
                        paddingLeft: theme.sizing.scale600,
                        paddingRight: theme.sizing.scale600,
                        transition: 'margin 200ms',
                        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                            paddingLeft: theme.sizing.scale1200,
                            paddingRight: theme.sizing.scale1200
                        },
                        width: '90%',
                        zIndex: 1
                    })}
                >
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: theme.sizing.scale1400,
                            color: '#fff',
                            marginTop: '0px',
                            marginBottom: theme.sizing.scale600,
                            ...TABLET_TYPOGRAHPY_OVERRIDE.large
                        })}
                    >
                        <i className={`${css({ fill: '#fff', fontSize: '40px' })} wemolo-confetti`} />
                        <div
                            className={css({
                                ...GUEST_UI_STYLES.SUCCESS_HEADER,
                                textAlign: 'center'
                            })}
                        >
                            {t('member.memberEntry.success.title')}
                        </div>
                    </div>
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            [theme.mediaQuery.medium]: {
                                paddingLeft: '10px',
                                paddingRight: '10px'
                            }
                        })}
                    >
                        <div>
                            <div
                                className={css({
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    marginBottom: theme.sizing.scale600
                                })}
                            >
                                <div
                                    className={css({
                                        width: '100%',
                                        marginBottom: theme.sizing.scale500,
                                        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: { width: '460px' }
                                    })}
                                >
                                    <LPInput
                                        disabled
                                        value={data.plate}
                                        country={data.country}
                                        width="100%"
                                        overrides={{
                                            CircularBadge: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.BADGES.CIRCULAR
                                            },
                                            PlateBadge: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.BADGES.PLATE
                                            },
                                            LPContainer: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.HEIGHT
                                            },
                                            LPInnerContainer: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.HEIGHT
                                            },
                                            Input: {
                                                ...GUEST_UI_STYLES.SUCCESS_LP_INPUT
                                            },
                                            DropdownButton: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.BUTTON
                                            }
                                        }}
                                    />
                                </div>
                                <span className={css({ color: '#fff' })}>{timeOfCreation}</span>
                            </div>
                        </div>
                        {showEmailField ? (
                            <div>
                                <div>
                                    <FormControl
                                        label={
                                            <div
                                                className={css({
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: theme.colors.white,
                                                    [CUSTOM_MEDIA_QUERIES.phone]: {
                                                        fontSize: '14px'
                                                    },
                                                    [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                                                        fontSize: '18px'
                                                    }
                                                })}
                                            >
                                                <span>{t('member.memberEntry.form.label.email')}</span>
                                            </div>
                                        }
                                        error={t(validationErrors.email)}
                                        overrides={{
                                            Caption: {
                                                style: {
                                                    ...theme.typography.font250,
                                                    [CUSTOM_MEDIA_QUERIES.tablet]: {
                                                        fontSize: theme.sizing.scale600,
                                                        lineHeight: theme.sizing.scale700
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <Input
                                            placeholder={t('member.memberEntry.form.placeholder.email')}
                                            value={data.email}
                                            inputMode="none"
                                            inputRef={emailRef}
                                            onBlur={() => hideKeyboard()}
                                            onFocus={() => {
                                                showKeyboard('success_email_input', {
                                                    handler: (input: string) => {
                                                        resetErrors()
                                                        edit((draft) => {
                                                            draft.email = input.toLowerCase()
                                                        })
                                                        keyboardRef.current.setCaretPosition(input.length)
                                                    },
                                                    props: DefaultTouchKeyboardProps,
                                                    withTrim: false,
                                                    defaultValue: data.email
                                                })
                                            }}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                            ) => {
                                                const value = e.target.value
                                                resetErrors()
                                                edit((draft) => {
                                                    draft.email = value
                                                })
                                                forceSelectionOfKeyboard('success_email_input', value)
                                            }}
                                            error={!!validationErrors.email}
                                            overrides={{
                                                Root: {
                                                    style: {
                                                        ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                                                    }
                                                },
                                                Input: {
                                                    style: {
                                                        ...GUEST_UI_STYLES.INPUT_EMAIL_SUCCESS,
                                                        paddingLeft: theme.sizing.scale600,
                                                        color: theme.colors.white,
                                                        '::placeholder': {
                                                            color: theme.colors.white
                                                        }
                                                    }
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        ) : undefined}
                        <div
                            id="success_email_container"
                            className={css({
                                display: 'flex',
                                paddingBottom: theme.sizing.scale1400,
                                [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                                    paddingBottom: theme.sizing.scale1600
                                }
                            })}
                        >
                            <Button
                                $dark
                                overrides={{
                                    BaseButton: {
                                        style: {
                                            width: '100%',
                                            ...GUEST_UI_STYLES.CONFIRM_SUCCESS_BUTTON,
                                            color: theme.colors.black
                                        }
                                    }
                                }}
                                onClick={async () => {
                                    setSendingEmail(true)
                                    await sendEmail().catch((err) => {
                                        console.error(err)
                                    })
                                    setSendingEmail(false)
                                    cancel()
                                    // .then((res) => {
                                    //     console.log(res)
                                    //     setSendingEmail(false)
                                    // })
                                    // .catch((error) => {
                                    //     console.error(error)
                                    //     setSendingEmail(false)
                                    //     cancel()
                                    // })
                                }}
                            >
                                {t('common.button.done')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessEntryModal
