import { styled, useStyletron } from 'baseui'
import { Spinner } from 'baseui/spinner'
import { LabelLarge } from 'baseui/typography'
import { DateTime } from 'luxon'
import React from 'react'

const StyledAppLoadingWrapper = styled('div', {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: 'var(--vh, 100vh)'
})

const StyleCopyright = styled('div', {
    position: 'absolute',
    bottom: 0,
    height: '10vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const AppLoading = () => {
    const [css, theme] = useStyletron()

    return (
        <StyledAppLoadingWrapper>
            <div className={css({ marginTop: theme.sizing.scale800 })}>
                <Spinner />
            </div>
            <StyleCopyright>
                <LabelLarge color="contentInverseSecondary">{`Wemolo © ${DateTime.local().year}`}</LabelLarge>
            </StyleCopyright>
        </StyledAppLoadingWrapper>
    )
}

export default AppLoading
