export interface IUser {
    id: string
    name: string
    nickname: string
    email: string
    own_company: string
}

export interface IUserWithPermissions extends IUser {
    password?: string
    confirmPassword?: string
    user_preference?: IUserMetadata
}

export interface IUserMetadata {
    [FEATURES.ANALYTICS]?: {
        [key in ANALYTICS_TYPES]?: boolean
    }
    [FEATURES.CANCELLATIONS]?: {
        reason?: CANCELLATIONS_REASONS[]
        free_cancellations_per_month?: number | null
    }
    [FEATURES.CUSTOMER_WHITELIST]?: {
        reason?: WHITELIST_REASONS[]
        anonymous?: boolean
        duration_limit?: string | null
        whitelist_limit?: number | null
        employee_description?: EMPLOYEE_DESCRIPTION
    }
    [FEATURES.RESIDENT]?: {
        duration_limit?: string | null
        whitelist_limit?: number | null
    }
    [FEATURES.USER_MANAGEMENT]?: {}
    [FEATURES.BLACKLIST]?: {}
}

export enum FEATURES {
    ANALYTICS = 'analytics',
    CUSTOMER_WHITELIST = 'customer_whitelist',
    RESIDENT = 'resident',
    CANCELLATIONS = 'cancellations',
    USER_MANAGEMENT = 'user_management',
    BLACKLIST = 'blacklist'
}

export enum EMPLOYEE_DESCRIPTION {
    NAME = 'name',
    '7_DIGIT_NUMBER' = '7_digit_number',
    '8_DIGIT_NUMBER' = '8_digit_number'
}

export enum ANALYTICS_TYPES {
    GENERAL = 'general',
    PARKING_BEHAVIOR = 'parking_behavior',
    CARPAY_FINANCE = 'carpay_finance',
    OCCUPANCY = 'occupancy',
    OFFENCES = 'offences'
}

export enum CANCELLATIONS_REASONS {
    EMPLOYEE = 'employee',
    GOODWILL_CUSTOMER = 'goodwill_customer',
    NEW_EMPLOYEE = 'newEmployee',
    CO_TENANT = 'co_tenant',
    SERVICE_SUPPLIER = 'service_supplier',
    OTHER = 'other'
}

export enum WHITELIST_REASONS {
    EMPLOYEE = 'employee',
    NEW_EMPLOYEE = 'newEmployee',
    SERVICE_SUPPLIER = 'service_supplier',
    CO_TENANT = 'co_tenant',
    OTHER = 'other'
}

export enum PREFERENCE_TEMPLATES {
    CUSTOM = 'custom',
    DEFAULT = 'default',
    LIDL = 'lidl',
    LIDL_CH = 'lidl_ch',
    NETTO = 'netto',
    RESIDENT = 'resident'
}

export const USER_PERMISSION_TEMPLATES: { [key in PREFERENCE_TEMPLATES]?: IUserMetadata } = {
    default: {
        customer_whitelist: {
            anonymous: false,
            whitelist_limit: null,
            duration_limit: null,
            employee_description: EMPLOYEE_DESCRIPTION.NAME,
            reason: [
                WHITELIST_REASONS.CO_TENANT,
                WHITELIST_REASONS.EMPLOYEE,
                WHITELIST_REASONS.OTHER,
                WHITELIST_REASONS.SERVICE_SUPPLIER
            ]
        },
        cancellations: {
            free_cancellations_per_month: null,
            reason: [
                CANCELLATIONS_REASONS.SERVICE_SUPPLIER,
                CANCELLATIONS_REASONS.CO_TENANT,
                CANCELLATIONS_REASONS.GOODWILL_CUSTOMER,
                CANCELLATIONS_REASONS.EMPLOYEE,
                CANCELLATIONS_REASONS.OTHER
            ]
        }
    },
    netto: {
        customer_whitelist: {
            anonymous: false,
            whitelist_limit: null,
            duration_limit: null,
            employee_description: EMPLOYEE_DESCRIPTION['7_DIGIT_NUMBER'],
            reason: [
                WHITELIST_REASONS.CO_TENANT,
                WHITELIST_REASONS.EMPLOYEE,
                WHITELIST_REASONS.NEW_EMPLOYEE,
                WHITELIST_REASONS.SERVICE_SUPPLIER
            ]
        },
        cancellations: {
            free_cancellations_per_month: null,
            reason: [
                CANCELLATIONS_REASONS.SERVICE_SUPPLIER,
                CANCELLATIONS_REASONS.CO_TENANT,
                CANCELLATIONS_REASONS.EMPLOYEE,
                CANCELLATIONS_REASONS.NEW_EMPLOYEE,
                CANCELLATIONS_REASONS.OTHER
            ]
        }
    },
    lidl: {
        analytics: {
            general: true,
            occupancy: true,
            offences: true,
            carpay_finance: false,
            parking_behavior: false
        },
        customer_whitelist: {
            anonymous: false,
            whitelist_limit: null,
            duration_limit: null,
            employee_description: EMPLOYEE_DESCRIPTION['8_DIGIT_NUMBER'],
            reason: [
                WHITELIST_REASONS.CO_TENANT,
                WHITELIST_REASONS.EMPLOYEE,
                WHITELIST_REASONS.NEW_EMPLOYEE,
                WHITELIST_REASONS.SERVICE_SUPPLIER
            ]
        },
        cancellations: {
            free_cancellations_per_month: 10,
            reason: [
                CANCELLATIONS_REASONS.SERVICE_SUPPLIER,
                CANCELLATIONS_REASONS.CO_TENANT,
                CANCELLATIONS_REASONS.EMPLOYEE
            ]
        }
    },
    lidl_ch: {
        analytics: {
            general: true,
            occupancy: true,
            offences: true,
            carpay_finance: false,
            parking_behavior: false
        },
        customer_whitelist: {
            anonymous: false,
            whitelist_limit: null,
            duration_limit: null,
            employee_description: EMPLOYEE_DESCRIPTION['8_DIGIT_NUMBER'],
            reason: [
                WHITELIST_REASONS.CO_TENANT,
                WHITELIST_REASONS.EMPLOYEE,
                WHITELIST_REASONS.NEW_EMPLOYEE,
                WHITELIST_REASONS.SERVICE_SUPPLIER
            ]
        },
        cancellations: {
            free_cancellations_per_month: 15,
            reason: [
                CANCELLATIONS_REASONS.SERVICE_SUPPLIER,
                CANCELLATIONS_REASONS.CO_TENANT,
                CANCELLATIONS_REASONS.EMPLOYEE
            ]
        }
    },
    resident: {
        resident: {
            whitelist_limit: 10,
            duration_limit: null
        }
    }
}
