import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import da from './da.json'
import de from './de.json'
import en from './en.json'
import fr from './fr.json'
import it from './it.json'
import pl from './pl.json'

import { Settings } from 'luxon'
import capitalize from './utils/capitalize'

export const LANGUAGE_FALLBACKS: Record<string, string> = {
    de: 'de-DE',
    en: 'en-GB',
    fr: 'fr-FR',
    da: 'da-DK',
    it: 'it-IT',
    pl: 'pl-PL'
}

const REVERSE_FALLBACKS = [
    ...Object.keys(LANGUAGE_FALLBACKS).map((key) => [key, key]),
    ...Object.entries(LANGUAGE_FALLBACKS).map(([key, value]) => [value, key])
] as [string, string][]

export const REVERSE_FALLBACK_MAP = new Map(REVERSE_FALLBACKS)

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: { 'de-DE': de, 'en-GB': en, 'da-DK': da, 'it-IT': it, 'pl-PL': pl, 'fr-FR': fr },
        ns: [
            'common',
            'errors',
            'member',
            'membership',
            'ev',
            'lpInput',
            'modals',
            'base',
            'authErrorPage',
            'unsupportedBrowserPage'
        ],
        lng: 'de',
        defaultNS: 'common',
        nsSeparator: '.',
        fallbackLng: {
            default: ['de-DE'],
            de: ['de-DE'],
            da: ['da-DK'],
            'de-AT': ['de-DE'],
            'de-CH': ['de-DE'],
            en: ['en-GB'],
            'en-US': ['en-GB'],
            'en-CA': ['en-GB']
        },
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
            format: function (value, format) {
                if (format === 'uppercase') return value.toUpperCase()
                if (format === 'capitalize') return capitalize(value)
                return value
            }
        },
        returnObjects: true
    })

Settings.defaultLocale = 'de'

export default i18n
