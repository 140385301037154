import { styled, useStyletron } from 'baseui'
import { Spinner } from 'baseui/spinner'
import { Tag } from 'baseui/tag'
import { LabelXSmall } from 'baseui/typography'
import i18n from 'i18next'
import React from 'react'
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized'
import { StyleObject } from 'styletron-standard'
import darkScrollbarStyles from '../styles/darkScrollbarStyles'

const StyledListCap = styled('div', {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
})

export interface IInfiniteList<T> {
    /** Classes to attach to the wrapper component */
    className?: string
    /** Are there more items to load? (This information comes from the most recent API request.) */
    hasNextPage: boolean
    /** List of items loaded so far */
    list: T[]
    /** Callback function (eg. Redux action-creator) responsible for loading the next page of items */
    loadNextPage: (props: { startIndex: number; stopIndex: number }) => Promise<any>
    /** Render function for a single element of the list */
    renderItem: (prop: { index: number; item: T; style: any; key: string; parent: any }) => JSX.Element
    /** Render function for the end of list indicator. */
    renderCapRow?: (prop: { index: number; style: any; key: string; parent: any }) => JSX.Element
    /** Row height */
    rowHeight: number | ((props: any) => number)
    /** Apply dark scrollbar */
    applyDarkScrollbar?: boolean
}

const renderCapRowWithCSS = ({
    key,
    style,
    css
}: {
    key: React.Key
    style: React.CSSProperties
    css: (a: StyleObject) => string
}) => (
    <div key={key} style={style}>
        <StyledListCap>
            <LabelXSmall
                className={css({
                    color: '#AFAFAF',
                    fontSize: '16px',
                    lineHeight: '24px'
                })}
            >
                {i18n.t('common.status.endOfList')}
            </LabelXSmall>
        </StyledListCap>
    </div>
)

/**
 * An infinite loading list. Fetches new list items as the user scrolls down the list.
 * The list is virtualized to ensure good performance (only the items visible to the user are included in the DOM).
 * Automatically resizes to the dimensions of the parent component.
 */
const InfiniteList = <T extends unknown>({
    className,
    hasNextPage,
    list,
    loadNextPage,
    renderItem,
    renderCapRow,
    rowHeight,
    applyDarkScrollbar
}: IInfiniteList<T>) => {
    const [css] = useStyletron()
    const [loading, setLoading] = React.useState(false)
    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const rowLength = list?.length + 1

    // Every row is loaded except for our loading indicator row.
    const isRowLoaded = (props: { index: number }) => !!list[props.index]

    // Render a list item or a loading indicator.

    const rowRenderer = ({ index, key, style, parent }: { index: number; key: string; style: any; parent: any }) => {
        if (index === list?.length && !hasNextPage) {
            return renderCapRow ? renderCapRow({ index, style, key, parent }) : renderCapRowWithCSS({ style, key, css })
        } else if (list[index]) {
            return renderItem({ index, item: list[index], style, key, parent })
        } else {
            return null
        }
    }

    return (
        <AutoSizer>
            {({ width, height }: { width: number; height: number }) => (
                <div className={css({ position: 'relative', width: `${width}px`, height: `${height}px` })}>
                    <InfiniteLoader
                        isRowLoaded={isRowLoaded}
                        loadMoreRows={
                            hasNextPage
                                ? async (props: { startIndex: number; stopIndex: number }) => {
                                      setLoading(true)
                                      await loadNextPage(props)
                                      setLoading(false)
                                  }
                                : async () => {}
                        }
                        rowCount={rowLength}
                        minimumBatchSize={20}
                        threshold={10}
                    >
                        {({ onRowsRendered, registerChild }: { onRowsRendered: unknown; registerChild: unknown }) => (
                            <List
                                className={`${className} ${css({
                                    ...(applyDarkScrollbar ? darkScrollbarStyles : {})
                                })}`}
                                ref={registerChild}
                                onRowsRendered={onRowsRendered}
                                rowRenderer={rowRenderer}
                                width={width}
                                height={height}
                                rowCount={rowLength}
                                rowHeight={rowHeight}
                                tabIndex={null}
                            />
                        )}
                    </InfiniteLoader>
                    {loading && (
                        <div
                            className={css({
                                position: 'absolute',
                                bottom: 0,
                                zIndex: 1000,
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                                marginBottom: '1rem'
                            })}
                        >
                            <Tag
                                kind="accent"
                                variant="solid"
                                closeable={false}
                                size="medium"
                                overrides={{
                                    Root: {
                                        style: () => ({
                                            padding: '1rem 1.2rem'
                                        })
                                    }
                                }}
                            >
                                <Spinner $size="12px" color="#ffffff" />
                            </Tag>
                        </div>
                    )}
                </div>
            )}
        </AutoSizer>
    )
}

export default InfiniteList
