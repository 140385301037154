import React from 'react'

import EvEntryContainer from './EvContainer'
import EvEntryView from './EvEntryView'

const EVCharging = () => {
    return (
        <EvEntryContainer>
            <EvEntryView />
        </EvEntryContainer>
    )
}

export default EVCharging
