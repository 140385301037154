import { Accordion, Panel } from 'baseui/accordion'
import { useStyletron } from 'parkdepot-shared/theme'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_SIZE, BUTTON_USE } from 'parkdepot-shared/components/Buttons/types'

const PrivacyPolicy = () => {
    const [css, theme] = useStyletron()

    const [expanded, setExpanded] = React.useState(false)

    const { t } = useTranslation()

    const paragraphsKeys = ['parkingTime', 'upload', 'dataProtection']

    const typograhpyStyles = {
        ...theme.typography.LabelSmall,
        color: theme.colors.contentAccent
    }

    return (
        <div
            className={css({
                marginTop: theme.sizing.scale1000,
                [theme.mediaQuery.medium]: {
                    marginTop: theme.sizing.scale1600
                }
            })}
        >
            <Accordion>
                <Panel
                    onChange={({ expanded: $expanded }) => setExpanded($expanded)}
                    overrides={{
                        Content: {
                            style: {
                                backgroundColor: 'transparent',
                                border: 'none',
                                paddingBottom: theme.sizing.scale600,
                                paddingLeft: theme.sizing.scale900,
                                paddingRight: theme.sizing.scale900,
                                paddingTop: 0
                            }
                        },
                        PanelContainer: {
                            style: {
                                ...theme.borders.border200,
                                backgroundColor: theme.colors.white,
                                borderBottomColor: theme.borders.border200.borderColor,
                                borderRadius: '25px'
                            }
                        },
                        Header: {
                            style: {
                                border: 'none',
                                display: 'flex',
                                alignItems: 'baseline',
                                position: 'relative',
                                gap: '1px',
                                paddingTop: theme.sizing.scale600,
                                paddingBottom: theme.sizing.scale600,
                                paddingLeft: theme.sizing.scale900,
                                paddingRight: theme.sizing.scale600,
                                backgroundColor: 'transparent',
                                [theme.mediaQuery.medium]: {
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }
                            }
                        },
                        ToggleIcon: {
                            component: () => {
                                return (
                                    <div className={css({ marginLeft: '-20px' })}>
                                        <Button $use={BUTTON_USE.icon} $size={BUTTON_SIZE.large}>
                                            {expanded ? (
                                                <i className="wemolo-chevron-up" />
                                            ) : (
                                                <i className="wemolo-chevron-down" />
                                            )}
                                        </Button>
                                    </div>
                                )
                            }
                        }
                    }}
                    title={
                        <div
                            className={css({
                                ...theme.typography.LabelLarge,
                                fontWeight: 700,
                                display: 'flex',
                                alignItems: 'center',
                                paddingRight: theme.sizing.scale950,
                                color: theme.colors.black
                            })}
                        >
                            {t(`ev.evEntry.privacyPolicy.expandLabel`)}
                        </div>
                    }
                >
                    {paragraphsKeys.map((paragraphKey, index) => (
                        <div
                            key={paragraphKey}
                            className={css({
                                marginBottom:
                                    index + 1 !== paragraphsKeys.length ? theme.sizing.scale750 : theme.sizing.scale400
                            })}
                        >
                            <div
                                className={css({
                                    ...typograhpyStyles,
                                    fontWeight: 700,
                                    marginBottom: theme.sizing.scale0
                                })}
                            >
                                {t(`ev.evEntry.privacyPolicy.paragraphs.${paragraphKey}.title`)}
                            </div>
                            <div
                                className={css({
                                    ...typograhpyStyles,
                                    fontWeight: 300
                                })}
                                // dangerouslySetInnerHTML used for interpolation
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: t(`ev.evEntry.privacyPolicy.paragraphs.${paragraphKey}.description`, {
                                        escapeInterpolation: false,
                                        interpolation: { escapeValue: false }
                                    })
                                }}
                            />
                        </div>
                    ))}
                </Panel>
            </Accordion>
        </div>
    )
}

export default PrivacyPolicy
