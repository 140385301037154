import axios, { AxiosError, AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import useErrorMessageHandler from '../utils/hooks/useErrorMessageHandler'

interface ISendEmailRequest {
    plate: string
    email: string
}

interface SendEmailData {
    to: string[]
    mail_variables: {
        plate: string
        language: string
    }
}

interface SendEmailReponse {
    message?: string
}

const getMailLanguage = (lang: string) => {
    const capitalizeLang = lang.toUpperCase()

    return capitalizeLang.includes('DK') ? 'DK' : capitalizeLang.split('-')[0]
}

const useSendEmail = () => {
    const { i18n } = useTranslation()
    const { handleAPIError } = useErrorMessageHandler()

    const sendEmail = ({ plate, email }: ISendEmailRequest): Promise<SendEmailReponse | AxiosError> => {
        return new Promise((resolve, reject) => {
            axios
                .post<SendEmailData, AxiosResponse>(`${process.env.REACT_APP_API_URL}customer/enque-email`, {
                    to: [email],
                    mail_variables: {
                        plate,
                        language: getMailLanguage(i18n.language)
                    }
                })
                .then(({ data }: AxiosResponse) => {
                    resolve(data as SendEmailReponse)
                })
                .catch((err: AxiosError) => {
                    handleAPIError(err)
                    reject(err)
                })
        })
    }

    return { sendEmail }
}

export default useSendEmail
