import React from 'react'
import { useTranslation } from 'react-i18next'

const useLanguages = () => {
    const { t, i18n } = useTranslation()

    const selectedLanguage = i18n.language

    const FALLBACK_LANGUAGE = React.useMemo(
        () => ({
            label: t('common.languages.de'),
            match: 'de',
            id: 'de-DE'
        }),
        // eslint-disable-next-line
        [selectedLanguage]
    )

    const LANGUAGES = React.useMemo(
        () => [
            {
                label: t('common.languages.de'),
                match: 'de',
                id: 'de'
            },
            {
                label: t('common.languages.en'),
                match: 'en',
                id: 'en'
            },
            {
                label: t('common.languages.fr'),
                match: 'fr',
                id: 'fr'
            },
            {
                label: t('common.languages.pl'),
                match: 'pl',
                id: 'pl'
            },
            {
                label: t('common.languages.da'),
                match: 'da',
                id: 'da'
            },
            {
                label: t('common.languages.it'),
                match: 'it',
                id: 'it'
            }
        ],
        // eslint-disable-next-line
        [selectedLanguage]
    )

    return { FALLBACK_LANGUAGE, LANGUAGES }
}

export default useLanguages
