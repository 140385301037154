import React from 'react'

import { useTranslation } from 'react-i18next'

import useChangeUsercentricsLanguage from 'parkdepot-shared/utils/hooks/useChangeUsercentricsLanguage'
import { COUNTRIES } from 'parkdepot-shared/utils/licensePlate'
import { LANGUAGE_FALLBACKS } from '../../i18n'

import { TABLET_TYPOGRAHPY_OVERRIDE } from '../MemberEntry/constants'

import { Select } from 'baseui/select'
import useLanguages from '../../utils/hooks/useLanguages'

const LanguageSelect = () => {
    const { i18n } = useTranslation()

    const { FALLBACK_LANGUAGE, LANGUAGES } = useLanguages()

    const language = i18n.language || COUNTRIES.germany.toLowerCase()

    useChangeUsercentricsLanguage(language.split('-')[0])

    return (
        <Select
            overrides={{
                Root: {
                    style: ({ $theme }) => ({
                        color: '#333',
                        border: '2px solid #333',
                        borderRadius: '8px',
                        width: '200px',
                        height: '40px',
                        backgroundColor: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        ':hover': {
                            borderColor: $theme.colors.primary
                        }
                    })
                },
                ControlContainer: {
                    style: {
                        backgroundColor: '#fff',
                        border: 'none',
                        ...TABLET_TYPOGRAHPY_OVERRIDE.medium
                    }
                },
                DropdownListItem: {
                    style: TABLET_TYPOGRAHPY_OVERRIDE.medium
                }
            }}
            searchable={false}
            options={Object.values(LANGUAGES)}
            labelKey="label"
            valueKey="id"
            value={[LANGUAGES.find((item) => language.startsWith(item.match))! || FALLBACK_LANGUAGE]}
            onChange={({ value }) => {
                const selected = LANGUAGE_FALLBACKS[value[0].id as string] || (value[0].id as string)
                i18n.changeLanguage(selected)
            }}
            size="compact"
            clearable={false}
        />
    )
}

LanguageSelect.displayName = 'LanguageSelect'
export default LanguageSelect
