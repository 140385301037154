import { Schema, ValidationError } from '@hapi/joi'
import defaultHandlers from './defaultHandlers'
import { IErrorHandlerDictionary, IErrorMessageDictionary, IErrorObjectDictionary } from './types'

/**
 * Takes a Joi error object and returns a dictionary of error descriptors
 * organized by path in a dictionary
 */
const getErrors = (errorObj?: ValidationError): IErrorObjectDictionary => {
    // Indicates no errors
    if (!errorObj) {
        return {}
    }

    const errorDict: IErrorObjectDictionary = {}
    for (const error of errorObj.details || []) {
        const errorPath = error.path.join('.')

        errorDict[errorPath] = {
            type: error.type,
            path: errorPath,
            value: error.context?.value,
            context: error.context,
            defaultMessage: error.message
        }
    }

    return errorDict
}

/**
 * Takes a dictionary of error descriptors and produces localized error messages according to a dictionary of
 * error handlers
 * @param errorObjects Dictionary of error descriptors
 * @param errorHandlers Dictionary error handlers, functions responsible for generating error messages
 */
const applyErrorHandler = (
    errorObjects: IErrorObjectDictionary,
    errorHandlers: IErrorHandlerDictionary
): IErrorMessageDictionary => {
    const errorMessages: IErrorMessageDictionary = {}
    for (const path in errorObjects) {
        const error = errorObjects[path]

        const handler =
            errorHandlers[error.type] || defaultHandlers[error.type] || (() => `no handler for type ${error.type}`)

        errorMessages[path] = handler(error) || `${error.path} was not handled properly with error type ${error.type}`
    }

    return errorMessages
}

/**
 * Generate a validator object acting as a wrapper around a Joi schema
 * @param schema Joi schema, see https://hapi.dev/module/joi/#usage
 * @param errorHandlers Dictionary of functions responsible for converting field error into human
 *                          readable messages.
 */
const generateValidator = (schema: Schema, errorHandlers: IErrorHandlerDictionary) => {
    return (data: unknown) => {
        const result = schema.validate(data, { abortEarly: false })

        return {
            data: result.value,
            errors: applyErrorHandler(getErrors(result.error), errorHandlers),
            valid: !result.error
        }
    }
}

export default generateValidator
