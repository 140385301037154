import { fetchVersion } from './fetchVersion'
import { LocalStorageKey } from './types'

/**
 * Check if the commit hash has changed, if so, reload the page.
 * Enabled only in production (process.env.NODE_ENV !== 'development').
 * @returns void
 */
export const checkCommitHash = async () => {
    if (process.env.NODE_ENV === 'development') {
        return
    }

    const fetchVersionResult = await fetchVersion()

    const currentCommitHash = localStorage.getItem(LocalStorageKey.VERSION_COMMIT_HASH)

    if (!fetchVersionResult || !fetchVersionResult.data?.commit) {
        return
    }

    const commitHash = fetchVersionResult.data.commit

    localStorage.setItem(LocalStorageKey.VERSION_COMMIT_HASH, commitHash)

    if (!currentCommitHash) {
        return
    }

    if (commitHash !== currentCommitHash) {
        localStorage.removeItem(LocalStorageKey.VERSION_CHECK_PENDING)
        window.location.reload()
    }
}

export default checkCommitHash
