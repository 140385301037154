import React from 'react'

import MemberEntryContainer from './MemberEntryContainer'
import MemberEntryView from './MemberEntryView'

const MemberEntry = () => {
    return (
        <MemberEntryContainer>
            <MemberEntryView />
        </MemberEntryContainer>
    )
}

export default MemberEntry
