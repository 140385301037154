import axios, { AxiosError, AxiosResponse } from 'axios'

interface IMemberEntryRequest {
    plate: string
    country: string
    email?: string
    name?: string
    handleAPIError: (e: AxiosError) => void
}

interface InsertGuestEntryReponse {
    plate?: string
    country?: string
}

export default ({
    plate,
    country,
    email,
    name,
    handleAPIError
}: IMemberEntryRequest): Promise<InsertGuestEntryReponse | AxiosError> => {
    return new Promise((resolve, reject) => {
        axios
            .post(`${process.env.REACT_APP_API_URL}customer/whitelist`, {
                plate,
                country,
                email: email || undefined,
                name: name || undefined
            })
            .then(({ data }: AxiosResponse) => {
                resolve(data)
            })
            .catch((err: AxiosError) => {
                handleAPIError(err)
                reject(err)
            })
    })
}
