import { styled, useStyletron } from 'baseui'
import { PublicHeader } from 'parkdepot-shared/components/PublicHeader'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useTimer from '../../hooks/useTimer'
import useHelpModal from '../modals/HelpModal'
import usePrivacyPolicyModal from '../modals/PrivacyPolicy'
import LanguageMenuDropdown from './components/LanguageMenuDropdown'
import { MemberEntryContext } from './MemberEntryContext'

const StyledTopBar = styled<'div', { $paddingSmall?: boolean; $sticky?: boolean; $shadow?: boolean }>(
    'div',
    ({ $paddingSmall, $sticky, $theme }) => ({
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: '80px',
        paddingTop: $paddingSmall ? $theme.sizing.scale300 : $theme.sizing.scale600,
        paddingBottom: $paddingSmall ? $theme.sizing.scale300 : $theme.sizing.scale600,
        paddingLeft: '0px',
        position: $sticky ? 'sticky' : 'static',
        top: 0,
        zIndex: 1,
        [$theme.mediaQuery.large]: {
            borderTopLeftRadius: $theme.borders.radius400,
            borderTopRightRadius: $theme.borders.radius400
        }
    })
)

const MemberEntryTopbar = () => {
    const { t } = useTranslation()
    const [css] = useStyletron()

    const { data, jwt_id } = React.useContext(MemberEntryContext)

    // only show notification email field if the jwt id could be successfully parsed from the JWT
    const showEmailField = !!jwt_id
    const anonymous = data.anonymous

    const time = useTimer()

    const [showHelpModal] = useHelpModal()
    const [showDataPrivacyModal] = usePrivacyPolicyModal()

    return (
        <StyledTopBar>
            <PublicHeader
                actions={() => [
                    <div className={css({ minWdith: '150px' })} key="timer">
                        {time}
                    </div>,
                    <LanguageMenuDropdown key="language_dropdown" />
                ]}
                links={[
                    {
                        label: t('member.memberEntry.privacyPolicy.title'),
                        clicked: (e) => {
                            e.preventDefault()
                            showDataPrivacyModal()
                        }
                    },
                    {
                        label: t('member.memberEntry.help.title'),
                        clicked: () => showHelpModal(showEmailField, anonymous)
                    }
                ]}
            />
        </StyledTopBar>
    )
}

export default MemberEntryTopbar
