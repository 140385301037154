import { PLACEMENT, ToasterContainer } from 'baseui/toast'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import { ModalProvider } from 'parkdepot-shared/components/Modal'
import concatProviders from 'parkdepot-shared/utils/concatProviders'

import { TouchKeyboardProvider } from 'parkdepot-shared/components/TouchKeyboard'
import UsercentricsContainer from 'parkdepot-shared/components/UsercentricsContainer'
import Routes from './routes'
import GuestConsentContainer from './views/GuestConsentContainer'
import PreflightContainer from './views/PreFlight/PreflightContainer'

const App = () => {
    const RootProvider = concatProviders([
        {
            Component: UsercentricsContainer,
            props: { settingsID: process.env.REACT_APP_USERCENTRICS_SETTINGS_ID }
        },
        {
            Component: GuestConsentContainer
        },
        {
            Component: Router
        },
        {
            Component: PreflightContainer
        },
        {
            Component: ToasterContainer,
            props: { placement: PLACEMENT.bottomRight }
        },
        {
            Component: ModalProvider
        },
        {
            Component: TouchKeyboardProvider
        }
    ])

    return (
        <RootProvider>
            <Routes />
        </RootProvider>
    )
}

export default App
